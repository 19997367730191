/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { PayloadCompanyData, Response } from "src/types";

interface ChangePhoneParams {
  id: string;
  cell_phone_number: string;
  country_code: string;
  name: string;
  last_name: string;
  last_name_2: string;
  person_type_id: string;
}

const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addCompanyData: build.query<Response, any>({
      query: (data: { id: UUID; body: PayloadCompanyData }) => ({
        url: `cuentas/${data.id}`,
        method: "PATCH",
        body: {
          company_rfc: data.body.rfc,
          company_name: data.body.name,
          company_legal_name: data.body.legal_name,
          is_pfae: data.body.is_pfae,
          pfae_name: data.body.pfae_name,
          pfae_last_name: data.body.pfae_last_name,
          pfae_last_name_2: data.body.pfae_last_name_2,
        },
      }),
    }),
    addPhoneVerified: build.query<Response, any>({
      query: (data: { id: UUID }) => ({
        url: `cuentas/${data.id}`,
        method: "PATCH",
        body: {
          cell_phone_verified: true,
        },
      }),
    }),

    changePhone: build.mutation<Response, ChangePhoneParams>({
      query: ({ id, cell_phone_number, country_code }) => ({
        url: `cuentas/${id}`,
        method: "PATCH",
        body: {
          cell_phone_number,
          country_code,
        },
      }),
    }),
    getCuentasById: build.query<Response, { id: string }>({
      query: ({ id }) => ({
        url: `cuentas/${id}`,
        method: "GET",
      }),
    }),
    updateLegalRepresentativeData: build.query<Response, any>({
      query: (data: {
        id: UUID;
        name: string;
        last_name: string;
        last_name_2: string;
        emailLegalRepresentative: string;
        countryCodeLegalRepresentative: string;
        phoneLegalRepresentative: string;
        businessActivityId: string;
        businessDescription: string;
      }) => ({
        url: `cuentas/${data.id}`,
        method: "PATCH",
        body: {
          name: data.name,
          last_name: data.last_name,
          last_name_2: data.last_name_2,
          email_legal_representative: data.emailLegalRepresentative,
          country_code_legal_representative: data.countryCodeLegalRepresentative,
          phone_legal_representative: data.phoneLegalRepresentative,
          business_activity_id: data.businessActivityId,
          business_description: data.businessDescription,
        },
      }),
    }),
  }),
});

export const {
  useLazyAddCompanyDataQuery,
  useLazyAddPhoneVerifiedQuery,
  useLazyUpdateLegalRepresentativeDataQuery,
  useLazyGetCuentasByIdQuery,
  useChangePhoneMutation,
} = accountApi;
