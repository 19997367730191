import { useState } from "react";
import { Box, Grid, Container, TextField, Typography, Button, useMediaQuery, useTheme, Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import img from "@assets/img/right_15.svg";
import { EMAIL } from "@utils/expreciones";
import { useRequestPasswordChangeMutation, useGetUserbyEmailMutation } from "@api/onboarding";

export const CambiarContrasena = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [requestPasswordChange] = useRequestPasswordChangeMutation();
  const [getUserbyEmail] = useGetUserbyEmailMutation();

  const handleCloseSuccessModal = () => setOpenSuccessModal(false);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim("El correo no puede tener espacios al inicio o al final")
      .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)")
      .required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const userResponse = await getUserbyEmail({ email: values.email }).unwrap();
        if (userResponse && userResponse.id) {
          await requestPasswordChange({ username: values.email }).unwrap();
          setOpenSuccessModal(true);
        } else {
          setOpenErrorModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.error("Error enviando solicitud de cambio de contraseña:", error);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} sx={{ mt: 2 }}>
          <Box
            sx={{
              mt: isMobileOrTablet ? 4 : 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={2}>
              <Typography variant={isMobileOrTablet ? "h3" : "h2"} fontWeight={700} color={"#528CD6"}>
                Ingresa tu correo electrónico
              </Typography>
            </Box>

            <Box my={4}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} textAlign="center">
                Te enviaremos un enlace para que generes una nueva contraseña
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trimStart();
                    const trimmed = trimmedValue.trim();
                    formik.setFieldValue("email", trimmed);
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                />

                <Box textAlign={"center"} mt={6}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || loading}
                    sx={{
                      backgroundColor: "#528CD6",
                      color: "#fff",
                      padding: "10px 24px",
                      borderRadius: "24px",
                      textTransform: "none",
                    }}
                  >
                    {loading ? "Enviando..." : "Continuar"}
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#002652"}>
            <img src={img} alt="Descripción" style={{ height: "calc(100vh - 76px)" }} />
          </Grid>
        )}
      </Grid>

      {/* Modal de éxito */}
      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="700" color="#528CD6" mb={2}>
            ¡Correo enviado!
          </Typography>
          <Typography variant="body1" mb={3}>
            Hemos enviado un enlace a tu correo electrónico. Sigue las instrucciones para restablecer tu contraseña.
          </Typography>
          <Button
            onClick={handleCloseSuccessModal}
            variant="contained"
            sx={{
              backgroundColor: "#528CD6",
              color: "#fff",
              borderRadius: "24px",
              textTransform: "none",
              px: 4,
              py: 1,
            }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>

      {/* Modal de error */}
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="700" color="#F2704F" mb={2}>
            Cuenta no encontrada
          </Typography>
          <Typography variant="body1" mb={3}>
            No encontramos una cuenta asociada a este correo electrónico. Por favor verifica el correo ingresado e
            inténtalo de nuevo.
          </Typography>
          <Button
            onClick={handleCloseErrorModal}
            variant="contained"
            sx={{
              backgroundColor: "#F2704F",
              color: "#fff",
              borderRadius: "24px",
              textTransform: "none",
              px: 4,
              py: 1,
            }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
};
