import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  setAccountUser,
  setCompanyData,
  setLoanApplication,
  setPersonalData,
  setShareHolderData,
} from "@store/slices/registerSlice";
import { restoreBofuCompanyData, restoreBofuGuaranteeData, restoreBofuOwnersData } from "@store/slices/bofuSlice";
import { setProcessPage } from "@store/slices/appSlice";
import { PayloadRequestPhoneCode, PayloadValidatePhoneCode, SessionInfoResponse } from "@interfaces/index";
import { restoreBofuPfaeData } from "@store/slices/bofuSlicePfae";

const sessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSessionInfo: build.query<SessionInfoResponse, UUID | string>({
      query: (sso_id: UUID | string) => ({
        url: "session_info",
        params: {
          sso_id: sso_id,
        },
      }),
      transformResponse: (response: SessionInfoResponse) => {
        return response;
      },
      async onQueryStarted(_arg, api) {
        const { data } = await api.queryFulfilled;

        api.dispatch(setProcessPage(data.data?.current_screen.url));

        api.dispatch(setLoanApplication(data.data?.loan_application_data));
        api.dispatch(setAccountUser(data.data?.user_data));
        api.dispatch(setPersonalData(data.data?.account_data));



        if (data.data?.loan_application_data?.type === "CS001") {


          api.dispatch(
            setCompanyData({
              name: data.data?.account_data?.company_name,
              rfc: data.data?.account_data?.company_rfc,
              legal_name: data.data?.account_data?.company_legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
            }),
          );

          if (data.data?.shareholder_data) {
            api.dispatch(setShareHolderData(data.data?.shareholder_data));
          }

          if (data.data?.bofu_data) {
            if (data.data?.bofu_data?.company) {
              api.dispatch(
                restoreBofuCompanyData({
                  files: data.data?.bofu_data?.company.files,
                  bank_accounts: data.data?.bofu_data?.company?.bank_accounts,
                  loan_application: data.data?.bofu_data?.company?.loan_application,
                }),
              );
            }

            if (data.data?.bofu_data?.guarantee) {
              api.dispatch(
                restoreBofuGuaranteeData({
                  files: data.data?.bofu_data?.guarantee?.files,
                  bank_accounts: data.data?.bofu_data?.guarantee?.bank_accounts,
                }),
              );
            }

            if (data.data?.bofu_data?.actual_owner) {
              api.dispatch(restoreBofuOwnersData(data.data?.bofu_data?.actual_owner));
            }
          }
        } else if (data.data?.loan_application_data?.type === "CS002") {

          api.dispatch(
            setCompanyData({
              name: data.data?.account_data?.company_name,
              rfc: data.data?.account_data?.company_rfc,
              legal_name: data.data?.account_data?.company_legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_pfae: data.data?.account_data?.is_pfae,
              pfae_name: data.data?.account_data?.pfae_name,
              pfae_last_name: data.data?.account_data?.pfae_last_name,
              pfae_last_name_2: data.data?.account_data?.pfae_last_name_2
            }),
          );
          if (data.data.bofu_data)
            api.dispatch(restoreBofuPfaeData({
              files: data.data.bofu_data.company.files,
              bank_accounts: data.data.bofu_data.company.bank_accounts,
              transactional_info: data.data.bofu_data.company.loan_application.transactional_info,
              loan_application: data.data.bofu_data.company.loan_application,
            }))
        }
      },
    }),
    requestPhoneCode: build.mutation<Response, any>({
      query: (data: PayloadRequestPhoneCode) => ({
        url: `telefono/enviar_codigo_login`,
        method: "POST",
        body: data,
      }),
    }),
    validatePhoneCode: build.mutation<Response, any>({
      query: (data: PayloadValidatePhoneCode) => ({
        url: `telefono/validar_codigo_login`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLazyGetSessionInfoQuery, useRequestPhoneCodeMutation, useValidatePhoneCodeMutation } = sessionApi;
