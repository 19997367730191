import { baseApi } from "./baseApi";

interface ApiResponse {
  code: string;
  message: string;
  errors: []; // Cambia a un tipo más específico si conoces la estructura de los errores
  data: ClientDetails;
}

interface ClientDetails {
  client_details_id: string;
  loan_application_id: string;
  person_id: string;
  person_type: string;
  regimen: string;
  attempts: number;
  upload_type: string;
}

const metaMap = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMetaMapData: build.query<ApiResponse, string>({
      query: (id) => ({
        url: `identity_verification_metamap/metadata/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetMetaMapDataQuery } = metaMap;
