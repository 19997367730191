import { baseApi } from "./baseApi";

const codigoPostalApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getByCp: build.query<any, string>({
      query: (codigoPostal) => ({
        url: "codigo_postal",
        params: { postal_code: codigoPostal },
      }),
      transformResponse: (response: { data: any }) => {
        return response.data;
      },
    }),
  }),
});

export const { useLazyGetByCpQuery } = codigoPostalApi;
