/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StyledSlider } from "./CustomSlider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { amountToText, calculateAmountMonthly, formatCurrency } from "@helpers/funciones";
import { useGetTaxSystemsQuery } from "@api/catalogs";

// se le suma 0.01 para que el slider no se quede en el minimo, ya que el slider no permite valores iguales
const QUANTITY_TO_PLUS = 0.01;

interface QuotationProps {
  isShowed?: boolean;
  approved_amount: number;
  approved_term: number;
  onUpdateValues: (amount: number, term: number, monthlyRate: number) => void;
}

export const QuotationOferta = (props: QuotationProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const loanApplication = useSelector((state: RootState) => state.register.application);

  const resultTaxSystems = useGetTaxSystemsQuery(loanApplication.type);

  const [loanAmount, setLoanAmountLocal] = useState<number>(props.approved_amount);
  const [loanTerm, setLoanTermLocal] = useState<number>(props.approved_term);
  const [monthlyRate, setMonthlyRateLocal] = useState<number>(0);
  const [isMinAmount, setIsMinAmount] = useState<boolean>(false);
  const [isMinTerm, setIsMinTerm] = useState<boolean>(false);

  const calculate = () => {
    const value = calculateAmountMonthly(loanAmount, loanTerm);
    props.onUpdateValues(loanAmount, loanTerm, value);
    setMonthlyRateLocal(value);
  };

  const handleAmountChange = (_: any, newValue: number | number[]) => {
    setLoanAmountLocal(newValue as number);
  };

  const handleTermChange = (_: any, newValue: number | number[]) => {
    setLoanTermLocal(newValue as number);
  };

  useEffect(() => {
    calculate();
  }, [loanAmount, loanTerm]);

  useEffect(() => {
    if (props.approved_amount > 0) {
      setLoanAmountLocal(props.approved_amount);
      setIsMinAmount(props.approved_amount === resultTaxSystems?.data?.min_amount);
    }
    if (props.approved_term > 0) {
      setLoanTermLocal(props.approved_term);
      setIsMinTerm(props.approved_term === resultTaxSystems?.data?.min_term);
    }
  }, [props.approved_amount, props.approved_term, resultTaxSystems.data]);

  return (
    <Box textAlign={"center"} width={"100%"}>
      <Container sx={{ mt: 2, mb: 2 }}>
        <Typography fontWeight={500} variant={isMobileOrTablet ? "body2" : "body1"} mt={2}>
          Selecciona el monto
        </Typography>
        <Typography textAlign={"center"} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
          {amountToText(loanAmount)} MXN
        </Typography>
        {props.isShowed && resultTaxSystems.isSuccess ? (
          <>
            <StyledSlider
              value={isMinAmount ? loanAmount + QUANTITY_TO_PLUS : loanAmount}
              aria-label="amount"
              min={resultTaxSystems.data.min_amount}
              max={
                props.approved_amount === 0
                  ? resultTaxSystems.data.max_amount
                  : isMinAmount
                  ? props.approved_amount + QUANTITY_TO_PLUS
                  : props.approved_amount
              }
              step={50000}
              onChange={handleAmountChange}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"#002652"} fontSize={"13px"}>
                {amountToText(resultTaxSystems.data.min_amount, "$")}
              </Typography>
              <Typography color={"#002652"} fontSize={"13px"}>
                {amountToText(
                  props.approved_amount === 0 ? resultTaxSystems.data.max_amount : props.approved_amount,
                  "$",
                )}
              </Typography>
            </Box>
          </>
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}

        <Typography fontWeight={500} variant={isMobileOrTablet ? "body2" : "body1"} mt={4}>
          Selecciona el plazo en meses
        </Typography>
        {props.isShowed && resultTaxSystems.isSuccess ? (
          <StyledSlider
            aria-label="term"
            value={isMinTerm ? loanTerm + QUANTITY_TO_PLUS : loanTerm}
            min={resultTaxSystems.data.min_term}
            max={
              props.approved_term === 0
                ? resultTaxSystems.data.max_term
                : isMinTerm
                ? props.approved_term + QUANTITY_TO_PLUS
                : props.approved_term
            }
            step={3}
            marks={
              isMinTerm
                ? [
                    {
                      value: props.approved_term,
                      label: props.approved_term.toString(),
                    },
                    {
                      value: props.approved_term + QUANTITY_TO_PLUS,
                      label: props.approved_term.toString(),
                    },
                  ]
                : [
                    { value: 6, label: "6" },
                    { value: 9, label: "9" },
                    { value: 12, label: "12" },
                    { value: 15, label: "15" },
                    { value: 18, label: "18" },
                    { value: 21, label: "21" },
                    { value: 24, label: "24" },
                  ]
            }
            onChange={handleTermChange}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}
      </Container>

      <Typography
        textAlign={"center"}
        color={"#528CD6"}
        fontWeight={700}
        variant={isMobileOrTablet ? "body2" : "body1"}
      >
        Pago mensual estimado
      </Typography>
      <Typography mb={3} textAlign={"center"} variant="h4" fontWeight={600}>
        {isNaN(monthlyRate) || !isFinite(monthlyRate) ? "0" : formatCurrency(monthlyRate)}{" "}
        <Typography variant="h5" component={"span"} fontWeight={600}>
          MXN
        </Typography>
      </Typography>

      <Typography textAlign={"center"} color={"#002652"} variant={isMobileOrTablet ? "body3" : "body2"}>
        *Mensualidad estimada
      </Typography>
    </Box>
  );
};
