/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { FormikErrors, useFormik } from "formik";

import { RootState } from "@store/store";
import { CustomUploadFile } from "@components/CustomUploadFile";
import { Bank } from "@interfaces/catalogs";
import { useLazyGetBanksByCodeQuery } from "@api/catalogs";
import {
  usePostBankAccountsMutation,
  usePutBankAccountMutation,
  useDeleteBankAccountMutation,
  usePutApplicationCompletedMutation,
} from "@api/applications";

import {
  removePfaeBankAccount,
  removePfaeBankAccountFile,
  setPfaeBankAccountFile,
  setPfeaBankAccount,
  setPfeaBankAccountUpdate,
  setPfeaBankAccountUpdateId,
} from "@store/slices/bofuSlicePfae";
import { UploadedFile } from "@interfaces/store";

import img from "@assets/img/img_tofu/img_tofu_8.svg";
import { CustomDialog } from "@components/index";
import { useDeleteDocumentMutation } from "@api/documents";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";

interface InformacionBancariaProps {
  type: "pfae";
}

interface BankAccountSection extends InformacionBancariaProps {
  index: number;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  registerFormik?: (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => void;
}

const BankAccountSection = ({ setFieldValue, registerFormik, index }: BankAccountSection) => {
  const dispatch = useDispatch();

  const [bankInfo, setBankInfo] = useState<Bank | undefined>(undefined);
  const [sectionInternalId] = useState<string>(crypto.randomUUID());
  const [validations, setValidations] = useState<yup.ObjectShape>({
    clabe: yup.string().min(18, "Formato incorrecto, debe tener 18 dígitos").required("Campo requerido."),
    banco: yup.string().required("Campo requerido."),
  });

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const sectionData = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);


  const bankAccounts = sectionData.bank_accounts;
  const [triggerPutBankAccount] = usePutBankAccountMutation();
  const [triggerPostBankAccount] = usePostBankAccountsMutation();
  const [triggerGetBanks, resultGetBanks] = useLazyGetBanksByCodeQuery();

  const formik = useFormik({
    initialValues: {
      clabe: "",
      banco: "",
    },
    validationSchema: yup.object().shape(validations),
    onSubmit: async (values) => {
      if (!formik.isValid) return;

      try {
        const bankAccount = bankAccounts[index];

        const isBankAccountNew = !bankAccount.id || bankAccount.id === "00000000-0000-0000-0000-000000000000";

        const bankAccountPayload = {
          account: 0,
          clabe: values.clabe,
          account_holder: "",
          bank_id: bankInfo ? bankInfo.id : "0-0-0-0-0",
          loan_application_id: applicationData.id,
          client_details_id: personalData.id,
          person_id: null,
          bank_statment_first_documents_id: String(bankAccount.bank_statements[0].id),
          bank_statment_second_documents_id: String(bankAccount.bank_statements[1].id),
          bank_statment_third_documents_id: String(bankAccount.bank_statements[2].id),
        };

        if (isBankAccountNew) {
          const postBank = await triggerPostBankAccount({
            solicitudId: applicationData.id,
            body: bankAccountPayload,
          }).unwrap();

          dispatch(
            setPfeaBankAccountUpdateId({
              index,
              id: String(postBank.data.id),
            }),
          );
        } else {
          await triggerPutBankAccount({
            solicitudId: applicationData.id,
            bankAccountId: String(bankAccount.id),
            body: bankAccountPayload,
          }).unwrap();
        }

        dispatch(
          setPfeaBankAccountUpdate({
            index,
            clabe: values.clabe,
            bank_name: String(bankInfo?.name),
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    validateOnMount: true,
  });

  const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };



  useEffect(() => {
    if (bankAccounts.length > 0) {
      formik.setFieldValue("clabe", bankAccounts[index].clabe);
      triggerGetBanks(bankAccounts[index].clabe.substring(0, 3));
      registerFormik?.(
        formik.submitForm,
        sectionInternalId,
        yup.boolean().isTrue(`La sección con id ${sectionInternalId} no ha pasado sus validaciones`),
      );
    }
  }, []);

  useEffect(() => {
    setFieldValue?.(sectionInternalId, formik.isValid);
  }, [formik.isValid]);

  useEffect(() => {
    if (resultGetBanks.isSuccess && resultGetBanks.currentData) {
      setBankInfo(resultGetBanks.data);
      formik.setFieldValue("banco", resultGetBanks.data.name);
    }
  }, [resultGetBanks]);

  const handleOnChangeCLABE = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    if (!event?.target) return;

    const numericText = event.target.value.replace(/[^0-9]/g, "");
    formik.setFieldValue("clabe", numericText);

    if (numericText.length >= 3) {
      try {
        const bank = await triggerGetBanks(numericText.substring(0, 3));

        if (bank.data === undefined || bank.data === null || Object.keys(bank.data).length === 0) {
          throw new Error("CLABE no válida");
        }

        formik.setFieldValue("banco", bank.data.name);
        formik.setFieldError("banco", "");

        dispatch(
          setPfeaBankAccountUpdate({
            index,
            clabe: numericText,
            bank_name: bank.data.name,
          })
        );
      } catch (error) {
        if (numericText.length >= 18) {
          formik.setFieldError("banco", String(error) || "Error desconocido");
          formik.setErrors({ clabe: "Clabe bancaria incorrecta, validar nuevamente." });
        }
      }
    } else {
      formik.setFieldValue("banco", "");
    }
  };


  const handleUpdateStore = (action: any, indexFile: number, uploadedFile: UploadedFile | null, _fileId: string) => {

    if (action === "ADD" && uploadedFile !== null) {
      dispatch(
        setPfaeBankAccountFile({
          parentIndex: index,
          indexFile: indexFile,
          uploadedFile: uploadedFile,
        }),
      );
    } else if (action === "DEL") {
      dispatch(
        removePfaeBankAccountFile({
          parentIndex: index,
          indexFile: indexFile,
        }),
      );
    }

  };

  return (
    <>
      <Container>

        <InactivityHandler />
        <TextField
          id="clabe"
          name="clabe"
          label="Cuenta CLABE"
          margin="normal"
          type="text"
          required
          fullWidth
          autoComplete="off"
          onBlur={formik.handleBlur}
          onChange={handleOnChangeCLABE}
          value={formik.values.clabe}
          error={formik.touched.clabe && Boolean(formik.errors.clabe)}
          helperText={formik.touched.clabe ? formik.errors.clabe : ""}
          inputProps={{ maxLength: 18 }}
        />
        <TextField
          id="banco"
          name="banco"
          label="Banco"
          margin="normal"
          required
          fullWidth
          autoComplete="off"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.banco}
          error={formik.touched.banco && Boolean(formik.errors.banco)}
          helperText={formik.touched.banco ? formik.errors.banco : ""}
          disabled={true}
        />

        <CustomUploadFile
          py={2}
          index={0}
          updateStore={handleUpdateStore}
          uploadedFile={
            bankAccounts[index] ? bankAccounts[index].bank_statements[0] : undefined
          }
          optionalName="1er estado de cuenta bancario"
          optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
          setFieldValue={formik.setFieldValue}
          registerFormik={registerInternalFormik}
          errors={formik.errors}
          required
          metadata={{
            taxCode: import.meta.env.VITE_CODE_TAXS_CS_PFAE,
            fileCode: "DECB",
            uploadType: "client",
            loan_application_id: applicationData.id,
            regimen: applicationData.type,
            person_id: undefined,
            clientDetailsId: personalData.id,
            personType: "pfae",
          }}
        />

        <CustomUploadFile
          py={2}
          index={1}
          updateStore={handleUpdateStore}
          uploadedFile={
            bankAccounts[index] ? bankAccounts[index].bank_statements[1] : undefined
          }
          optionalName="2do estado de cuenta bancario"
          optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
          setFieldValue={formik.setFieldValue}
          registerFormik={registerInternalFormik}
          errors={formik.errors}
          metadata={{
            taxCode: import.meta.env.VITE_CODE_TAXS_CS_PFAE,
            fileCode: "DECB",
            uploadType: "client",
            loan_application_id: applicationData.id,
            regimen: applicationData.type,
            person_id: undefined,
            clientDetailsId: personalData.id,
            personType: "pfae",
          }}
        />

        <CustomUploadFile
          py={2}
          index={2}
          updateStore={handleUpdateStore}
          uploadedFile={
            bankAccounts[index] ? bankAccounts[index].bank_statements[2] : undefined
          }
          optionalName="3er estado de cuenta bancario"
          optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
          setFieldValue={formik.setFieldValue}
          registerFormik={registerInternalFormik}
          errors={formik.errors}
          metadata={{
            taxCode: import.meta.env.VITE_CODE_TAXS_CS_PFAE,
            fileCode: "DECB",
            uploadType: "client",
            loan_application_id: applicationData.id,
            regimen: applicationData.type,
            person_id: undefined,
            clientDetailsId: personalData.id,
            personType: "pfae",
          }}
        />
      </Container>
    </>
  );
};

export const InformacionBancariaPfae = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sectionData = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const [deleteBanckAccount] = useDeleteBankAccountMutation();
  const [deleteBannkDocuments] = useDeleteDocumentMutation();

  const [formiks, setFormiks] = useState<{ [k: string]: () => Promise<any> }>({});

  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [acountDelete, setAcountDelete] = useState<number>(0);
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  const handleOnOpenModal = (index: number) => {
    setCloseModal(false);
    setAcountDelete(index);
  };

  const validateAllAccounts = (accounts: any[]) => {
    if (!accounts || accounts.length === 0) return false;
    return accounts.every((account) => {
      const hasCLABE = account?.clabe?.length === 18;
      const hasBanco = account?.bank_name !== "";
      const hasAllDocuments =
        account?.bank_statements?.length === 3 &&
        account?.bank_statements?.every((doc: any) => doc?.id);

      return hasCLABE && hasBanco && hasAllDocuments;
    });
  };

  const handleDeleteAccount = async (indexToDelete: number) => {
    try {
      const accountToDelete = sectionData.bank_accounts[indexToDelete];

      if (
        sectionData.bank_accounts[indexToDelete].id &&
        sectionData.bank_accounts[indexToDelete].id !== "00000000-0000-0000-0000-000000000000"
      ) {
        await deleteBanckAccount({
          solicitudId: String(applicationData.id),
          accountId: String(sectionData.bank_accounts[indexToDelete].id),
        }).unwrap();
      }

      const documents = accountToDelete.bank_statements;
      for (const doc of documents) {
        if (doc?.id && doc.id !== "00000000-0000-0000-0000-000000000000") {
          await deleteBannkDocuments(doc.id as UUID);
        }
      }

      dispatch(removePfaeBankAccount(indexToDelete));

      const updatedLocalAccounts = bankAccounts.filter((_, index) => index !== indexToDelete);
      setBankAccounts(updatedLocalAccounts);

      const isValid = validateAllAccounts([...sectionData.bank_accounts.slice(0, indexToDelete), ...sectionData.bank_accounts.slice(indexToDelete + 1)]);
      setIsContinueEnabled(isValid);
      setCloseModal(true);
    } catch (error) {
      console.log("Ha ocurrido un error");
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      const tmp = [];
      for (const key in formiks) {
        tmp.push(formiks[key]());
      }

      await Promise.all(tmp);

      const data = {
        bank_information_completed: true,
      };

      try {
        await putAapplicationCompleted({ id_sol: applicationData.id, body: data });
        window.location.href = "/producto/creditosimple/avance_expediente_digitalPFAE";
      } catch (error: any) {
        if (error?.status === 500 && error?.status === 409) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }


    },
  });

  const registerFormik = (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);

    formiks[name] = formikLocal;
    setFormiks(formiks);

    formik.validateForm();
  };

  const [bankAccounts, setBankAccounts] = useState<Array<ReactNode>>(
    sectionData.bank_accounts?.length === 0 || !sectionData.bank_accounts
      ? [
        <BankAccountSection
          key={crypto.randomUUID()}
          index={0}
          type={"pfae"}
          registerFormik={registerFormik}
          setFieldValue={formik.setFieldValue}
        />,
      ]
      : sectionData.bank_accounts.map((_bankAccount, index) => (
        <BankAccountSection
          key={crypto.randomUUID()}
          index={index}
          type={"pfae"}
          registerFormik={registerFormik}
          setFieldValue={formik.setFieldValue}
        />
      )),
  );

  useEffect(() => {
    const isValid = validateAllAccounts(sectionData.bank_accounts);
    setIsContinueEnabled(isValid);
  }, [sectionData.bank_accounts, bankAccounts]);


  const handleOnAddAccountBank = () => {
    dispatch(setPfeaBankAccount());

    const tmpUUID = crypto.randomUUID();

    const newAccounts = [
      ...bankAccounts,
      <BankAccountSection
        key={tmpUUID}
        index={bankAccounts.length}
        type={"pfae"}
        registerFormik={registerFormik}
        setFieldValue={formik.setFieldValue}
      />,
    ];

    setBankAccounts(newAccounts);

    setIsContinueEnabled(false);
  };

  const handleOnClickRestart = () => {
    navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
  };

  useEffect(() => {
    const isValid = validateAllAccounts(sectionData.bank_accounts);
    setIsContinueEnabled(isValid);
  }, [sectionData.bank_accounts]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} variant="body2" fontWeight={700}
              color={"#002652"}
            >
              Información bancaria
            </Typography>

            {/* <Typography my={3} variant="body2" color={"#002652"} fontWeight={600}>
              Información bancaria
            </Typography> */}
            <Typography my={3} color={"#528CD6"} variant="body2" fontWeight={600}>
              Ingresa los datos de la cuenta en dónde concentras la mayor facturación de tu negocio, a esta cuenta
              depositaremos tu crédito
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Box>
                {bankAccounts.length > 0 &&
                  bankAccounts.map((bankAccount, index) => (
                    <Box key={index}>
                      {bankAccount}
                      <Container>
                        {index !== 0 && (
                          <Box textAlign={"left"} my={2} mt={2}>
                            <Link onClick={() => handleOnOpenModal(index)}>
                              <Typography variant="body1" fontWeight={400}>
                                - Eliminar cuentas bancaria
                              </Typography>
                            </Link>
                          </Box>
                        )}
                      </Container>
                    </Box>
                  ))}
              </Box>

              <Container>
                <Box textAlign={"left"} my={2}>
                  <Link onClick={handleOnAddAccountBank}>
                    <Typography variant="body1" fontWeight={400}>
                      + Agregar cuenta bancaria
                    </Typography>
                  </Link>
                </Box>
              </Container>
              <Box my={3} px={3}>
                <Typography variant="body3" fontWeight={600} color="#002652" textAlign={"center"}>
                  Si la cuenta refleja menos del 50% de los ingresos agrega otra cuenta bancaria hasta llegar a este
                  porcentaje
                </Typography>
              </Box>

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="blue_outlined"
                    sx={{ padding: "0", height: "100%" }}
                    onClick={handleOnClickRestart}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth type="submit" variant="default"
                    disabled={!isContinueEnabled}>
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomDialog
        colorClose="#A3D4E8"
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} color={"#002652"} fontWeight={600} fontSize={"14px"} mt={4}>
            ¿Estas seguro de eliminar la cuenta bancaria?
          </Typography>
        }
        itemContent={
          <Box mt={2} mb={2} mr={1} ml={1}>
            <Box display={"flex"} columnGap={2} justifyContent={"center"}>
              <Button variant="blue_outlined" sx={{ py: 0, px: 1, width: "110px" }} onClick={handleOnCloseModal}>
                Regresar
              </Button>
              <Button variant="default" sx={{ width: "110px" }} onClick={() => handleDeleteAccount(acountDelete)}>
                Eliminar
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};
