/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { ContractChart } from "@components/ContractChart";
import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { ContractProps } from "@interfaces/contract";
import { formatCurrency, formatDateToLargeDate } from "@helpers/index";
import { useLazyGetLoansQuery } from "@api/accountData";
import FlowerImg from "@assets/img/flower.svg";
import { useEffect } from "react";

export const Contrato = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [getLoans] = useLazyGetLoansQuery();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [contractList, setContractList] = useState<ContractProps[]>([]);

  const getStartDate = (contract: ContractProps) => {
    return contract.disbursementDetails.disbursementDate;
  };

  const getEndDate = (contract: ContractProps) => {
    const startdate = contract.disbursementDetails.disbursementDate;
    const monthsToAdd = contract.scheduleSettings.repaymentInstallments;
    const endDate = dayjs(startdate).add(monthsToAdd, "month");
    return endDate.toISOString();
  };

  const getPaymentDate = (contract: ContractProps) => {
    const startdate = contract.disbursementDetails.disbursementDate;
    const monthsToAdd = 1;
    const endDate = dayjs(startdate).add(monthsToAdd, "month");
    return endDate.toISOString();
  };

  useEffect(() => {
    const getLoanData = async () => {
      try {
        const response_contracts = await getLoans(undefined).unwrap();
        setContractList([response_contracts[0]]);
      } catch (error) {
        console.error("Error al obtener los datos de los contratos", error);
      }
    };

    getLoanData();
  }, []);

  return (
    <Box width={"90%"} maxHeight={"90%"} margin={"auto"} py={4}>
      <Typography variant={isMobileOrTablet ? "body2" : "body1"}>Selecciona contrato:</Typography>
      <CustomTabs
        titleTabs={contractList.map((item) => item.id)}
        onTabChange={(tabIndex) => setActiveTabIndex(tabIndex)}
      >
        {contractList.map((contractItem, index) => (
          <CustomTabPanel
            key={index}
            boxShadow={"8px 4px 20px rgba(88, 88, 88, 0.25)"}
            borderRadius={"0 0 8px 8px"}
            position={"relative"}
            active={activeTabIndex === index}
          >
            <img src={FlowerImg} style={{ position: "absolute" }} />
            <Box height={"100%"} padding={3}>
              <Typography variant="h3" fontWeight={600} color="#528CD6" textAlign={"center"}>
                Información de tu crédito
              </Typography>
              <Grid container height={"100%"} rowSpacing={2} mt={2}>
                <Grid item xs={12} sm={12} md={7} xl={7}>
                  <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1">Fecha inicio de contrato</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatDateToLargeDate(getStartDate(contractItem))}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Fecha fin de contrato</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatDateToLargeDate(getEndDate(contractItem))}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Próximo pago</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatDateToLargeDate(getPaymentDate(contractItem))}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Monto próximo pago</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatCurrency(contractItem.scheduleSettings.periodicPayment)}
                        <Typography component={"span"} variant="h5" fontWeight={600}>
                          MXN
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Saldo insoluto</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatCurrency(contractItem.balances.principalBalance)}
                        <Typography component={"span"} variant="h5" fontWeight={600}>
                          MXN
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Saldo pendiente</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {formatCurrency(contractItem.balances.principalDue + contractItem.balances.interestDue + contractItem.balances.feesDue)}
                        <Typography component={"span"} variant="h5" fontWeight={600}>
                          MXN
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h3" fontWeight={600} color="#528CD6" textAlign={"center"}>
                        Datos de pago
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Banco</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {contractItem._account_bank.banco_nombre}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Nombre beneficiario</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {contractItem._account_bank.nombre_beneficiario}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">CLABE</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {contractItem._account_bank.clabe}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Referencia de pago</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {contractItem._account_bank.referencia_pago}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">CLABE</Typography>
                      <Typography variant="h4" fontWeight={600} color={"#002652"}>
                        {contractItem._account_bank.clabe_corta}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} xl={5}>
                  <Link href={`/home/hitorial_de_pagos/${contractItem.id}`}>
                    <Typography variant="body1" fontWeight={600} color={"#002652"} textAlign={"center"} mb={4}>
                      Ver historial de pagos
                    </Typography>
                  </Link>
                  <Box display={"flex"} flexDirection={"column"} alignItems={"center"} maxHeight={500}>
                    <ContractChart
                      index={index}
                      data={{
                        months_paid: contractItem.balances.feesPaid,
                        months_due: contractItem.balances.feesDue,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>
        ))}
      </CustomTabs>
    </Box>
  );
};
