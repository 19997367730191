import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  DocumentResponse,
  ContractualDocumentResponse,
  PayloadDocument,
  PayloadDocumentGenerate,
  PayloadSignUrl,
  PayloadUploadFile,
  Response,
  SignedUrlFileResponse,
  UrlDownloadDocument,
} from "src/types";

const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSignUrlToUpload: build.query<SignedUrlFileResponse, PayloadSignUrl>({
      query: (data: PayloadSignUrl) => ({
        url: `generar_url/subir`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: SignedUrlFileResponse }) => {
        return response.data;
      },
    }),
    getUrlToDownloadDoc: build.mutation<string, string>({
      query: (fileKey) => ({
        url: `generar_url/descargar`,
        method: "POST",
        body: {
          file_key: fileKey,
        },
      }),
      transformResponse: (response: UrlDownloadDocument) => {
        return response.data;
      },
    }),
    getUrlToDownloadZip: build.mutation<string, string>({
      query: (files) => ({
        url: `generar_url/descargar_zip`,
        method: "POST",
        body: {
          files: files,
        },
      }),
      transformResponse: (response: UrlDownloadDocument) => {
        return response.data;
      },
    }),

    uploadFile: build.mutation<Response, PayloadUploadFile>({
      query: (data: PayloadUploadFile) => ({
        url: data.url,
        method: "POST",
        body: data.body,
        formData: true,
      }),
    }),
    postDocument: build.mutation<DocumentResponse, PayloadDocument>({
      query: (data: PayloadDocument) => ({
        url: "documentos",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: DocumentResponse }) => {
        return response.data;
      },
    }),
    deleteDocument: build.mutation<Response, UUID>({
      query: (id: UUID) => ({
        url: `documentos/${id}`,
        method: "DELETE",
      }),
    }),
    patchDocument: build.mutation<Response, { documentId: UUID | undefined; body: any }>({
      query: ({ documentId, body }) => ({
        url: `documentos/${documentId}`,
        method: "PATCH",
        body: body,
      }),
    }),

    patchContractualDocument: build.mutation<Response, { documentId: UUID | undefined; body: any }>({
      query: ({ documentId, body }) => ({
        url: `documentos/documents_contract/${documentId}`,
        method: "PATCH",
        body: body,
      }),
    }),

    getDocumentByFilters: build.query<DocumentResponse[], { loanApplicationId: UUID; fileCategoryId: UUID }>({
      query: ({ loanApplicationId, fileCategoryId }) => ({
        url: `documentos`,
        params: {
          loan_application_id: loanApplicationId,
          file_category_id: fileCategoryId,
        },
      }),
      transformResponse: (response: { data: DocumentResponse[] }) => {
        return response.data;
      },
    }),
    postDocumentGenarate: build.mutation<Response, PayloadDocumentGenerate>({
      query: (data: PayloadDocumentGenerate) => ({
        url: "generar_documento",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: Response }) => {
        return response.data;
      },
    }),
    getDocumentLoadApplicationClientId: build.query<
      DocumentResponse[],
      { loanApplicationId: string; clientId: string }
    >({
      query: ({ loanApplicationId, clientId }) => ({
        url: `documentos?loan_application_id=${loanApplicationId}&cliend_details_id=${clientId}`,
      }),
      transformResponse: (response: { data: DocumentResponse[] }) => {
        return response.data;
      },
    }),
    getContractualDocumentLoanApplication: build.query<
      ContractualDocumentResponse[],
      { loanApplicationId: string; clientId: string }
    >({
      query: ({ loanApplicationId, clientId }) => ({
        url: `documentos/documents_contract?q=loan_application_id:${loanApplicationId}&q=client_details_id:${clientId}`,

      }),
      transformResponse: (response: { data: { data: ContractualDocumentResponse[] } }) => {
        return response.data.data;
      },
    }),

    getDocumentPersonatId: build.query<DocumentResponse[], { personaId: string; loanApplicationId: string }>({
      query: ({ personaId, loanApplicationId }) => ({
        url: `documentos?loan_application_id=${loanApplicationId}&person_id=${personaId}`,
      }),
      transformResponse: (response: { data: DocumentResponse[] }) => {
        return response.data;
      },
    }),

    getDocumentAppId: build.query<DocumentResponse[], { client_details_id: string; loanApplicationId: string }>({
      query: ({ client_details_id, loanApplicationId }) => ({
        url: `documentos?client_details_id=${client_details_id}&loan_application_id=${loanApplicationId}`,
      }),
      transformResponse: (response: { data: DocumentResponse[] }) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyGetSignUrlToUploadQuery,
  useUploadFileMutation,
  usePostDocumentMutation,
  useDeleteDocumentMutation,
  usePatchDocumentMutation,
  useLazyGetDocumentByFiltersQuery,
  usePostDocumentGenarateMutation,
  useLazyGetDocumentLoadApplicationClientIdQuery,
  useGetUrlToDownloadDocMutation,
  useLazyGetContractualDocumentLoanApplicationQuery,
  useLazyGetDocumentPersonatIdQuery,
  useGetUrlToDownloadZipMutation,
  useLazyGetDocumentAppIdQuery,
  usePatchContractualDocumentMutation
} = accountApi;
