import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Modal,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentContract, DocumentSigner } from "@interfaces/document_contract";

interface ModalFirmarMiFielsProps {
  handleClose: () => void;
  handleSuccess: (signer: DocumentSigner) => void;
  document_contract: DocumentContract;
}

const ModalFirmarMiFiel = (props: ModalFirmarMiFielsProps) => {
  const { document_contract, handleClose, handleSuccess } = props;
  const [externalSigners, setExternalSigners] = useState<DocumentSigner[]>([]);

  const [signerId, setSignerId] = useState<string>("");

  const selectSigner = (event: SelectChangeEvent) => {
    const value = event.target.value || "";
    setSignerId(value);

    if (value) {
      const signer = document_contract.mifiel_signers?.find((signer) => signer.id === value);
      if (!signer?.widget_id) {
        return;
      }

      window.mifiel.widget({
        widgetId: signer.widget_id,
        appendTo: "mifiel-widget",
        successBtnText: "Finalizar",
        onSuccess: {
          callToAction: function () {
            handleSuccess(signer);
          },
        },
        onError: {
          callToAction: function () {
            handleClose();
          },
        },
      });
    }
  };

  useEffect(() => {
    if (document_contract.mifiel_signers?.length) {
      const external_signers = document_contract.mifiel_signers.filter((signer) => signer.type === "external");
      setExternalSigners(external_signers);
    }
  }, [document_contract]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={handleClose}
      closeAfterTransition
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "1280px",
          maxWidth: "90%",
          height: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "24px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="label-select-firmante">Selecciona el firmante</InputLabel>
                <Select
                  labelId="label-select-firmante"
                  id="select-firmante"
                  name="signer_id"
                  fullWidth
                  value={signerId}
                  onChange={selectSigner}
                >
                  <MenuItem value="">
                    {externalSigners.length ? "Selecciona el Firmante" : "No se han encontrado firmantes"}
                  </MenuItem>
                  {externalSigners?.map((signer: DocumentSigner) => (
                    <MenuItem key={signer.id} value={signer.id}>
                      {signer.rfc} | {signer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ height: "90%" }} id="mifiel-widget"></Box>
      </Box>
    </Modal>
  );
};

export default ModalFirmarMiFiel;
