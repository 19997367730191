/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  TextField,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Typography,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  linearProgressClasses,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetApplicationByApplicationIdQuery, usePostValidateNextStageMutation } from "@api/applications";
import { CustomSwitch, ProgressBar } from "@components/index";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { useCiecValidatorMutation, CiecValidatorRequest } from "@api/sat_apis_new/satApsi";
import { InactivityHandler } from "@components/InactivityHandler";
import { Visibility, VisibilityOff, ErrorOutline } from "@mui/icons-material";

import img from "@assets/img/ciec_img_right.svg";
import clock from "@assets/img/img_tofu/img_ciec.svg";

const SECONDS_TO_FETCH = 10 * 1000;

const CustomLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 8,
  backgroundColor: "#E3F2F8",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#002652",
    animation: "progressAnim 10s linear infinite",
    borderRadius: 8,
  },
  "@keyframes progressAnim": {
    from: { width: "0%" },
    to: { width: "100%" },
  },
});

export const ConsultaCiec = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [ciecValidation] = useCiecValidatorMutation();
  const [getLoadApplication, { data: applicationData, isSuccess }] = useLazyGetApplicationByApplicationIdQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidCiec, setIsValidCiec] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const sendCiecRequest = async (values: any) => {
    const payload_cieq: CiecValidatorRequest = {
      rfc: companyData.rfc,
      ciec: values.ciec,
      application_code: import.meta.env.VITE_APPLICATION_CODE,
      client_details_id: String(application.client_details_id),
      loan_application_conditions_id: String(application.application_condition_id),
      loan_application_id: String(application.id),
      requested_amount: Number(application.loan_amount),
      requested_term: Number(application.loan_term),
    };
    await ciecValidation(payload_cieq).unwrap();
  };

  const sendNextStage = async () => {
    await triggerPostNextStage({
      applicationId: application.id,
      statusCode: "CSAEM",
    }).unwrap();
  };

  const formik = useFormik({
    initialValues: {
      ciec: "",
      switch: false,
    },
    validationSchema: yup.object().shape({
      ciec: yup.string().min(8, "Formato incorrecto, debe tener 8 dígitos").required("Campo requerido."),
      switch: yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setIsLoading(true);
          await sendCiecRequest(values);
          setOpen(true);
          setTimeLeft(6);
        } catch (error: any) {
          if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
            closeModal();
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            closeModal();
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (applicationData && isSuccess) {
      const ciec = applicationData.data[0]?.is_correct_ciec;
      setIsValidCiec(`${ciec}`);
    }
  }, [applicationData, isSuccess]);

  useEffect(() => {
    if (isValidCiec === "true") {
      setTimeLeft(0);
      closeModal();
      nexPage();
    } else if (isValidCiec === "false") {
      setTimeLeft(0);
    }
  }, [isValidCiec]);

  const closeModal = () => {
    setOpen(false);
  };

  const getStatusCiec = async () => {
    try {
      await getLoadApplication(application.id).unwrap();
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      }
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timeLeft !== 0) {
        getStatusCiec();
        setTimeLeft((prev) => prev - 1);
      } else {
        if (isValidCiec === "null") {
          setTimeLeft(30);
        }
      }
    }, SECONDS_TO_FETCH);
    return () => clearInterval(timerId);
  }, [timeLeft, isValidCiec]);

  const nexPage = async () => {
    try {
      await sendNextStage();
      navigate("/producto/creditosimple/uso_de_credito");
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      }
    }
  };

  useEffect(() => {
    const duration = 10000;
    const interval = 100;
    const step = 100 / (duration / interval);

    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prev + step;
      });
    }, interval);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={3} mt={4}>
            <Typography mb={3} variant="h4" fontWeight={500}>
              Registro de empresa
            </Typography>
            <Container maxWidth="sm" sx={{ my: 2, p: 0 }}>
              <ProgressBar progress={application.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 4 : 5} />
            </Container>

            <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} textAlign={"center"}>
              Haremos una consulta de tus datos al SAT para obtener tu oferta personalizada
            </Typography>

            <Typography my={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
              {companyData.legal_name}
            </Typography>
            <Typography my={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
              {companyData.rfc}
            </Typography>
            <Typography my={1} variant={isMobileOrTablet ? "body2" : "body1"}>
              Ingresa la contraseña
            </Typography>
            <Container maxWidth="sm" sx={{ mt: 1, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="ciec"
                  name="ciec"
                  label="Contraseña del SAT (CIEC)"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ciec}
                  error={formik.touched.ciec && Boolean(formik.errors.ciec)}
                  helperText={formik.touched.ciec && formik.errors.ciec}
                  inputProps={{ maxLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isLoading}
                />

                <Box my={5}>
                  <FormControlLabel
                    control={<CustomSwitch />}
                    label={
                      <Typography variant={isMobileOrTablet ? "body2" : "body1"}>
                        Autorizo a Lendit Group a consultar tu información ante el SAT. Consulta{" "}
                        <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                          términos y condiciones
                        </Link>{" "}
                        y{" "}
                        <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                          aviso de privacidad
                        </Link>{" "}
                      </Typography>
                    }
                    name="switch"
                    checked={formik.values.switch}
                    onChange={formik.handleChange}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="default"
                  disabled={!formik.isValid || isLoading}
                >
                  Continuar
                </Button>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            closeModal();
          }
        }}
      >
        <DialogTitle>
          <Typography color={"#002652"} textAlign={"center"} fontSize={"20px"} fontWeight={700} mt={2}>
            {isValidCiec === "false"
              ? "La CIEC ingresada es inválida."
              : " Estamos estableciendo conexión al SAT, esto puede demorar algunos minutos"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {isValidCiec === "false" ? (
            <>
              <Typography color={"#002652"} textAlign={"center"} fontSize={"18px"} fontWeight={400}>
                Inténtalo de nuevo.
              </Typography>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <ErrorOutline style={{ color: "red", fontSize: "40px" }} />
              </Box>

              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button variant="default" onClick={closeModal}>
                  Cerrar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <img src={clock} alt="Rotating SVG" />
              </Box>

              <Box mt={2} mb={2}>
                <Typography color={"#528CD6"} fontSize={"20px"} fontWeight={700} textAlign={"center"}>
                  Esto puede demorar algunos minutos
                </Typography>
              </Box>

              <Box mr={3} ml={3} mb={2}>
                <CustomLinearProgress variant="determinate" value={progress} />
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
