import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, useTheme, useMediaQuery, IconButton, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface FileParams {
  file_name: string;
  file_url: string;
}

export interface modalProps {
  fileParams: FileParams | null;
  onClose: () => void;
}

const ModalFilePreview: React.FC<modalProps> = ({ fileParams, onClose }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [viewer, setViewer] = useState("iframe");

  useEffect(() => {
    setOpen(!!fileParams);
    if (fileParams) {
      const file_extension = fileParams.file_name.split(".").pop();
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(file_extension || "")) {
        setViewer("image");
      } else {
        setViewer("iframe");
      }
    }
  }, [fileParams]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobileOrTablet}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          width: isMobileOrTablet ? "100%" : "90%",
          height: isMobileOrTablet ? "90%" : "100%",
          borderRadius: "10px",
          paddingTop: "10px",
          maxWidth: "1200px",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: "20px",
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {viewer === "image" ? (
          <img
            src={fileParams?.file_url}
            alt="pdf"
            style={{
              width: "100%",
              maxHeight: "100%",
            }}
          />
        ) : (
          <object
            data={fileParams?.file_url}
            type="application/pdf"
            width={"100%"}
            height={"100%"}
            style={{
              border: "1px solid #000",
            }}
          >
            <div>No se puede visualizar el archivo</div>
          </object>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalFilePreview;
