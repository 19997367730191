/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Typography,
  SxProps,
  FormHelperText,
  Modal,
} from "@mui/material";

import { RootState, useAppDispatch } from "@store/store";
import { useValidatePhoneCodeMutation, useRequestPhoneCodeMutation, usePostPhoneCodeQuery } from "@api/onboarding";

import { TerminosCondiciones } from "@components/TerminosCondiciones";
import { ProgressBar } from "@components/ProgressBar";
import { formatTime, formatPhoneNumber } from "@helpers/funciones";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useLazyAddPhoneVerifiedQuery } from "@api/account";

import img from "@assets/img/right_4.svg";
import "@styles/stylesRegistro.css";
import { setModalErrosVisible } from "@store/slices/appSlice";

import { InactivityHandler } from "@components/InactivityHandler";

const TIME_LIMIT = 120;

const CustomTextField: SxProps = {
  "& input": {
    textAlign: "center",
    fontSize: "20px",
    with: "50px",
    height: "80px",
  },
  margin: "8px",
  backgroundColor: "#E3F2F8",
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
  },
  "& input[disabled]": {
    cursor: "not-allowed",
  },
};

const validationSchema = yup.object().shape({
  code_1: yup.string().required("Campo requerido"),
  code_2: yup.string().required("Campo requerido"),
  code_3: yup.string().required("Campo requerido"),
  code_4: yup.string().required("Campo requerido"),
});

interface FormInitialValues {
  code_1: string;
  code_2: string;
  code_3: string;
  code_4: string;
}

export const ValidacionTelefono = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const personalData = useSelector((state: RootState) => state.register.personal_data);

  usePostPhoneCodeQuery(
    {
      country_code: personalData.country_code,
      phone_number: personalData.phone,
    },
    { skip: !(location.state && location.state.from.pathname == "/login") },
  );

  const [validatePhoneCode] = useValidatePhoneCodeMutation();
  const [requestPhoneCode] = useRequestPhoneCodeMutation();
  const [addPhoneVerified] = useLazyAddPhoneVerifiedQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [secondsRemaining, setSecondsRemaining] = useState(TIME_LIMIT);
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);
  const inputRef_3 = useRef<HTMLInputElement>(null);
  const inputRef_4 = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const initialValues: FormInitialValues = {
    code_1: "",
    code_2: "",
    code_3: "",
    code_4: "",
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values: FormInitialValues) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      await validatePhoneCode({
        country_code: personalData.country_code,
        phone_number: personalData.phone,
        code: `${values.code_1}${values.code_2}${values.code_3}${values.code_4}`,
      }).unwrap();

      await addPhoneVerified({
        id: personalData.id,
      }).unwrap();

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: application.type == import.meta.env.VITE_CODE_TAXS_CS_PFAE ? "CSDEM" : "CSVTE",
      }).unwrap();

      routeTaxSystems(String(application.type));
    } catch (error: any) {
      if (error?.status === 404 || error?.status === 400) {
        setErrorMessage("Código incorrecto, intenta de nuevo");
        return;
      }

      if (error?.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      await requestPhoneCode({
        country_code: personalData.country_code,
        phone_number: personalData.phone,
      }).unwrap();

      setSecondsRemaining(TIME_LIMIT);
    } catch (error: any) {
      if (error?.status === 429) {
        handleOpen();
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  useEffect(() => {
    const fetchInitialData = () => {
      if (!accountUser.email || !personalData.phone) {
        navigate("/producto/creditosimple");
      }
    };
    fetchInitialData();
  }, []);

  const routeTaxSystems = (tax: string) => {
    if (tax == import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
      navigate("/producto/creditosimple/datos_empresa_pfae");
    } else {
      navigate("/producto/creditosimple/datos_empresa_pm");
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData("text").trim().slice(0, 4);

    if (pasteData.length === 4) {
      formik.setFieldValue("code_1", pasteData[0]);
      formik.setFieldValue("code_2", pasteData[1]);
      formik.setFieldValue("code_3", pasteData[2]);
      formik.setFieldValue("code_4", pasteData[3]);

      inputRef_4.current?.focus();
    }
  };

  return (
    <Grid container height={"100%"}>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 3 : 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
            Validación de teléfono
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
            <ProgressBar progress={2} />
          </Container>

          <Typography variant={isMobileOrTablet ? "body2" : "h3"} mt={isMobileOrTablet ? 2 : 8} textAlign="center">
            Ingresa el código que enviamos vía <br /> whatsapp al número
          </Typography>

          <Typography variant={isMobileOrTablet ? "h5" : "h4"} mt={isMobileOrTablet ? 4 : 8} fontWeight={600}>
            ({personalData.country_code}) {formatPhoneNumber(personalData.phone)}
          </Typography>

          <Typography
            mt={1}
            variant={isMobileOrTablet ? "body2" : "body2"}
            component={"a"}
            onClick={() => navigate("/producto/creditosimple/registro_datos_personales")}
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            ¿No es tu número? Editalo aquí
          </Typography>

          <Typography mt={isMobileOrTablet ? 4 : 8} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={300}>
            Código
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 1, mb: 1 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <TextField
                  type="text"
                  name="code_1"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_2.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace") {
                      formik.setFieldValue("code_1", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_1 && Boolean(formik.errors.code_1)}
                  sx={CustomTextField}
                  inputProps={{
                    maxLength: 1,
                  }}
                  inputRef={inputRef_1}
                  disabled={isLoading}
                  value={formik.values.code_1}
                />
                <TextField
                  type="text"
                  name="code_2"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_3.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_2) {
                      inputRef_1.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_2", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_2 && Boolean(formik.errors.code_2)}
                  sx={CustomTextField}
                  inputProps={{
                    maxLength: 1,
                  }}
                  inputRef={inputRef_2}
                  disabled={isLoading}
                  value={formik.values.code_2}
                />
                <TextField
                  type="text"
                  name="code_3"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_4.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_3) {
                      inputRef_2.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_3", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_3 && Boolean(formik.errors.code_3)}
                  sx={CustomTextField}
                  inputProps={{
                    maxLength: 1,
                  }}
                  inputRef={inputRef_3}
                  disabled={isLoading}
                  value={formik.values.code_3}
                />
                <TextField
                  type="text"
                  name="code_4"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_4) {
                      inputRef_3.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_4", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_4 && Boolean(formik.errors.code_4)}
                  sx={CustomTextField}
                  inputProps={{
                    maxLength: 1,
                  }}
                  inputRef={inputRef_4}
                  disabled={isLoading}
                  value={formik.values.code_4}
                />
              </Box>

              {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}

              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                mt={isMobileOrTablet ? 2 : 6}
                textAlign="center"
                fontWeight={600}
              >
                {formatTime(secondsRemaining)} min
              </Typography>

              {secondsRemaining === 0 && (
                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 1 : 6}>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    component={"a"}
                    onClick={handleResendCode}
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Reenviar código
                  </Typography>
                </Box>
              )}

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 4 : 10}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={
                    isLoading ||
                    !formik.values.code_1 ||
                    !formik.values.code_2 ||
                    !formik.values.code_3 ||
                    !formik.values.code_4
                  }
                >
                  Continuar
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: 3, mb: 2 }}>
              <TerminosCondiciones />
            </Box>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} bgcolor={"#A3D4E8"} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography fontWeight={"600"} gutterBottom color={"#002652"} fontSize={"20px"}>
            Excedió el número de intentos permitidos para ese número
          </Typography>
          <Typography fontSize={"16px"} sx={{ mt: 2, mb: 2 }} color={"#002652"}>
            intenta más tarde o contacta a un asesor.
          </Typography>
          <Button variant="default" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};
