/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Modal, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { CustomUploadFile } from "@components/CustomUploadFile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";

import img from "@assets/img/img_tofu/img_tofu_3.svg";
import { UploadedFile } from "@interfaces/store";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { removeCompanyDocument, setCompanyCompleted, setCompanyDocument } from "@store/slices/bofuSlice";
import { UUID } from "crypto";

import { useGetApplicationByApplicationIdQuery, usePutApplicationCompletedMutation } from "@api/applications";
import { useLazyGetDocumentLoadApplicationClientIdQuery, usePostDocumentGenarateMutation } from "@api/documents";
import { Spinner, TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";

import CloseIcon from "@mui/icons-material/Close";

interface PropsButtoms {
  isFilled: boolean;
  buttonLabel: string;
  backgroundColor: string;
  color: string;
}

export const AvanceExpedienteDigital: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const tofuSection = useSelector((state: RootState) => state.register);
  const companySection = useSelector((state: RootState) => state.bofu.digital_file.company);

  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const [indetidadCompleted, setIndetidadCompleted] = useState(null);
  const [bankCompleted, setBankCompleted] = useState(null);
  const [referencesCompleted, setReferencesCompleted] = useState(null);
  const [applicationCompleted, setApplicationCompleted] = useState(null);

  const [dictamenCompleted, setDictamenCompleted] = useState(null);

  const [applicationCompletedState, setApplicationCompletedState] = useState("");

  const [hasDocsDDC, setHasDocsDDC] = useState(false);
  const [hasDocsSolicitud, setHasDocsSolicitud] = useState(false);
  const [isAbleButtom, setIsableButtom] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [visible, setVisible] = useState(false);

  const [solicitudCreditoProps, setSolicitudCreditoProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });
  const [referenciasProps, setReferenciasPropss] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });
  const [bancariaProps, setBancariaProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });
  const [indentidadProps, setIndentidadProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });

  const [documentValidate, setDocumentValidate] = useState<string | null>(null);

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const {
    data: applicationData,
    isSuccess,
    isLoading: loadingData,
  } = useGetApplicationByApplicationIdQuery(application.id);

  const [getDocumentByApplicationClient, { data: dataDocuments, isLoading: loadingDocumentData }] =
    useLazyGetDocumentLoadApplicationClientIdQuery();
  const [createDocument, { isLoading: loadingDocument }] = usePostDocumentGenarateMutation();

  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  useEffect(() => {
    if (application.id) {
      getDocumentByApplicationClient({ loanApplicationId: application.id, clientId: clientDetails.id });
    }
  }, [application]);

  useEffect(() => {
    if (applicationData) {
      setIndetidadCompleted(applicationData.data[0]?.identity_validation_completed);
      setBankCompleted(applicationData.data[0]?.bank_information_completed);
      setReferencesCompleted(applicationData.data[0]?.references_completed);
      setApplicationCompleted(applicationData.data[0]?.loan_application_completed);
      setApplicationCompletedState(`${applicationData.data[0]?.completed}`);

      setDictamenCompleted(applicationData.data[0]?.decision_validated);

      if (
        applicationData.data[0]?.identity_validation_completed &&
        applicationData.data[0]?.bank_information_completed &&
        applicationData.data[0]?.references_completed &&
        applicationData.data[0]?.loan_application_completed
      ) {
        formik.setFieldValue("isAllFilled", true);
        dispatch(setCompanyCompleted());
      }
    }
  }, [applicationData, isSuccess]);

  const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  const formik = useFormik({
    initialValues: {
      isAllFilled: false,
    },
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      navigate("/producto/creditosimple/docs_aval");
    },
  });

  const handleUpdateStore = (action: any, _: number, uploadedFile: UploadedFile | null, fileInternalId: UUID) => {
    if (action === "ADD" && uploadedFile !== null) {
      dispatch(setCompanyDocument(uploadedFile));
    } else if (action === "DEL") {
      dispatch(removeCompanyDocument(fileInternalId));
    }
  };

  const closeModal = () => setOpenModal(false);

  const getButtonProps = (status: boolean | any, document: string | any) => {
    if (document === "null") {
      if (status === null) {
        return {
          isFilled: false,
          buttonLabel: "Llenar",
          backgroundColor: "#528CD6",
          color: "#FFFFFF",
        };
      }

      if (!status) {
        return {
          isFilled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        };
      }

      if (status) {
        return {
          isFilled: true,
          buttonLabel: "Completo",
          backgroundColor: "#002652",
          color: "#A3D4E8",
        };
      }
    }

    if (document === "false") {
      if (status === null) {
        return {
          isFilled: false,
          buttonLabel: "Llenar",
          backgroundColor: "#528CD6",
          color: "#FFFFFF",
        };
      }

      if (!status) {
        return {
          isFilled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        };
      }

      if (status) {
        return {
          isFilled: true,
          buttonLabel: "Completo",
          backgroundColor: "#002652",
          color: "#A3D4E8",
        };
      }
    }

    if (document === "true" && status) {
      return {
        isFilled: true,
        buttonLabel: "Validado",
        backgroundColor: "#A3D4E8",
        color: "#528CD6",
      };
    }

    return { isFilled: false, buttonLabel: "Llenar", backgroundColor: "#528CD6", color: "#FFFFFF" };
  };

  useEffect(() => {
    if (!dataDocuments) return;

    const filteredFiles = dataDocuments.filter((file) => file.file_name.includes("solicitud_credito"));
    const validatedFile = filteredFiles.find((item) => item.status);

    if (filteredFiles.length === 0 || validatedFile === null || validatedFile === undefined) {
      setDocumentValidate("null");
    } else {
      setDocumentValidate(`${validatedFile?.validated}`);
    }

    const allCompleted = applicationCompleted && referencesCompleted && bankCompleted;

    if (allCompleted) {
      if (filteredFiles.length === 0) {
        generateSolicitudDocument();
        setHasDocsSolicitud(true);
      } else {
        setHasDocsSolicitud(true);
      }
    }
  }, [dataDocuments, applicationCompleted, referencesCompleted, bankCompleted]);

  useEffect(() => {
    if (!loadingData && !loadingDocumentData && isSuccess) {
      setSolicitudCreditoProps(getButtonProps(applicationCompleted, documentValidate));
      setReferenciasPropss(getButtonProps(referencesCompleted, documentValidate));
      setBancariaProps(getButtonProps(bankCompleted, documentValidate));
      setIndentidadProps(getButtonProps(indetidadCompleted, documentValidate));
    }
  }, [
    applicationCompleted,
    referencesCompleted,
    bankCompleted,
    indetidadCompleted,
    documentValidate,
    loadingData,
    loadingDocumentData,
    isSuccess,
  ]);

  useEffect(() => {
    if (!loadingData && !loadingDocumentData && isSuccess) {
      setVisible(true);
    }
  }, [loadingData, loadingDocumentData, isSuccess]);

  const generateSolicitudDocument = async () => {
    try {
      const data = {
        loan_application_id: String(application.id),
        document_type: "solicitud_credito",
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status === 500 || documents?.error?.status === 404) {
        throw new Error(JSON.stringify({ status: 500 }));
      }

      if (documents?.error?.status === 400) {
        throw new Error(JSON.stringify({ status: 400 }));
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  useEffect(() => {
    const fileCodeToFilterDCD = "DCD";
    const filteredDCD = companySection.files.filter((item) => item.file_code === fileCodeToFilterDCD);
    const exist = filteredDCD.length > 0;
    setHasDocsDDC(exist);
  }, [companySection.files]);

  useEffect(() => {
    const exist = hasDocsDDC && hasDocsSolicitud;
    setIsableButtom(exist);
  }, [hasDocsDDC, hasDocsSolicitud]);

  const updateStatusComplete = async (id: string) => {
    try {
      const data = {
        completed: null,
      };

      await putAapplicationCompleted({ id_sol: id, body: data });
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    if (applicationCompletedState === "false") {
      if (applicationCompleted && referencesCompleted && bankCompleted && indetidadCompleted) {
        updateStatusComplete(String(application.id));
        generateSolicitudDocument();
        setKey((prevKey) => prevKey + 1);
      }
    }
  }, [
    applicationCompleted,
    referencesCompleted,
    bankCompleted,
    indetidadCompleted,
    documentValidate,
    loadingData,
    loadingDocumentData,
    isSuccess,
    applicationCompletedState,
    application,
  ]);

  const handleButtonClick = () => {
    if (!dictamenCompleted) {
      setOpenModal(true);
    }
  };

  return (
    <>
      <Grid container>
        <Spinner open={loadingDocument} />
        <Grid item xs={12} md={8} mt={2}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
              Expediente digital
            </Typography>

            <TabStepBofuPm key={key} />

            <Typography
              mt={4}
              variant={isMobileOrTablet ? "body2" : "body1"}
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Muy bien, ahora llena los formatos relacionados a la empresa
            </Typography>
            <Typography
              mt={2}
              variant={isMobileOrTablet ? "body2" : "body1"}
              sx={{ color: "#528CD6", fontSize: "16px", fontWeight: 300 }}
            >
              {tofuSection.company.legal_name ?? "Nombre del Cliente"}
            </Typography>

            {!visible ? (
              <>
                <Skeleton variant="text" animation="wave" width={"100%"} />
                <Skeleton variant="text" animation="wave" width={"100%"} />
                <Skeleton variant="text" animation="wave" width={"100%"} />
                <Skeleton variant="text" animation="wave" width={"100%"} />
              </>
            ) : (
              <Box>
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Solicitud de crédito
                  </Typography>
                  <Button
                    variant="default"
                    onClick={() => navigate("/producto/creditosimple/solicitud_credito_1")}
                    disabled={solicitudCreditoProps?.isFilled}
                    sx={{
                      width: "160px",
                      fontSize: "15px",
                      height: "30px",
                      backgroundColor: solicitudCreditoProps?.backgroundColor,
                      color: solicitudCreditoProps?.color,
                      "&:hover": {
                        backgroundColor: solicitudCreditoProps?.backgroundColor,
                      },
                      "&.Mui-disabled": {
                        backgroundColor: solicitudCreditoProps?.backgroundColor,
                        color: solicitudCreditoProps?.color,
                        opacity: 1,
                      },
                    }}
                  >
                    {solicitudCreditoProps?.buttonLabel}
                  </Button>
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Referencias
                  </Typography>
                  <Button
                    variant="default"
                    onClick={() => navigate("/producto/creditosimple/referencias_clientes")}
                    disabled={referenciasProps?.isFilled}
                    sx={{
                      width: "160px",
                      fontSize: "15px",
                      height: "30px",
                      backgroundColor: referenciasProps?.backgroundColor,
                      color: referenciasProps?.color,
                      "&:hover": {
                        backgroundColor: referenciasProps?.backgroundColor,
                      },
                      "&.Mui-disabled": {
                        backgroundColor: referenciasProps?.backgroundColor,
                        color: referenciasProps?.color,
                        opacity: 1,
                      },
                    }}
                  >
                    {referenciasProps?.buttonLabel}
                  </Button>
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Información bancaria
                  </Typography>
                  <Button
                    variant="default"
                    onClick={() => navigate("/producto/creditosimple/info_bancaria_empresa")}
                    disabled={bancariaProps?.isFilled}
                    sx={{
                      width: "160px",
                      fontSize: "15px",
                      height: "30px",
                      backgroundColor: bancariaProps?.backgroundColor,
                      color: bancariaProps?.color,
                      "&:hover": {
                        backgroundColor: bancariaProps?.backgroundColor,
                      },
                      "&.Mui-disabled": {
                        backgroundColor: bancariaProps?.backgroundColor,
                        color: bancariaProps?.color,
                        opacity: 1,
                      },
                    }}
                  >
                    {bancariaProps?.buttonLabel}
                  </Button>
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Validación de identidad
                  </Typography>
                  <Box onClick={handleButtonClick}>
                    <div
                      style={{
                        pointerEvents: !dictamenCompleted ? "none" : "auto",
                        opacity: !dictamenCompleted ? 0.5 : 1,
                      }}
                    >
                      <Button
                        variant="default"
                        onClick={() => navigate("/producto/creditosimple/validacion_representante_legal")}
                        disabled={indentidadProps?.isFilled}
                        sx={{
                          width: "160px",
                          fontSize: "15px",
                          height: "30px",
                          backgroundColor: indentidadProps?.backgroundColor,
                          color: indentidadProps?.color,
                          "&:hover": {
                            backgroundColor: indentidadProps?.backgroundColor,
                          },
                          "&.Mui-disabled": {
                            backgroundColor: indentidadProps?.backgroundColor,
                            color: indentidadProps?.color,
                            opacity: 1,
                          },
                        }}
                      >
                        {indentidadProps?.buttonLabel}
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Box>
            )}

            <Typography mt={4} variant={isMobileOrTablet ? "body2" : "body1"}>
              Necesitarás tener la identificación a la mano y tomar un par de selfies
            </Typography>
            <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
              Ahora carga los documentos relacionados a la empresa
            </Typography>
            <Box mt={2} textAlign="left">
              <form onSubmit={formik.handleSubmit} noValidate>
                <CustomUploadFile
                  py={2}
                  updateStore={handleUpdateStore}
                  optionalName="Comprobante de domicilio"
                  uploadedFile={companySection.files.find((uploadedFile) => uploadedFile.file_code === "DCD")}
                  optionalDescription="No deberá ser mayor a 3 meses"
                  setFieldValue={formik.setFieldValue}
                  registerFormik={registerInternalFormik}
                  errors={formik.errors}
                  required
                  metadata={{
                    taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                    fileCode: "DCD",
                    uploadType: "client",
                    loan_application_id: application.id,
                    regimen: tofuSection.application.type,
                    clientDetailsId: tofuSection.personal_data.id,
                  }}
                />

                <Box mb={3} textAlign={"center"}>
                  <Button variant="default" type="submit" disabled={!isAbleButtom}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Modal open={openModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,

            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#A3D4E8",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color={"#528CD6"} fontWeight={600} fontSize={"17px"}>
            Sección no disponible
          </Typography>
          <Typography sx={{ mt: 2 }} color={"#002652"} fontWeight={300} fontSize={"14px"}>
            La sección de Validación de identidad, estará disponible una vez que se encuentre validado el dictamen
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
