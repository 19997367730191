/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import img from "@assets/img/img_tofu/img_tofu_15.svg";

import { RootState, useAppDispatch } from "@store/store";
import { useSelector } from "react-redux";

import { InactivityHandler } from "@components/InactivityHandler";
import { useEffect, useRef, useState } from "react";
import { useLazyGetMetaMapDataQuery } from "@api/metaMapApi";
import { setModalErrosVisible } from "@store/slices/appSlice";

import { useLazyGetPersonByIdQuery } from "@api/personasApi";

export const ValidarIdentidadAval = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const aval = useSelector((state: RootState) => state.register.shareholder);

  const [getDataMetaMap, { data, isSuccess }] = useLazyGetMetaMapDataQuery();
  const [getDocumentByPersonaId, { data: dataAval, isSuccess: isSuccessAval }] = useLazyGetPersonByIdQuery();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isIdentityCompleted, setIsIdentityCompleted] = useState<any>(null);

  const [attempts, setAttempts] = useState(0);

  const metamapButtonRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const [metaDataJson, setMetaDataJson] = useState({
    client_details_id: "",
    loan_application_id: "",
    person_id: "",
    person_type: "",
    regimen: "",
    attempts: 0,
    upload_type: "",
  });

  useEffect(() => {
    if (aval?.id) {
      metaMapData(String(aval.id));
    }
  }, [aval]);

  useEffect(() => {
    getApplication();
  }, []);

  useEffect(() => {
    if (data?.data && isSuccess) {
      setMetaDataJson({
        client_details_id: data?.data?.client_details_id,
        loan_application_id: data?.data?.loan_application_id,
        person_id: data?.data?.person_id,
        person_type: data?.data?.person_type,
        regimen: data?.data?.regimen,
        attempts: data?.data?.attempts,
        upload_type: data?.data?.upload_type,
      });
      setAttempts(data?.data?.attempts);
      setIsLoading(false);
    }
  }, [data?.data, isSuccess]);

  useEffect(() => {
    if (dataAval && isSuccessAval) {
      setIsIdentityCompleted(dataAval?.data?.identity_validation_completed);
    }
  }, [dataAval?.data, isSuccessAval]);

  useEffect(() => {
    if (isIdentityCompleted) {
      setIsDisabled(true);
    } else {
      if (attempts > 3) {
        setIsDisabled(true);
      }
    }
  }, [attempts, isIdentityCompleted]);

  const metaMapData = async (id: string) => {
    try {
      await getDataMetaMap(id).unwrap();
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 403 || error?.status === "FETCH_ERROR") {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        setIsDisabled(true);
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        setIsDisabled(true);
        return;
      }
    }
  };

  const getApplication = async () => {
    try {
      await getDocumentByPersonaId(String(aval.id)).unwrap();
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      }
    }
  };

  useEffect(() => {
    const handleUserFinishedSdk = (_event: CustomEvent) => {
      setIsDisabled(true);
    };

    const metamapButton = metamapButtonRef.current;

    if (metamapButton) {
      metamapButton.addEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
    }

    return () => {
      if (metamapButton) {
        metamapButton.removeEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
      }
    };
  }, []);

  const goBack = () => {
    window.location.href = "/producto/creditosimple/docs_aval";
  };

  return (
    <>
      {/* <Spinner open={isLoading} /> */}
      <Grid container>
        {/* Form Section */}
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={1}>
              <Typography align={"center"} fontSize={"16px"} fontWeight={600} color={"#002652"}>
                Validación identidad
              </Typography>
            </Box>

            <Typography fontSize={"14px"} fontWeight={400} color={"#528CD6"} align="center" mt={3} mb={3}>
              {aval.name + " " + aval.first_lastname + " " + aval.second_lastname}
            </Typography>

            <Typography fontSize={"14px"} fontWeight={600} color={"#002652"} align="center" mt={3} mb={3}>
              Validaremos tu identidad por medio de fotografías faciales y tu identificación oficial
            </Typography>

            <Typography align={"center"} fontSize={"14px"} fontWeight={400} color={"#002652"} mt={3} mb={3}>
              En caso de no ser tú el Aval de la Empresa, podrás reenviar la solicitud de validación vía correo al
              iniciar el proceso.
            </Typography>

            <Box mt={2} mb={2} sx={{ pointerEvents: isLoading ? "none" : "auto", opacity: isLoading ? 0.5 : 1 }}>
              <div style={{ pointerEvents: isDisabled ? "none" : "auto", opacity: isDisabled ? 0.5 : 1 }}>
                <metamap-button
                  ref={metamapButtonRef}
                  clientid={import.meta.env.VITE_META_CLIENT_ID}
                  flowId={import.meta.env.VITE_META_FLOW_ID}
                  merchantToken={import.meta.env.VITE_MERCHANT_TOKEN}
                  language="es"
                  metadata={JSON.stringify(metaDataJson)}
                  color="#528CD6"
                />
              </div>
            </Box>

            <Box display="flex" justifyContent="center" mt={4}>
              <Button variant="blue_outlined" type="button" onClick={() => goBack()}>
                Regresar al expediente
              </Button>
            </Box>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
