import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CustomCheckmark from "@assets/img/icon_check.svg";

const steps = [
  { label: "Agendar \nvisita" },
  { label: "Visita" },
  { label: "Análisis visita y \ndocumentos" },
  { label: "Firma" },
];

interface ProgressNavbarProps {
  currentStep: number;
}

export const NavBarOcularVisit: React.FC<ProgressNavbarProps> = ({ currentStep }) => {
  const totalStepsForBar = 4;
  const adjustedProgress = currentStep === 5 ? 95 : (currentStep / totalStepsForBar) * 100;
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width="90%" mx="auto" display="flex" flexDirection="column" alignItems="center" mt={4}>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
        {steps.map((step, index) => (
          <Box key={index} display="flex" flexDirection="column" alignItems="center">
            <Box
              sx={{
                width: isMobileOrTablet ? 22 : 30,
                height: isMobileOrTablet ? 22 : 30,
                borderRadius: "50%",
                backgroundColor:
                  currentStep === 0
                    ? "#fff"
                    : currentStep > index + 1
                      ? "#002652"
                      : currentStep === index + 1
                        ? "#002652"
                        : "#fff",
                border: `2px solid ${currentStep > index ? "#002652" : "#528CD6"}`,
                color:
                  currentStep === index + 1
                    ? "#fff"
                    : currentStep > index + 1
                      ? "#fff"
                      : "#528CD6",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 16,
                fontWeight: currentStep > index || currentStep === index + 1 ? "bold" : "normal",
                fontFamily: "Outfit, sans-serif",
              }}
            >
              {currentStep > index + 1 ? (
                <Box
                  component="img"
                  src={CustomCheckmark}
                  alt="Checkmark"
                  sx={{
                    width: isMobileOrTablet ? 16 : 18,
                    height: isMobileOrTablet ? 16 : 18,
                    olor: "#00A024",
                  }}
                >

                </Box>
              ) : (
                index + 1
              )}
            </Box>
            <Box
              mt={1}
              sx={{
                fontSize: isMobileOrTablet ? 13 : 16,
                color: currentStep > index || currentStep === index + 1 ? "#002652" : "#528CD6",
                fontWeight: currentStep > index || currentStep === index + 1 ? "bold" : "normal",
                whiteSpace: "pre-wrap",
                textAlign: "center",
                fontFamily: "Outfit, sans-serif",
              }}
            >
              {step.label}
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor: "#E3F2F8",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: currentStep > 0 ? `${adjustedProgress}%` : "0%",
              backgroundColor: "#002652",
              transition: "width 0.3s ease",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};