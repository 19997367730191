import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Home } from "@pages/home";
import { Login } from "@pages/Login";
import { CambiarContrasena } from "@pages/CambiaContrasena";
import { NuevaContraseña } from "@pages/nuevaContraseña";
import MainLayout from "../layouts/MainLayout";
import { RootState } from "@store/store";
import { CreditoSimple } from "@pages/producto/credito_simple/";
import { ValidateCodeLogin } from "@pages/home/ValidateCodeLogin";
import { ProtectedRoute } from "@routes/ProtectedRoute";
import { CrearCuenta } from "@pages/producto/credito_simple/tofu/pm/CrearCuenta";
import { RegistroDatosPersonales } from "@pages/producto/credito_simple/tofu/pm/RegistroDatosPersonales";
import { ValidacionTelefono } from "@pages/producto/credito_simple/tofu/pm/ValidacionTelefono";

export const LenditRoute = () => {
  const appData = useSelector((state: RootState) => state.app);

  return (
    <Routes>
      <Route path="/producto/creditosimple/crear_cuenta" element={<MainLayout />}>
        <Route index element={<CrearCuenta />} />
      </Route>

      <Route path="/producto/creditosimple/registro_datos_personales" element={<MainLayout />}>
        <Route index element={<RegistroDatosPersonales />} />
      </Route>

      <Route path="/producto/creditosimple/validacion_telefono" element={<MainLayout />}>
        <Route index element={<ValidacionTelefono />} />
      </Route>

      <Route
        path="/producto/creditosimple/*"
        element={
          <ProtectedRoute>
            <CreditoSimple />
          </ProtectedRoute>
        }
      />

      <Route path="/home/*" element={<Home />} />
      <Route path="/cambia_contraseña" element={<MainLayout />}>
        <Route index element={<CambiarContrasena />} />
      </Route>
      <Route path="/nueva_contraseña" element={<MainLayout />}>
        <Route index element={<NuevaContraseña />} />
      </Route>
      <Route path="/login" element={<MainLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/validacion_codigo_login" element={<MainLayout />}>
        <Route index element={<ValidateCodeLogin />} />
      </Route>
      {/* <Route path="*" element={<Navigate to={"/login"} />}></Route> */}
      <Route path="*" element={<Navigate to={appData.process_page ? appData.process_page : "/login"} />}></Route>
    </Routes>
  );
};
