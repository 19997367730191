import { Backdrop, CircularProgress, Theme } from "@mui/material";

interface SpinnerProps {
  open: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ open }) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
