/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  Link,
  Stack,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useBuroValidatorMutation, BuroValidatorRequest } from "@api/sat_apis_new/satApsi";
import fondo_rfc from "@assets/img/img_buro_pfae.svg";
import { CustomSwitch, CustomDialog, ProgressBar } from "@components/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { obtenerFechaHoy } from "@helpers/index";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

interface FormValues {
  prestamo_hipotecario: boolean;
  credito_automotriz: boolean;
  tarjeta_credito: boolean;
  tarjeta: string;
  authorize: boolean;
  at_least_one: boolean;
}

export const ConsultaBuroPfae = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [buroValidator] = useBuroValidatorMutation();

  const companyData = useSelector((state: RootState) => state.register.company);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const [initialValues] = useState<FormValues>({
    prestamo_hipotecario: false,
    credito_automotriz: false,
    tarjeta_credito: false,
    tarjeta: "",
    authorize: false,
    at_least_one: false,
  });

  const validationSchema = yup.object().shape({
    credito_automotriz: yup.boolean(),
    prestamo_hipotecario: yup.boolean(),
    tarjeta_credito: yup.boolean(),
    authorize: yup.boolean().isTrue("Se requiere aceptar"),
    tarjeta: yup.string().when("tarjeta_credito", {
      is: true,
      then: (schema) => schema.required("Campo requerido").min(4, "Formato de tarjeta no válido"),
    }),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const names = (companyData.pfae_name ?? "").split(" ");
      const first_name = names[0] || "";
      const second_name = names.slice(1).join(" ") || "";

      const payload_buro: BuroValidatorRequest = {
        person_Type: "pf",
        data: {
          rfc: companyData.rfc,
          transaction_number: String(applicationData.id_application),
          application_code: import.meta.env.VITE_APPLICATION_CODE,
          legal_name: companyData.legal_name,
          first_name: first_name,
          second_name: second_name,
          father_last_name: companyData.pfae_last_name ?? "",
          mother_last_name: companyData.pfae_last_name_2 ?? "",
          reenter_NIP: true,
          authorization_label_response: true,
          loan_application_id: String(applicationData.id),
          pf: {
            mortgage_credit: values.prestamo_hipotecario,
            automotive_credit: values.credito_automotriz,
            credit_card: values.tarjeta_credito,
            last_four_digits: values.tarjeta,
          },
        },
      };

      await buroValidator(payload_buro).unwrap();

      await triggerPostNextStage({
        applicationId: applicationData.id,
        statusCode: "CSABA",
      }).unwrap();

      navigate("/producto/creditosimple/waiting_room");
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnMount: true,
  });

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  useEffect(() => {
    if (formik.values.authorize) {
      setCloseModal(false);
    } else {
      setCloseModal(true);
    }
  }, [formik.values.authorize]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 4 : 8,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
              Consulta Buró
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
              <ProgressBar progress={8} />
            </Container>

            <Box maxWidth={"sm"} mt={2} textAlign={"center"}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"}>
                Mientras hacemos tu consulta al SAT como PFAE, necesitamos consultar tu Buró de Crédito como Persona
                Física.
              </Typography>
            </Box>
          </Box>

          <Container maxWidth="sm" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container rowSpacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="one" name="prestamo_hipotecario" onChange={formik.handleChange} />}
                    label="¿Tiene un préstamo hipotecario?"
                  />
                  {Boolean(formik.touched.at_least_one) && Boolean(formik.errors.at_least_one) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.at_least_one}</FormHelperText>
                  )}
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="two" name="credito_automotriz" onChange={formik.handleChange} />}
                    label="¿Ha tenido un crédito automotriz?"
                  />
                  {Boolean(formik.touched.at_least_one) && Boolean(formik.errors.at_least_one) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.at_least_one}</FormHelperText>
                  )}
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="three" name="tarjeta_credito" onChange={formik.handleChange} />}
                    label="¿Es titular de una tarjeta de crédito?"
                  />
                  {Boolean(formik.touched.at_least_one) && Boolean(formik.errors.at_least_one) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.at_least_one}</FormHelperText>
                  )}
                </Grid>
                {formik.values.tarjeta_credito && (
                  <Grid item xs={12}>
                    <TextField
                      id="tarjeta"
                      name="tarjeta"
                      label="Últimos 4 dígitos tarjeta de crédito"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      inputProps={{ maxLength: 4, inputMode: "numeric" }}
                      disabled={!formik.values.tarjeta_credito}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.match(/[^0-9]/)) {
                          event.preventDefault();
                          return;
                        }
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.tarjeta}
                      error={formik.touched.tarjeta && Boolean(formik.errors.tarjeta)}
                      helperText={formik.touched.tarjeta && formik.errors.tarjeta}
                    />
                  </Grid>
                )}
                <Grid item xs={12} py={2} mx={1}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomSwitch id="authorize" name="authorize" onChange={formik.handleChange} />
                    <Typography sx={{ cursor: "pointer" }} variant={isMobileOrTablet ? "body2" : "body1"}>
                      Autorizo a Lendit group a consultar tu información ante Buró de Crédito. Consulta{" "}
                      <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                        términos y condiciones
                      </Link>{" "}
                      y{" "}
                      <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                        aviso de privacidad
                      </Link>{" "}
                    </Typography>
                  </Stack>
                  {Boolean(formik.touched.authorize) && Boolean(formik.errors.authorize) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.authorize}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button variant="default" type="submit" color="primary" disabled={isLoading || !formik.isValid}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#A3D4E8"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={fondo_rfc}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomDialog
        colorClose="#A3D4E8"
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600}>
            Consulta a Buró de Crédito
          </Typography>
        }
        itemContent={
          <Typography textAlign={"justify"} variant="body2" color={"#002652"}>
            {obtenerFechaHoy()}, como obligado solidario de la Persona Física suscrita, autorizo a Lendit Group SAPI de
            CV y/o cualquier de sus afiliadas, subsidiarias o personas relacionadas, para consultar mi comportamiento
            crediticio en cualquier sociedad de información crediticia. Conozco la información que se solicita, el uso
            que se le dará y autorizo posibles consultas periódicas con una vigencia de 2 (dos) años o más, mientras se
            mantenga la relación jurídica.
          </Typography>
        }
      />
    </>
  );
};
