/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Select, MenuItem, IconButton, SelectChangeEvent } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface TablePaginationProps {
  totalItems: number;
  onChange: (page: number, rowsPerPage: number) => void;
}

export const TablePagination = (props: TablePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const handlePreviousPage = () => {
    const new_page = currentPage - 1;
    setCurrentPage(new_page);
    props.onChange(new_page, rowsPerPage);
  };

  const handleNextPage = () => {
    const new_page = currentPage + 1;
    setCurrentPage(new_page);
    props.onChange(new_page, rowsPerPage);
  };

  const handleSelectPage = (page: number) => {
    setCurrentPage(page);
    props.onChange(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  useEffect(() => {
    const rows_per_page = rowsPerPage;
    const total_pages = Math.ceil(props.totalItems / rows_per_page);

    setTotalPages(total_pages);
    setCurrentPage(1);
    props.onChange(1, rows_per_page);
  }, [props.totalItems, rowsPerPage]);

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ marginTop: 2, paddingRight: 2 }}>
        <Typography sx={{ color: "#6D7882", marginRight: 2 }}>Anterior</Typography>
        <IconButton onClick={handlePreviousPage} disabled={currentPage === 1} sx={{ color: "#6D7882" }}>
          <ArrowBackIosIcon />
        </IconButton>

        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <Button
            key={page}
            onClick={() => handleSelectPage(page)}
            sx={{
              backgroundColor: "transparent",
              color: "#6D7882",
              border: "none",
              minWidth: "40px",
              margin: "0 4px",
              padding: "6px 12px",
              textTransform: "none",
              fontWeight: currentPage === page ? "bold" : "normal",
            }}
          >
            {page}
          </Button>
        ))}

        <IconButton onClick={handleNextPage} disabled={currentPage === totalPages} sx={{ color: "#6D7882" }}>
          <ArrowForwardIosIcon />
        </IconButton>
        <Typography sx={{ color: "#6D7882", marginLeft: 2 }}>Siguiente</Typography>

        <Box display="flex" alignItems="center" sx={{ marginLeft: 3 }}>
          <Typography color={"#6D7882"} sx={{ marginRight: 1 }}>
            Ver
          </Typography>
          <Select
            value={rowsPerPage.toString()}
            onChange={handleRowsPerPageChange}
            sx={{ minWidth: "60px", color: "#6D7882", borderColor: "#6D7882" }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
          <Typography color={"#6D7882"} sx={{ marginLeft: 1 }}>
            Clientes por página
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
