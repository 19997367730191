/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";

import * as yup from "yup";
import { UUID } from "crypto";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { UploadedFile } from "@interfaces/store";

import { CustomUploadFile } from "@components/CustomUploadFile";

import { setModalErrosVisible } from "@store/slices/appSlice";
import { removeCompanyDocument, setCompanyDocument } from "@store/slices/bofuSlice";

import img from "@assets/img/img_tofu/img_tofu_2.svg";
import { useGetApplicationByApplicationIdQuery, usePutApplicationUpdateStatusMutation } from "@api/applications";
import { useGetStatusQuery } from "@api/catalogs";
import { TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";

export const DocsEmpresa = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const companySection = useSelector((state: RootState) => state.bofu.digital_file.company);

  const { data: application_data, isSuccess } = useGetApplicationByApplicationIdQuery(applicationData.id);
  const [validatatePath, setvalidatatePath] = useState(false);
  const { data: statusList } = useGetStatusQuery();
  const [status, setStatus] = useState("");

  const [upDateStatus] = usePutApplicationUpdateStatusMutation();

  const handleUpdateStore = (action: any, _: number, uploadedFile: UploadedFile | null, fileInternalId: UUID) => {
    if (action === "ADD" && uploadedFile !== null) {
      dispatch(setCompanyDocument(uploadedFile));
    } else if (action === "DEL") {
      dispatch(removeCompanyDocument(fileInternalId));
    }
  };

  useEffect(() => {
    if (statusList) {
      const aproved = statusList.find((item: { code: string }) => item.code === "CSAED");
      setStatus(String(aproved?.id));
    }
  }, [statusList]);

  useEffect(() => {
    if (application_data) {
      if (application_data.data[0].status_catalog?.url_actual === "/producto/creditosimple/docs_empresa") {
        setvalidatatePath(true);
      } else {
        setvalidatatePath(false);
      }
    }
  }, [application_data, isSuccess]);

  const [extraFiles, setExtraFiles] = useState<Array<ReactElement>>(
    companySection.files.filter((uploadedFile) => uploadedFile.file_code === "DAA").length > 0
      ? companySection.files
        .filter((uploadedFile) => uploadedFile.file_code === "DAA")
        .map((uploadedFile, index) => (
          <CustomUploadFile
            py={2}
            key={uploadedFile.id}
            index={index}
            updateStore={handleUpdateStore}
            uploadedFile={uploadedFile}
            metadata={{
              fileCode: "DAA",
              taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
              uploadType: "client",
              loan_application_id: applicationData.id,
              regimen: applicationData.type,
              clientDetailsId: personalData.id,
            }}
          />
        ))
      : [
        <CustomUploadFile
          py={2}
          key={crypto.randomUUID()}
          updateStore={handleUpdateStore}
          metadata={{
            fileCode: "DAA",
            taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
            uploadType: "client",
            loan_application_id: applicationData.id,
            regimen: applicationData.type,
            clientDetailsId: personalData.id,
          }}
        />,
      ],
  );
  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      if (bothFilesExist) {
        if (validatatePath) {
          try {
            const data = {
              status_id: status,
            };
            await upDateStatus({ id_sol: applicationData.id, body: data });
            navigate("/producto/creditosimple/avance_expediente_digital");
          } catch (error: any) {
            if (error?.status === 500 || error?.status === 409) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
              return;
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
              return;
            }
          }
        } else {
          navigate("/producto/creditosimple/avance_expediente_digital");
        }
      }
    },
  });

  const registerFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  const [bothFilesExist, setBothFilesExist] = useState(false);

  useEffect(() => {
    const fileCodeToFilterDAC = "DAC";
    const filteredDataDAC = companySection.files.filter((item) => item.file_code === fileCodeToFilterDAC);

    const fileCodeToFilterDIORL = "DIORL";
    const filteredDataDIORL = companySection.files.filter((item) => item.file_code === fileCodeToFilterDIORL);
    const bothExist = filteredDataDAC.length > 0 && filteredDataDIORL.length > 0;

    setBothFilesExist(bothExist);
  }, [companySection.files]);

  const handleOnClickAddExtra = () => {
    setExtraFiles([
      ...extraFiles,
      <CustomUploadFile
        key={crypto.randomUUID()}
        updateStore={handleUpdateStore}
        py={2}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          fileCode: "DAA",
          uploadType: "client",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          clientDetailsId: personalData.id,
        }}
      />,
    ]);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
            Expediente digital
          </Typography>

          <TabStepBofuPm />

          <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400} color={"#528CD6"} mt={3}>
            Comenzaremos con el dictamen de tu empresa, para lo que necesitaremos los siguientes documentos
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <CustomUploadFile
              py={2}
              updateStore={handleUpdateStore}
              uploadedFile={companySection.files.find((uploadedFile) => uploadedFile.file_code == "DAC")}
              optionalDescription="Deberá contar con su registro público de la propiedad"
              registerFormik={registerFormik}
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
              metadata={{
                taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                fileCode: "DAC",
                uploadType: "client",
                loan_application_id: applicationData.id,
                regimen: applicationData.type,
                clientDetailsId: personalData.id,
              }}
            />

            {extraFiles.length > 0 && <Box id="extra_files">{extraFiles.map((file) => file)}</Box>}
            <Box textAlign={"right"}>
              <Link onClick={handleOnClickAddExtra} sx={{ cursor: "pointer" }}>
                <Typography variant="body2">+ Agregar acta adicional</Typography>
              </Link>
            </Box>

            <CustomUploadFile
              py={2}
              updateStore={handleUpdateStore}
              uploadedFile={companySection.files.find((uploadedFile) => uploadedFile.file_code == "DIORL")}
              optionalDescription="Vigente, puede ser INE, Pasaporte o MF2"
              registerFormik={registerFormik}
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
              metadata={{
                taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                fileCode: "DIORL",
                uploadType: "client",
                loan_application_id: applicationData.id,
                regimen: applicationData.type,
                clientDetailsId: personalData.id,
              }}
            />

            <Button sx={{ margin: "10px 5px" }} variant="default" type="submit" disabled={!bothFilesExist}>
              Continuar
            </Button>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
