/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";

import img from "@assets/img/img_digital_right.svg";
import { ProgressBar } from "@components/index";
import { RadioButtonUnchecked, CheckCircleOutline } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { usePostValidateNextStageMutation } from "@api/applications";
import {
  useLazyGetSocialMediaQuery,
  usePatchSocialMediaDataMutation,
  usePostSocialMediaMutation,
} from "@api/socialMediaApi";
import { WEP_PAGE } from "@utils/expreciones";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { useEffect, useState } from "react";

const CustomRadio = styled(Radio)({
  "& .MuiSvgIcon-root": {
    fontSize: 21, // Cambia el tamaño del icono
  },
  color: "#A3D4E8",
  "&.Mui-checked": {
    color: "#528CD6", // Color del icono de paloma
  },
});

export const PresenciaDigitalPm = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [postSocialMedia, { isLoading }] = usePostSocialMediaMutation();
  const [patchMedia] = usePatchSocialMediaDataMutation();

  const application = useSelector((state: RootState) => state.register.application);
  const person = useSelector((state: RootState) => state.register.personal_data);

  const [getSolialMedia, { data, isSuccess }] = useLazyGetSocialMediaQuery();

  const [idMedia, setIdMedia] = useState("");

  const dispatch = useAppDispatch();

  const initialValues = {
    has_web: "",
    pagina_web: "",
    has_solcial_media: "",
    linkin: "",
    face: "",
    insta: "",
    x_twttiter: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      has_web: yup.boolean().required("Campo requerido"),
      pagina_web: yup.string().when("has_web", {
        is: true,
        then: (schema) => schema.required("Campo requerido").matches(WEP_PAGE, "URL no válida"),
      }),
      has_solcial_media: yup.boolean().required("Campo requerido"),
      linkin: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "linkin",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),

      face: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "face",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),
      insta: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "insta",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),

      x_twttiter: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "x_twttiter",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        const data = {
          has_web_page: Boolean(values.has_web),
          web_page: values.pagina_web || "",
          has_social_media: Boolean(values.has_solcial_media),
          linkedin: values.linkin || "",
          facebook: values.face || "",
          instagram: values.insta || "",
          x_twitter: values.x_twttiter || "",
        };

        try {
          if (idMedia !== "" && idMedia !== undefined) {
            await patchMedia({ client_details_id: String(person.id), data, social_media_id: idMedia });
          } else {
            await postSocialMedia({ client_details_id: String(person.id), data }).unwrap();

            await triggerPostNextStage({
              applicationId: application.id,
              statusCode: "CSPDI",
            }).unwrap();
          }

          navigate("/producto/creditosimple/datos_accionista");
        } catch (error: any) {
          if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!formik.values.has_web) {
      formik.setFieldValue("pagina_web", "");
    }
  }, [formik.values.has_web]);

  useEffect(() => {
    if (!formik.values.has_solcial_media) {
      formik.resetForm({
        values: {
          ...formik.values,
          linkin: "",
          face: "",
          insta: "",
          x_twttiter: "",
        },
      });
    }
  }, [formik.values.has_solcial_media]);

  useEffect(() => {
    if (person?.id) {
      getSolialMedia({ client_details_id: String(person?.id) });
    }
  }, [person?.id]);

  useEffect(() => {
    if (isSuccess && data) {
      setIdMedia(data?.data?.id);
      formik.setFieldValue("has_web", data?.data?.has_web_page);
      formik.setFieldValue("has_solcial_media", data?.data?.has_social_media);
      
      formik.setFieldValue("pagina_web", data?.data?.web_page || "");

      formik.setFieldValue("linkin", data?.data?.linkedin || "");
      formik.setFieldValue("face", data?.data?.facebook || "");
      formik.setFieldValue("insta", data?.data?.instagram || "");
      formik.setFieldValue("x_twttiter", data?.data?.x_twitter || "");
    }
  }, [isSuccess, data]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={1} mb={2}>
              <Typography align={"center"} fontWeight={500} color={"#002652"} fontSize={"22px"}>
                Presencia digital
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
              <ProgressBar progress={6} />
            </Container>

            <Box my={2}>
              <Typography align={"center"} fontWeight={300} color={"#002652"} fontSize={"14px"} paddingTop={"20px"}>
                Conocer la información de los canales digitales de tu negocio nos permite mejorar tu oferta
                personalizada
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box my={2}>
                  <FormControl>
                    <FormLabel id="tienes_web" sx={{ color: "#002652", fontSize: "14px", fontWeight: 300 }}>
                      ¿La empresa cuenta con página web?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="tienes_web"
                      name="has_web"
                      sx={{ marginTop: "1em" }}
                      value={formik.values.has_web.toString()}
                      onChange={(event) => formik.setFieldValue("has_web", event.target.value === "true")}
                    >
                      <FormControlLabel
                        value={true}
                        control={<CustomRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircleOutline />} />}
                        label="Si"
                        disabled={isLoading}
                      />

                      <FormControlLabel
                        value={false}
                        control={<CustomRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircleOutline />} />}
                        label="No"
                        disabled={isLoading}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {formik.values.has_web && (
                  <TextField
                    id="pagina_web"
                    name="pagina_web"
                    label="Página web"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.pagina_web && Boolean(formik.errors.pagina_web)}
                    helperText={formik.touched.pagina_web && formik.errors.pagina_web}
                    value={formik.values.pagina_web}
                    disabled={isLoading}
                  />
                )}

                <Box my={2}>
                  <FormControl>
                    <FormLabel id="tienes_redes" sx={{ color: "#002652", fontSize: "14px", fontWeight: 300 }}>
                      ¿La empresa cuenta con redes sociales?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="tienes_redes"
                      name="has_solcial_media"
                      sx={{ marginTop: "1em" }}
                      value={formik.values.has_solcial_media.toString()}
                      onChange={(event) => formik.setFieldValue("has_solcial_media", event.target.value === "true")}
                    >
                      <FormControlLabel
                        value={true}
                        control={<CustomRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircleOutline />} />}
                        label="Si"
                        disabled={isLoading}
                      />

                      <FormControlLabel
                        value={false}
                        control={<CustomRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircleOutline />} />}
                        label="No"
                        disabled={isLoading}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {formik.values.has_solcial_media && (
                  <>
                    <TextField
                      id="linkin"
                      name="linkin"
                      label="LinkedIn"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.linkin && Boolean(formik.errors.linkin)}
                      helperText={formik.touched.linkin && formik.errors.linkin}
                      value={formik.values.linkin}
                      disabled={isLoading}
                    />
                    <TextField
                      id="face"
                      name="face"
                      label="Facebook"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.face && Boolean(formik.errors.face)}
                      helperText={formik.touched.face && formik.errors.face}
                      value={formik.values.face}
                      disabled={isLoading}
                    />
                    <TextField
                      id="insta"
                      name="insta"
                      label="Instagram"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.insta && Boolean(formik.errors.insta)}
                      helperText={formik.touched.insta && formik.errors.insta}
                      value={formik.values.insta}
                      disabled={isLoading}
                    />
                    <TextField
                      id="x_twttiter"
                      name="x_twttiter"
                      label="X (Twitter)"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.x_twttiter && Boolean(formik.errors.x_twttiter)}
                      helperText={formik.touched.x_twttiter && formik.errors.x_twttiter}
                      value={formik.values.x_twttiter}
                      disabled={isLoading}
                    />
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Button type="submit" variant="default" disabled={!formik.isValid}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
