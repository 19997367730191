/* eslint-disable @typescript-eslint/no-inferrable-types */
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const capitalizeFirstLetter = (str: string) => {
  if (str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeSpecialCharacters = (value: string) => {
  return value.replace(/[^a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s']/g, "");
};

export const formatCurrency = (amount: number, currencySymbol = "$") => {

  // Formatear el número con separadores de miles y dos decimales
  const formattedAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  // Retornar el string formateado con el símbolo de la moneda
  return `${currencySymbol}${formattedAmount}`;
};

export const amountToText = (amount: number, currencySymbol = ""): string => {
  amount = parseFloat(amount.toFixed(2));

  if (amount < 1000) {
    return `${currencySymbol}${amount}`;
  } else if (amount >= 1000 && amount < 1_000_000) {
    const thousands = Math.floor(amount / 1000);
    return `${currencySymbol}${thousands} mil`;
  } else if (amount >= 1_000_000 && amount < 1_000_000_000) {
    const millions = Math.floor(amount / 1_000_000);
    const residual = amount % 1_000_000;
    const thousands = Math.floor(residual / 1000);

    let result = `${currencySymbol}${millions} ${millions === 1 ? "millón" : "millones"}`;
    if (thousands % 1000 !== 0) {
      result += ` ${thousands} mil`;
    }
    return result;
  } else if (amount >= 1_000_000_000) {
    const billions = Math.floor(amount / 1_000_000_000);
    return `${currencySymbol}${billions} mil millones`;
  }

  return `${currencySymbol}${amount}`;
};

export const splitPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length > 10) {
    const country_code = phoneNumber.slice(0, -10);
    const phone_number = phoneNumber.replace(/\D/g, "").slice(-10);
    return { country_code: country_code[0] === "+" ? country_code : `+${country_code}`, phone_number };
  } else {
    return { country_code: "", phone_number: phoneNumber };
  }
};

export const formatTime = (timeToFormat: number) => {
  const minutes = Math.floor(timeToFormat / 60);
  const seconds = timeToFormat % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < 10) {
    return phoneNumber;
  }

  const part1 = phoneNumber.substring(0, 2);
  const part2 = phoneNumber.substring(2, 6);
  const part3 = phoneNumber.substring(6, 10);

  return `${part1}-${part2}-${part3}`;
};

export const formatDate = (dateString: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const convertSex = (sexString: string) => {
  if (!sexString) return "";
  return sexString.toLowerCase() === "masculino" ? "H" : "M";
};

export const calculateAmountMonthly = (loanAmount: number, loanTerm: number) => {
  const monthlyRate = 0.025;
  const payment = (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -loanTerm));
  return Number(payment.toFixed(2));
};

export const obtenerFechaHoy = (): string => {
  const hoy = new Date();
  const dia = hoy.getDate();
  const mes = meses[hoy.getMonth()];
  const año = hoy.getFullYear();

  return `Hoy ${dia} de ${mes} de ${año}`;
};

// Formatear la fecha a formato largo, Ej: 01 de enero de 2021
export const formatDateToLargeDate = (str_date: string) => {
  return dayjs(str_date).format(`DD [de] MMMM [de] YYYY`);
};

export const getMimeTypeFromFilename = (filename: string): string => {
  const extension = filename.split(".")[1];

  return (
    {
      aac: "audio/aac",
      abw: "application/x-abiword",
      arc: "application/x-freearc",
      avi: "video/x-msvideo",
      azw: "application/vnd.amazon.ebook",
      bin: "application/octet-stream",
      bmp: "image/bmp",
      bz: "application/x-bzip",
      bz2: "application/x-bzip2",
      cda: "application/x-cdf",
      csh: "application/x-csh",
      css: "text/css",
      csv: "text/csv",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      eot: "application/vnd.ms-fontobject",
      epub: "application/epub+zip",
      gz: "application/gzip",
      gif: "image/gif",
      htm: "text/html",
      html: "text/html",
      ico: "image/vnd.microsoft.icon",
      ics: "text/calendar",
      jar: "application/java-archive",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      js: "text/javascript",
      json: "application/json",
      jsonld: "application/ld+json",
      mid: "audio/midi audio/x-midi",
      midi: "audio/midi audio/x-midi",
      mjs: "text/javascript",
      mp3: "audio/mpeg",
      mp4: "video/mp4",
      mpeg: "video/mpeg",
      mpkg: "application/vnd.apple.installer+xml",
      odp: "application/vnd.oasis.opendocument.presentation",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      odt: "application/vnd.oasis.opendocument.text",
      oga: "audio/ogg",
      ogv: "video/ogg",
      ogx: "application/ogg",
      opus: "audio/opus",
      otf: "font/otf",
      png: "image/png",
      pdf: "application/pdf",
      php: "application/x-httpd-php",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      rar: "application/vnd.rar",
      rtf: "application/rtf",
      sh: "application/x-sh",
      svg: "image/svg+xml",
      swf: "application/x-shockwave-flash",
      tar: "application/x-tar",
      tif: "image/tiff",
      tiff: "image/tiff",
      ts: "video/mp2t",
      ttf: "font/ttf",
      txt: "text/plain",
      vsd: "application/vnd.visio",
      wav: "audio/wav",
      weba: "audio/webm",
      webm: "video/webm",
      webp: "image/webp",
      woff: "font/woff",
      woff2: "font/woff2",
      xhtml: "application/xhtml+xml",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      xml: "application/xml",
      xul: "application/vnd.mozilla.xul+xml",
      zip: "application/zip",
      "3gp": "video/3gpp",
      "3g2": "video/3gpp2",
      "7z": "application/x-7z-compressed",
    }[extension] || "application/octet-stream"
  );
};

export const forceDownload = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getMonthName = (monthNumber: number) => {
  return meses[monthNumber - 1] || "";
};

export const transformTitleCase = (value: string) => {
  const capital = value
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  .join(' ');
  const noSpecialCharacters = removeSpecialCharacters(capital);
  return noSpecialCharacters;
};
