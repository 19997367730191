/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  TextField,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { signIn } from "aws-amplify/auth";
import { RootState, useAppDispatch } from "@store/store";
import {
  set_Id_applicationCondition,
  setAccountUserId,
  setClientDetailsId,
  setId_application,
  setLoanId,
  setPersonalData,
} from "@store/slices/registerSlice";
import { useRegisterUserMutation, useRequestPhoneCodeMutation } from "@api/onboarding";
import { useLazyGetPersonTypeQuery } from "@api/catalogs";
import { ProgressBar } from "@components/ProgressBar";
import { TerminosCondiciones } from "@components/TerminosCondiciones";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { transformTitleCase } from "@helpers/funciones";
import { CatalogPersonType, PayloadRegisterUser, PayloadRequestPhoneCode } from "@interfaces/index";
import { NAME, LAST_NAMES, PHONE_NUMBER } from "@utils/expreciones";
import img from "@assets/img/right_3.svg";
import "react-phone-input-2/lib/style.css";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { useChangePhoneMutation, useLazyGetCuentasByIdQuery } from "@api/account";
import { UUID } from "crypto";

interface FormInitialValues {
  name: string;
  first_lastname: string;
  second_lastname: string;
  country_code: string;
  phone: string;
  person_type_id: string;
  rol_detail: string;
}

export const RegistroDatosPersonales = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const dispatch = useAppDispatch();
  const [registerUser] = useRegisterUserMutation();
  const [requestPhoneCode] = useRequestPhoneCodeMutation();
  const [getPersonTypesTrigger] = useLazyGetPersonTypeQuery();
  const [isLoading, setIsloading] = React.useState(false);
  const [personTypeCatalog, setPersonTypeCatalog] = React.useState<CatalogPersonType[]>([]);
  const [otherPersonTypeId, setOtherPersonTypeId] = React.useState<string | null>(null);
  const [personalId, setPersonalId] = React.useState("");
  const [getAccount, { data, isSuccess }] = useLazyGetCuentasByIdQuery();
  const [patchUserPhone] = useChangePhoneMutation();

  const initialValues: FormInitialValues = {
    name: personalData.name || "",
    first_lastname: personalData.first_lastname || "",
    second_lastname: personalData.second_lastname || "",
    country_code: personalData.country_code || "+52",
    phone: personalData.phone || "",
    person_type_id: personalData.person_type_id || "",
    rol_detail: personalData.rol_detail || "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Campo requerido").matches(new RegExp(NAME), "Nombre inválido, revisa el formato"),
    first_lastname: yup
      .string()
      .required("Campo requerido")
      .matches(new RegExp(LAST_NAMES), "Apellido paterno inválido, revisa el formato"),
    second_lastname: yup.string().matches(new RegExp(LAST_NAMES), "Apellido materno inválido, revisa el formato"),
    country_code: yup.string().required("Campo requerido"),
    phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
    person_type_id: yup.string().required("Campo requerido"),
    rol_detail: yup.string().when("person_type_id", {
      is: otherPersonTypeId,
      then: (schema) => schema.required("Campo requerido"),
    }),
  });

  const handleSubmit = async (values: FormInitialValues) => {
    try {
      setIsloading(true);

      if (personalId !== "" && personalId !== undefined) {
        const patchData = {
          id: personalId,
          cell_phone_number: values.phone,
          country_code: values.country_code,
          name: values.name,
          last_name: values.first_lastname,
          last_name_2: values.second_lastname,
          person_type_id: values.person_type_id,
        };
        await patchUserPhone(patchData).unwrap();
        dispatch(
          setPersonalData({
            id: personalId as UUID,
            name: values.name,
            first_lastname: values.first_lastname,
            second_lastname: values.second_lastname,
            country_code: values.country_code,
            phone: values.phone,
            person_type_id: values.person_type_id,
            rol_detail: values.rol_detail,
          }),
        );
      } else {
        const payloadRegisterUser: PayloadRegisterUser = {
          username: accountUser.email,
          email: accountUser.email,
          password: accountUser.password,
          phone: `${values.country_code}${values.phone}`,
          loan_application: {
            stage_id: import.meta.env.VITE_INITIAL_LOAN_STAGE_ID,
            // status_id: import.meta.env.VITE_INITIAL_LOAN_STATUS_ID,
            status_id: "1b3f968c-5de4-4c17-ac42-f185a73017a5",
            product_id: import.meta.env.VITE_PRODUCT_SIMPLE_CREDIT_ID,
            tax_systems_id: String(application.tax_system_id),
          },
          application_condition: {
            loan_amount: application.loan_amount,
            requested_term: application.loan_term,
          },
          client_details: {
            name: values.name,
            last_name: values.first_lastname,
            last_name_2: values.second_lastname,
            country_code: values.country_code,
            cell_phone_number: values.phone,
            person_type_id: values.person_type_id,
            tax_systems_id: String(application.tax_system_id),
          },
        };
        const responseRegisterUser = await registerUser(payloadRegisterUser).unwrap();

        await signIn({
          username: accountUser.email,
          password: accountUser.password,
        });

        dispatch(setAccountUserId(responseRegisterUser.data.id));
        dispatch(setLoanId(responseRegisterUser.data.loan_application.id));
        dispatch(setClientDetailsId(responseRegisterUser.data.client_details.id));
        dispatch(setId_application(Number(responseRegisterUser.data.loan_application.id_application)));
        dispatch(set_Id_applicationCondition(String(responseRegisterUser.data.application_condition.id)));
        dispatch(
          setPersonalData({
            id: responseRegisterUser.data.client_details.id,
            name: values.name,
            first_lastname: values.first_lastname,
            second_lastname: values.second_lastname,
            country_code: values.country_code,
            phone: values.phone,
            person_type_id: values.person_type_id,
            rol_detail: values.rol_detail,
          }),
        );
      }

      const payloadRequestPhoneCode: PayloadRequestPhoneCode = {
        country_code: values.country_code,
        phone_number: values.phone,
      };
      await requestPhoneCode(payloadRequestPhoneCode).unwrap();

      navigate("/producto/creditosimple/validacion_telefono");
    } catch (error: any) {
      if (error.status && error.data && error.data.code) {
        if (error.data.code === "002") {
          formik.setFieldError("phone", "El teléfono ya se encuentra registrado, intenta con otro número");
          return;
        }

        if (error?.status === 429) {
          formik.setFieldError(
            "phone",
            "Excedió el número de intentos permitidos para ese número, intenta más tarde o contacta a un asesor.",
          );
          return;
        }

        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
        }
      }
    } finally {
      setIsloading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsloading(true);
        // Check for required data before making the request
        if (!application.type || !accountUser.email) {
          // navigate("/producto/creditosimple");
          return;
        }

        // Fetch the person types
        const result = await getPersonTypesTrigger().unwrap();

        // Filter and sort the data
        const filteredData = result
          .filter(
            (item) =>
              (item.tax_systems_id === String(application.tax_system_id) || item.tax_systems_id === null) &&
              item.use_for_rol,
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        // Update the state with the filtered data
        setPersonTypeCatalog(filteredData);

        // Find and set the ID for "Otro" if it exists
        const otherItem = filteredData.find((item) => item.name.toLowerCase() === "otro");
        if (otherItem) {
          setOtherPersonTypeId(otherItem.id);
        }
      } catch (error) {
        console.error("Ha ocurrido un error al consultar el catalogo", error);
      } finally {
        setIsloading(false);
      }
    };

    fetchInitialData();
  }, [navigate, getPersonTypesTrigger, application, accountUser]);

  const getCuentasInfo = async (id: string) => {
    try {
      await getAccount({ id: id }).unwrap();
    } catch (error: any) {
      dispatch(setModalErrosVisible({ open: true, type: "500" }));
    }
  };

  useEffect(() => {
    const id = personalData?.id;

    if (!id || id === "00000000-0000-0000-0000-000000000000") {
      return;
    }

    getCuentasInfo(String(id));
  }, [personalData]);

  useEffect(() => {
    if (data) {
      setPersonalId(String(data?.data?.id));
    }
  }, [data, isSuccess]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 3 : 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
              Creación de cuenta
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
              <ProgressBar progress={1} />
            </Container>

            <Typography variant={isMobileOrTablet ? "body2" : "h3"} mt={isMobileOrTablet ? 2 : 8} textAlign="center">
              Ingresa los datos para crear tu cuenta
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 1 : 4, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    label="Nombre(s)"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("name", transformTitleCase(e.target.value))}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                    inputProps={{ maxLength: 20 }}
                    disabled={isLoading}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="first_lastname"
                    name="first_lastname"
                    label="Apellido paterno"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("first_lastname", transformTitleCase(e.target.value))}
                    value={formik.values.first_lastname}
                    error={formik.touched.first_lastname && Boolean(formik.errors.first_lastname)}
                    helperText={formik.touched.first_lastname ? formik.errors.first_lastname : ""}
                    inputProps={{ maxLength: 20 }}
                    disabled={isLoading}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="second_lastname"
                    name="second_lastname"
                    label="Apellido materno"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("second_lastname", transformTitleCase(e.target.value))}
                    inputProps={{ maxLength: 20 }}
                    value={formik.values.second_lastname}
                    disabled={isLoading}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="person_type_id-label" required>
                    Rol en la empresa
                  </InputLabel>
                  <Select
                    labelId="person_type_id-label"
                    id="person_type_id"
                    name="person_type_id"
                    fullWidth
                    required
                    label="Rol en la empresa*"
                    value={formik.values.person_type_id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.person_type_id && Boolean(formik.errors.person_type_id)}
                    disabled={isLoading}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {personTypeCatalog.length > 0 ? (
                      personTypeCatalog.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""} disabled>
                        No hay opciones disponibles
                      </MenuItem>
                    )}
                  </Select>
                  {Boolean(formik.touched.person_type_id) && Boolean(formik.errors.person_type_id) && (
                    <FormHelperText>{formik.errors.person_type_id}</FormHelperText>
                  )}
                </FormControl>

                {formik.values.person_type_id === otherPersonTypeId && (
                  <FormControl fullWidth>
                    <TextField
                      id="rol_detail"
                      name="rol_detail"
                      label="Especifica otro"
                      margin="normal"
                      fullWidth
                      required={formik.values.person_type_id === otherPersonTypeId}
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.rol_detail}
                      disabled={isLoading}
                    />
                  </FormControl>
                )}

                <Grid container spacing={1} mt={1}>
                  <Grid item xs={5} sm={5} md={5}>
                    <CustomContryCode
                      label="Código"
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                      disabled={isLoading}
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={7} sm={7} md={7}>
                    <TextField
                      type="text"
                      name="phone"
                      variant="outlined"
                      label="Teléfono"
                      fullWidth
                      value={formik.values.phone}
                      required
                      onChange={(event) => {
                        formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      inputProps={{
                        maxLength: 10,
                      }}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>

                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="default"
                    type="submit"
                    color="primary"
                    disabled={!(formik.isValid && (formik.dirty || !formik.dirty)) || isLoading}
                  >
                    Continuar
                  </Button>
                </Box>
              </form>

              <Box sx={{ mt: 3, mb: 2 }}>
                <TerminosCondiciones />
              </Box>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
