/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "@store/store";
import { setShareHolderRfcCurp } from "@store/slices/registerSlice";

import { useLazyGetCurpQuery } from "@api/onboarding";
import { usePatchPersonDataMutation } from "@api/personasApi";
import { usePostValidateNextStageMutation } from "@api/applications";

import { ProgressBar } from "@components/ProgressBar";
import { convertSex } from "@helpers/index";
import { CURP, RFC_PFAE_SIN_CLAVE } from "@utils/expreciones";
import { CurpQuery, CurpResponse, PayloadPersonPatch } from "@interfaces/index";

import img from "@assets/img/right_8.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

interface FormValues {
  rfc: string;
  homoclave: string;
  curp: string;
  confirmCheck: boolean;
}

export const RfcCurpForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const application = useSelector((state: RootState) => state.register.application);
  const shareholder = useSelector((state: RootState) => state.register.shareholder);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues] = useState<FormValues>({
    rfc: shareholder.rfc,
    homoclave: shareholder.rfc_homoclave,
    curp: shareholder.curp,
    confirmCheck: false,
  });

  const [getCurp] = useLazyGetCurpQuery();
  const [patchPersonData] = usePatchPersonDataMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const handlePatchPerson = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const data: PayloadPersonPatch = {
        id: shareholder.id,
        rfc: values.rfc + values.homoclave,
        curp: values.curp,
      };

      await patchPersonData(data).unwrap();

      dispatch(
        setShareHolderRfcCurp({
          rfc: values.rfc,
          rfc_homoclave: values.homoclave,
          curp: values.curp,
        }),
      );

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSCDA",
      }).unwrap();

      navigate("/producto/creditosimple/domicilio_accionista");
    } catch (error: any) {
      console.log("Hubo un error al actualizar los datos de la persona: ", error);
      if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      rfc: yup.string().required("Campo requerido").matches(RFC_PFAE_SIN_CLAVE, "Formato de RFC no válido"),
      homoclave: yup.string().required("Campo requerido"),
      curp: yup.string().required("Campo requerido").matches(CURP, "Formato de CURP no válido"),
      confirmCheck: yup.boolean().isTrue().required(""),
    }),
    onSubmit: handlePatchPerson,
  });

  const extractRFC = async (rfc: string) => {
    if (!rfc || typeof rfc !== "string" || rfc.length < 10) {
      return "";
    }

    return rfc.substring(0, 10);
  };

  // Ejemplo de uso

  useEffect(() => {
    const fetchCurp = async () => {
      try {
        setIsLoading(true);
        const birthStateNumber = parseInt(shareholder.birth_state, 10);

        const birthStateFormatted =
          birthStateNumber >= 1 && birthStateNumber <= 9 ? `0${birthStateNumber}` : shareholder.birth_state;

        const payloadGetCurp: CurpQuery = {
          nombres: shareholder.name,
          apellido_paterno: shareholder.first_lastname,
          apellido_materno: shareholder.second_lastname,
          estado_nacimiento: birthStateFormatted,
          fecha_nacimiento: dayjs(shareholder.date_birth, "YYYY-MM-DD").format("DD-MM-YYYY"),
          sexo: convertSex(shareholder.sex),
        };
        const responseGetCurp: CurpResponse = await getCurp(payloadGetCurp).unwrap();
        const rfc = await extractRFC(responseGetCurp.data.curp);
        formik.setFieldValue("curp", responseGetCurp.data.curp);
        formik.setFieldValue("rfc", rfc);
      } catch (error) {
        console.log("Hubo un error al obtener la CURP: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurp();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h4" fontWeight={"500"} align="center" sx={{ mt: 4, mb: 3 }}>
              Registro del accionista mayoritario
            </Typography>
            <ProgressBar progress={8} />
          </Container>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container columnSpacing={2}>
                <Grid item xs={8}>
                  <TextField
                    id="rfc"
                    name="rfc"
                    label="RFC accionista"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => {
                      const input = e.target;
                      const value = e.target.value.toUpperCase();
                      const start = input.selectionStart;
                      formik.handleChange(e);
                      formik.setFieldValue("rfc", value);
                      setTimeout(() => {
                        input.setSelectionRange(start, start);
                      }, 0);
                    }}
                    inputProps={{ maxLength: 10 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.rfc}
                    error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                    helperText={formik.touched.rfc && formik.errors.rfc}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="homoclave"
                    name="homoclave"
                    label="Homoclave"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => {
                      const input = e.target;
                      const value = e.target.value.toUpperCase();
                      const start = input.selectionStart;
                      formik.handleChange(e);
                      formik.setFieldValue("homoclave", value);
                      setTimeout(() => {
                        input.setSelectionRange(start, start);
                      }, 0);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.homoclave}
                    error={formik.touched.homoclave && Boolean(formik.errors.homoclave)}
                    helperText={formik.touched.homoclave && formik.errors.homoclave}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="curp"
                    name="curp"
                    label="CURP"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => {
                      const input = e.target;
                      const value = e.target.value.toUpperCase();
                      const start = input.selectionStart;
                      formik.handleChange(e);
                      formik.setFieldValue("curp", value);
                      setTimeout(() => {
                        input.setSelectionRange(start, start);
                      }, 0);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.curp}
                    error={formik.touched.curp && Boolean(formik.errors.curp)}
                    helperText={formik.touched.curp && formik.errors.curp}
                    inputProps={{ maxLength: 18 }}
                  />
                </Grid>
                <Grid item xs={12} py={2} mx={1}>
                  <FormControlLabel
                    control={<Checkbox id="confirmCheck" name="confirmCheck" onChange={formik.handleChange} />}
                    label="Confirmo que el RFC y CURP son correctos"
                  />
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"center"} mt={3} mb={3}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.dirty || !formik.isValid || isLoading}
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
