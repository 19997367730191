/* eslint-disable @typescript-eslint/no-explicit-any */
import { baseApi } from "./baseApi";

interface ClientsSuppliersResponse {
  code: string;
  message: string;
  errors: any[];
  data: ClientsSuppliersResponseData;
}

interface ClientsSuppliersResponseData {
  rfc: string;
  clients: {
    name: string;
    rfc: string;
    total: number;
    share: number;
    id: string;
  }[];
  suppliers: {
    name: string;
    rfc: string;
    total: number;
    share: number;
    id: string;
  }[]; 
  created_at: string;
  updated_at: string;
  id: string;
}

const proveedoresClientes = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientsSuppliersByRfc: build.query<ClientsSuppliersResponse, string>({
      query: (rfc) => ({
        url: `sat_core/clients_and_suppliers/${rfc}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetClientsSuppliersByRfcQuery } = proveedoresClientes;
