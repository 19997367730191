/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UUID } from "crypto";
import * as yup from "yup";
import { FormikErrors } from "formik";
import { useGetDocsConfigQuery } from "@api/catalogs";
import { DocumentConfig, UploadedFile } from "@interfaces/index";
import {
  useLazyGetSignUrlToUploadQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  useUploadFileMutation,
} from "@api/documents";
import { useAppDispatch } from "@store/store";
import { setUploadModalErrorVisible } from "@store/slices/appSlice";

import Done from "@assets/img/done.svg";

interface CustomUploadFileProps extends BoxProps {
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  registerFormik?: (name: string, validationSchema: yup.Schema) => void;
  updateStore: (action: "ADD" | "DEL", index: number, uploadedFile: UploadedFile | null, fileId: UUID) => void;
  errors?: FormikErrors<unknown>;
  uploadedFile?: UploadedFile;
  optionalName?: string;
  optionalDescription?: string;
  required?: boolean;
  parentIndex?: number;
  index?: number;
  metadata: {
    fileCode: string;
    taxCode: string;
    uploadType: "client" | "person";
    loan_application_id: string;
    regimen: string;
    clientDetailsId?: UUID;
    personType?: string;
    personCode?: string;
    person_id?: UUID;
  };
}

export const CustomUploadFile = ({
  setFieldValue,
  registerFormik,
  updateStore,
  errors,
  index,
  parentIndex,
  uploadedFile,
  optionalName,
  optionalDescription,
  required,
  metadata,
  ...props
}: CustomUploadFileProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const [fileInternalId, setFileInternalId] = useState<string>(metadata.fileCode.concat("_", crypto.randomUUID()));
  const [fileCategory, setFileCategory] = useState<DocumentConfig>();
  const [isUploadedFile, setIsUploadedFile] = useState<boolean>(true);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>("");
  const [uploadedFileId, setUploadedFileId] = useState<UUID>();
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean | null>(uploadedFile ? uploadedFile.validated : false);

  const documentConfig = useGetDocsConfigQuery({ taxCode: metadata.taxCode, personCode: metadata.personCode });

  const [loading, setLoading] = useState(false);

  const [triggerUploadFile] = useUploadFileMutation();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerGetSignUrl] = useLazyGetSignUrlToUploadQuery();

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.item(0);
    const fileName: string = file ? file.name : "";
    try {
      if (file && fileCategory) {
        setLoading(true);

        const signUrlResponse = await triggerGetSignUrl({
          upload_type: metadata.uploadType,
          file_name: file.name,
          regimen: metadata.regimen === import.meta.env.VITE_CODE_TAXS_CS_PM ? "pm" : "pfae",
          client_details_id: metadata.clientDetailsId ? metadata.clientDetailsId : undefined,
          person_id: metadata.person_id ? metadata.person_id : undefined,
          file_category_id: fileCategory.file_category_id,
          person_type: metadata.personType ? metadata.personType : undefined,
          loan_application_id: metadata.loan_application_id,
        }).unwrap();

        const tmp = new FormData();

        tmp.append("key", signUrlResponse.fields.key);
        tmp.append("AWSAccessKeyId", signUrlResponse.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrlResponse.fields["x-amz-security-token"]);
        tmp.append("policy", signUrlResponse.fields.policy);
        tmp.append("signature", signUrlResponse.fields.signature);
        tmp.append("file", file);

        await triggerUploadFile({
          url: signUrlResponse.url,
          body: tmp,
        }).unwrap();

        const documentResponse = await triggerPostDocument({
          loan_application_id: metadata.loan_application_id,
          file_category_id: fileCategory.file_category_id,
          client_details_id: metadata.uploadType === "client" ? metadata.clientDetailsId : undefined,
          person_id: metadata.uploadType === "person" ? metadata.person_id : undefined,
          url: signUrlResponse.fields.key,
          file_name: fileName,
        }).unwrap();

        setUploadedFileName(fileName);
        setIsUploadedFile(false);
        setUploadedFileId(documentResponse.id);

        setFieldValue?.(fileInternalId, true);

        updateStore(
          "ADD",
          index ? index : 0,
          {
            id: documentResponse.id,
            file_category_id: documentResponse.file_category_id,
            url: documentResponse.url,
            status: documentResponse.status,
            validated: null,
            created_at: documentResponse.created_at,
            updated_at: documentResponse.updated_at,
            file_code: metadata.fileCode,
            file_name: documentResponse.file_name,
          },
          documentResponse.id,
        );
      }

      setValidated(null);
      dispatch(setUploadModalErrorVisible({ file_name: "", visible: false }));
    } catch (error) {
      dispatch(setUploadModalErrorVisible({ file_name: fileName ? fileName : "", visible: true }));
    } finally {
      event.target.value = "";
      setLoading(false);
    }
  };

  const handleOnClickDelete = async () => {
    try {
      setLoading(true);
      await triggerPatchDocument({
        documentId: uploadedFile ? uploadedFile.id : uploadedFileId,
        body: { status: false },
      }).unwrap();

      const fileToDelete = uploadedFile ? uploadedFile.id : uploadedFileId || null;
      if (fileToDelete) {
        updateStore("DEL", index ? index : 0, null, fileToDelete);
      }
      setIsUploadedFile(true);
      setFieldValue?.(fileInternalId, false);
      setLoading(false);
    } catch (error) {
      console.log("Ha ocurrido un error");
    }
  };

  const handleOnReuploaded = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setLoading(true);
    await triggerPatchDocument({
      documentId: uploadedFile ? uploadedFile.id : uploadedFileId,
      body: { status: false },
    }).unwrap();
    const fileToDelete = uploadedFile ? uploadedFile.id : uploadedFileId || null;
    if (fileToDelete) {
      updateStore("DEL", index ? index : 0, null, fileToDelete);
    }

    await handleOnChange(event);
    setLoading(false);
  };

  useEffect(() => {
    if (documentConfig.isSuccess) {
      const fileCategory = documentConfig.data.find((file) => file.file_category.code === metadata.fileCode);

      setFileCategory(fileCategory);

      if (uploadedFile?.id !== undefined && uploadedFile?.validated !== false) {
        setFileInternalId(metadata.fileCode.concat("_", uploadedFile.id));
        setIsUploadedFile(false);
        setUploadedFileName(uploadedFile.file_name);
      }

      if (fileCategory?.optional == false) {
        registerFormik?.(
          uploadedFile?.id !== undefined ? metadata.fileCode.concat("_", uploadedFile.id) : fileInternalId,
          yup.boolean().isTrue("Este documento es requerido").required("Este documento es requerido"),
        );

        if (uploadedFile?.id !== undefined) {
          setFieldValue?.(
            uploadedFile.id !== undefined ? metadata.fileCode.concat("_", uploadedFile.id) : fileInternalId,
            uploadedFile.id !== undefined,
          );
        }
      }
    }
  }, [documentConfig]);

  useEffect(() => {
    setIsRequired(Object.keys(errors ? errors : {}).includes(fileInternalId));
  }, [errors]);

  return (
    <Box {...props}>
      <Grid container pb={2}>
        <Grid item xs={8} pr={2}>
          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            color={isRequired ? "#CD0202" : "#002652"}
            textAlign={"left"}
          >
            {documentConfig.isFetching ? (
              <Skeleton variant="text" animation={"wave"} />
            ) : (
              <>
                {optionalName ? optionalName : fileCategory?.file_category.name}{" "}
                {fileCategory?.optional == false && (
                  <Typography component={"span"} color={"#CD0202"}>
                    *
                  </Typography>
                )}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} hidden={!isUploadedFile}>
          <label htmlFor={`upload-file_${fileInternalId}`}>
            <input
              type="file"
              id={`upload-file_${fileInternalId}`}
              name={`upload-file_${fileInternalId}`}
              accept="application/pdf,image/jpeg,image/png"
              hidden
              onChange={uploadedFile?.validated === false ? handleOnReuploaded : handleOnChange}
            />

            {uploadedFile?.validated === false ? (
              <Button variant="orange" component={"span"} fullWidth sx={{ padding: "4px 5px" }}>
                Volver a cargar
              </Button>
            ) : (
              <Button variant="default" component={"span"} fullWidth sx={{ padding: "1px 25px" }} disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white", paddingTop: "5px", paddingBottom: "5px" }} />
                ) : (
                  "Cargar"
                )}
              </Button>
            )}
          </label>
        </Grid>
        <Grid item xs={12} textAlign={"left"} hidden={isUploadedFile}>
          <Grid container>
            <Grid item xs={4} mr={1}>
              <Typography
                component={"span"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={"#00A024"}
                fontWeight={400}
              >
                Archivo cargado:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                noWrap
                display={"inline-block"}
                maxWidth={"100%"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={"#002652"}
                fontWeight={400}
              >
                {uploadedFileName}
              </Typography>
            </Grid>
            <Grid item>
              <img src={Done} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} textAlign={"left"} pr={4}>
          <Typography
            variant={isMobileOrTablet ? "body3" : "body2"}
            fontWeight={400}
            color={isRequired ? "#CD0202" : "#002652"}
          >
            {documentConfig.isFetching ? (
              <Skeleton variant="text" animation={"wave"} />
            ) : optionalDescription ? (
              optionalDescription
            ) : (
              fileCategory?.file_category.description
            )}
          </Typography>
        </Grid>
        <Grid item xs={5} hidden={isUploadedFile}>
          {validated == null && (
            <Button variant="blue_demon" fullWidth sx={{ padding: "1px 25px" }} onClick={handleOnClickDelete}>
              Eliminar
            </Button>
          )}
          {uploadedFile?.validated && (
            <Button variant="validated" fullWidth sx={{ padding: "1px 25px" }}>
              Validado
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: "#A3D4E8" }} />
    </Box>
  );
};
