/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StyledSlider } from "./CustomSlider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { amountToText, calculateAmountMonthly, formatCurrency } from "@helpers/funciones";
import { useGetTaxSystemsQuery } from "@api/catalogs";
import { useGetCondicionesBySolicitudIdQuery } from "@api/conditions";

interface QuotationProps {
  isShowed?: boolean;
  onUpdateValues: (amount: number, term: number, monthlyRate: number) => void;
}

export const Quotation = (props: QuotationProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const loanApplication = useSelector((state: RootState) => state.register.application);

  const resultTaxSystems = useGetTaxSystemsQuery(loanApplication.type);

  const [loanAmount, setLoanAmountLocal] = useState<number>(0);
  const [loanTerm, setLoanTermLocal] = useState<number>(0);
  const [monthlyRate, setMonthlyRateLocal] = useState<number>(0);

  const [loanAmountAproved, setLoanAmountAproved] = useState<number>(0);
  const [loanTermAproved, setLoanTermAproved] = useState<number>(0);
  const { data: applicationData, isSuccess } = useGetCondicionesBySolicitudIdQuery(loanApplication.id);

  const calculate = () => {
    const value = calculateAmountMonthly(loanAmount, loanTerm);
    props.onUpdateValues(loanAmount, loanTerm, value);
    setMonthlyRateLocal(value);
  };

  const handleAmountChange = (_: any, newValue: number | number[]) => {
    setLoanAmountLocal(newValue as number);
  };

  const handleTermChange = (_: any, newValue: number | number[]) => {
    setLoanTermLocal(newValue as number);
  };

  useEffect(() => {
    calculate();
  }, [loanAmount, loanTerm]);

  useEffect(() => {
    if (applicationData?.data) {
      const activeData = applicationData.data.find((item) => item.active);

      if (activeData) {
        const { approved_amount, approved_term } = activeData;
        const maxAmount = Number(resultTaxSystems?.data?.max_amount);
        const maxTerm = Number(resultTaxSystems?.data?.max_term);
        setLoanAmountLocal(approved_amount);
        setLoanTermLocal(approved_term);
        setLoanAmountAproved(approved_amount === 0 ? maxAmount : Number(approved_amount));
        setLoanTermAproved(approved_term === 0 ? maxTerm : Number(approved_term));
      }
    }
  }, [applicationData, isSuccess, resultTaxSystems]);

  return (
    <Box textAlign={"center"} width={"100%"}>
      <Container sx={{ mt: 2, mb: 2 }}>
        <Typography fontWeight={500} variant={isMobileOrTablet ? "body2" : "body1"} mt={2}>
          Selecciona el monto
        </Typography>
        <Typography textAlign={"center"} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
          {amountToText(loanAmount)} MXN
        </Typography>
        {props.isShowed && resultTaxSystems.isSuccess && isSuccess ? (
          <>
            <StyledSlider
              value={loanAmount}
              aria-label="amount"
              min={resultTaxSystems.data.min_amount}
              max={loanAmountAproved}
              step={50000}
              onChange={handleAmountChange}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"#002652"} fontSize={"13px"}>
                {amountToText(resultTaxSystems.data.min_amount, "$")}
              </Typography>
              <Typography color={"#002652"} fontSize={"13px"}>
                {amountToText(loanAmountAproved, "$")}
              </Typography>
            </Box>
          </>
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}

        <Typography fontWeight={500} variant={isMobileOrTablet ? "body2" : "body1"} mt={4}>
          Selecciona el plazo en meses
        </Typography>
        {props.isShowed && resultTaxSystems.isSuccess && isSuccess ? (
          <StyledSlider
            aria-label="term"
            value={loanTerm}
            min={resultTaxSystems.data.min_term}
            max={loanTermAproved}
            step={3}
            marks={[
              { value: 6, label: "6" },
              { value: 9, label: "9" },
              { value: 12, label: "12" },
              { value: 15, label: "15" },
              { value: 18, label: "18" },
              { value: 21, label: "21" },
              { value: 24, label: "24" },
            ]}
            onChange={handleTermChange}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}
      </Container>

      <Typography
        textAlign={"center"}
        color={"#528CD6"}
        fontWeight={700}
        variant={isMobileOrTablet ? "body2" : "body1"}
      >
        Pago mensual estimado
      </Typography>
      <Typography mb={3} textAlign={"center"} variant="h4" fontWeight={600}>
        {isNaN(monthlyRate) || !isFinite(monthlyRate) ? "0" : formatCurrency(monthlyRate)}{" "}
        <Typography variant="h5" component={"span"} fontWeight={600}>
          MXN
        </Typography>
      </Typography>

      <Typography textAlign={"center"} color={"#002652"} variant={isMobileOrTablet ? "body3" : "body2"}>
        *Mensualidad estimada
      </Typography>
    </Box>
  );
};
