import { Navigate, useLocation } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    // Define las rutas públicas
    const publicRoutes = [
        "/producto/creditosimple/crear_cuenta",
        "/producto/creditosimple/registro_datos_personales",
    ];

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const session = await fetchAuthSession();
                setIsAuthenticated(!!(session && session.tokens && session.tokens.accessToken));
            } catch {
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Cargando...</div>;
    }

    // Verifica si la ruta actual es pública
    const isPublicRoute = publicRoutes.some((route) => location.pathname.startsWith(route));

    if (isPublicRoute) {
        return children;
    }

    // Si no está autenticado y la ruta no es pública, redirige al login
    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // Si está autenticado, permite el acceso
    return children;
};
