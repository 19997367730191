/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, IconButton, Modal, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactNode, useEffect, useState } from "react";

import imgDictamen from "@assets/img/iconTap/dicatamen.svg";
import imgEmpresa from "@assets/img/iconTap/empresa.svg";
import imgAval from "@assets/img/iconTap/aval.svg";
import imgProp from "@assets/img/iconTap/propietario.svg";
import { useLazyGetDocumentAppIdQuery, useLazyGetDocumentPersonatIdQuery } from "@api/documents";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useGetDocsConfigQuery, useGetPersonTypeQuery } from "@api/catalogs";

import { useGetApplicationByApplicationIdQuery } from "@api/applications";
import { useLazyGetPersonByIdQuery, useLazyGetPersonByTypePersonIdQuery } from "@api/personasApi";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckIcon from "@mui/icons-material/Check";
import { PersonaStructure } from "@interfaces/index";
interface DocumentStatusResult {
  icon?: ReactNode;
  label?: string;
  color?: string;
}

const validao: DocumentStatusResult = {
  icon: <CheckIcon style={{ color: "#01A388", fontSize: "22px", margin: "0px" }} />,
  label: "Validado",
  color: "#01A388",
};

const cargado: DocumentStatusResult = {
  icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px" }} />,
  label: "Cargado",
  color: "#A3D4E8",
};

const faltantes: DocumentStatusResult = {
  icon: <WarningAmberIcon style={{ color: "#F2704F", fontSize: "22px", margin: "0px" }} />,
  label: "Faltantes",
  color: "#F2704F",
};

interface DocumentDetails {
  client_details_id: string | null;
  created_at: string;
  file_category_id: string;
  file_name: string;
  id: string;
  loan_application_id: string;
  person_id: string;
  sign_client: string | null;
  sign_user: string | null;
  sign_validated: string | null;
  status: boolean;
  updated_at: string;
  url: string;
  validated: string | null | boolean;
}

interface OwenerDosc {
  id_owener: string;
  file_anexo?: DocumentDetails | null;
  file_DIO?: DocumentDetails | null;
  file_DCDF?: DocumentDetails | null;
}

export const TabStepBofuPm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [textModal, setTextModal] = useState("");

  const [isActiveDicatamen, setIsActiveDictamen] = useState(false);
  const [isActiveEmpresa, setIsActiveEmpresa] = useState(false);
  const [isActiveAval, setIsActiveAval] = useState(false);
  const [isActivePropietario, setIsActivePropietario] = useState(false);

  const [dictamenComplete, setDictamenComplete] = useState<any>(false);
  const [dictamenValidado, setDictamenValidado] = useState<any>(false);

  const [dictamenValidadoRedirect, setDictamenValidadoRedirect] = useState("");

  const [dicatmenProps, setDicatmenProps] = useState<DocumentStatusResult>({
    icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px" }} />,
    label: "cargado",
    color: "",
  });

  const [allCompletedSolicitud, setAllCompletedSolicitud] = useState<any>(false);

  const [solicitudProps, setSolicitudProps] = useState<DocumentStatusResult>({
    icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px" }} />,
    label: "cargado",
    color: "",
  });

  const [allCompletedAval, setAllCompletedAval] = useState<any>(false);

  const [avalProps, setAvalProps] = useState<DocumentStatusResult>({
    icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px" }} />,
    label: "cargado",
    color: "",
  });

  const [allCompletedPropietario, setAllCompletedPropietario] = useState<any>(false);

  const [propietarioProps, setPropietarioProps] = useState<DocumentStatusResult>({
    icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px" }} />,
    label: "cargado",
    color: "",
  });

  const applicationData = useSelector((state: RootState) => state.register.application);
  const client = useSelector((state: RootState) => state.register.personal_data);
  const aval = useSelector((state: RootState) => state.register.shareholder);
  const ownersSection = useSelector((state: RootState) => state.bofu.digital_file.actual_owner);

  const [getDocumentByAppId, { data: dataDocuments, isSuccess: isSuccessDocumentApp }] = useLazyGetDocumentAppIdQuery();

  const { data: documentConfig, isSuccess: isSuccessConfigDoc } = useGetDocsConfigQuery({
    taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
    personCode: "",
  });

  const { data: appData, isSuccess: isSuccessApp } = useGetApplicationByApplicationIdQuery(applicationData.id);

  const [getPersona, { data: dataPerson, isSuccess: isSuccessAval }] = useLazyGetPersonByIdQuery();

  const [getDocumentByPersonaId, { data: dataDocumentsAval, isSuccess: isSuccessDocAval }] =
    useLazyGetDocumentPersonatIdQuery();

  const [getDocumentByPersonaIdPropietario] = useLazyGetDocumentPersonatIdQuery();

  const [getPropietario, { data: dataPropietario, isSuccess: isSuccessPropietario }] =
    useLazyGetPersonByTypePersonIdQuery();

  const { data: personTypeData, isSuccess: typePersonSucess } = useGetPersonTypeQuery();

  const [personTypeId, setPersonTypeId] = useState("");

  const [hasPropietario, setHasPropietario] = useState(false);

  const [isSuccessListPropiestarios, setIsSuccessListPropiestarios] = useState(false);

  const [listPropietarios, setPropietarios] = useState<PersonaStructure[]>([]);

  const handleStepClick = (path: string) => {
    navigate(path);
  };

  const activeTab = (path: string) => {
    switch (path) {
      case "/producto/creditosimple/docs_empresa":
        setIsActiveDictamen(true);
        break;
      case "/producto/creditosimple/avance_expediente_digital":
        setIsActiveEmpresa(true);
        break;
      case "/producto/creditosimple/docs_aval":
        setIsActiveAval(true);
        break;
      case "/producto/creditosimple/propietario_real_pm":
        setIsActivePropietario(true);
        break;
    }
  };

  useEffect(() => {
    activeTab(location.pathname);
  }, [location]);

  useEffect(() => {
    getDocumentByAppId({ client_details_id: client.id, loanApplicationId: applicationData.id });
  }, [client, applicationData]);

  useEffect(() => {
    if (aval.id) {
      getPersona(aval.id);
    }
  }, [aval.id]);

  useEffect(() => {
    if (aval.id) {
      getDocumentByPersonaId({ loanApplicationId: applicationData.id, personaId: aval.id });
    }
  }, [aval.id]);

  useEffect(() => {
    if (dataDocuments && documentConfig && appData && isSuccessApp) {
      const DACItems = documentConfig.find((item) => item.file_category.code === "DAC");
      const DAAItems = documentConfig.find((item) => item.file_category.code === "DAA");
      const DIORLItems = documentConfig.find((item) => item.file_category.code === "DIORL");

      const DACIdataDocument = DACItems
        ? dataDocuments.find((item) => item.file_category_id === DACItems.file_category_id && item.status === true)
        : null;
      const DIORLItemsDocument = DIORLItems
        ? dataDocuments.find((item) => item.file_category_id === DIORLItems.file_category_id && item.status === true)
        : null;
      const DAAItemsDocuments = DAAItems
        ? dataDocuments.filter((item) => item.file_category_id === DAAItems.file_category_id && item.status === true)
        : [];

      if ((DACItems && !DACIdataDocument) || (DIORLItems && !DIORLItemsDocument)) {
        return;
      }

      const valiadDicatamen = `${appData?.data[0]?.decision_validated}`;
      setDictamenValidadoRedirect(`${appData?.data[0]?.decision_validated}`);

      if (valiadDicatamen === "true") {
        setDictamenComplete(true);
        setDictamenValidado(true);
        setDicatmenProps({
          icon: <CheckIcon style={{ color: "#01A388", fontSize: "22px", margin: "0px" }} />,
          label: "Validado",
          color: "#01A388",
        });
      } else if (valiadDicatamen === "null") {
        if (DAAItemsDocuments.length > 0) {
          if (DACIdataDocument && DIORLItemsDocument) {
            setDictamenComplete(true);
            setDicatmenProps({
              icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px" }} />,
              label: "Cargado",
              color: "#A3D4E8",
            });
          }
        } else {
          if (DACIdataDocument && DIORLItemsDocument) {
            setDictamenComplete(true);
            setDicatmenProps({
              icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px" }} />,
              label: "Cargado",
              color: "#A3D4E8",
            });
          }
        }
      } else if (valiadDicatamen === "false") {
        setDictamenComplete(true);
        setDictamenValidado(false);
        setDicatmenProps({
          icon: <WarningAmberIcon style={{ color: "#F2704F", fontSize: "22px", margin: "0px" }} />,
          label: "Faltantes",
          color: "#F2704F",
        });
      }
    }
  }, [dataDocuments, documentConfig, isSuccessDocumentApp, appData, isSuccessApp]);

  const redirectRoutes = [
    "/producto/creditosimple/docs_aval",
    "/producto/creditosimple/propietario_real_pm",
  ];
  
  useEffect(() => {
    if (
      redirectRoutes.includes(location.pathname) &&
      (dictamenValidadoRedirect === "null" || dictamenValidadoRedirect === "false")
    ) {
      navigate("/producto/creditosimple/docs_empresa");
    }
  }, [dictamenValidadoRedirect, location]);

  useEffect(() => {
    if (appData && dataDocuments && documentConfig && isSuccessConfigDoc) {
      const dosSol = dataDocuments.filter((file) => file.file_name.includes("solicitud_credito"));
      const validatedFile = dosSol.find((item) => item.status);

      const DCDItems = documentConfig?.find((item) => item.file_category.code === "DCD");
      const DCDdataDocuments = dataDocuments.find(
        (item) => item.file_category_id === DCDItems?.file_category_id && item.status === true,
      );

      const allDocsValid = [validatedFile, DCDdataDocuments].every((doc) => doc?.validated === true);
      const someInvalid = [validatedFile, DCDdataDocuments].some((doc) => doc?.validated === false);
      const allDocsNullOrTrue = [validatedFile, DCDdataDocuments].every(
        (doc) => doc?.validated === null || doc?.validated === true,
      );

      if (appData.data[0]?.completed) {
        setAllCompletedSolicitud(true);
        setSolicitudProps(validao);
      } else {
        if (validatedFile && DCDdataDocuments) {
          if (allDocsValid) {
            setAllCompletedSolicitud(true);
            setSolicitudProps(validao);
          } else if (someInvalid) {
            setAllCompletedSolicitud(true);
            setSolicitudProps(faltantes);
          } else if (allDocsNullOrTrue) {
            if (
              appData.data[0]?.identity_validation_completed &&
              appData.data[0]?.bank_information_completed &&
              appData.data[0]?.references_completed &&
              appData.data[0]?.loan_application_completed
            ) {
              setAllCompletedSolicitud(true);
              setSolicitudProps(cargado);
            }
          }
        }
      }
    }
  }, [appData, isSuccessApp, dataDocuments, documentConfig, isSuccessConfigDoc]);

  useEffect(() => {
    if (dataPerson && dataDocumentsAval && documentConfig) {
      const DIOItems = documentConfig?.find((item) => item.file_category.code === "DIO");
      const DIOdataDocuments = dataDocumentsAval.find(
        (item) => item.file_category_id === DIOItems?.file_category_id && item.status === true,
      );

      const DCDFItems = documentConfig?.find((item) => item.file_category.code === "DCDF");
      const DCDFdataDocuments = dataDocumentsAval.find(
        (item) => item.file_category_id === DCDFItems?.file_category_id && item.status === true,
      );

      const DCDItems = documentConfig?.find((item) => item.file_category.code === "DCD");
      const DCDdataDocuments = dataDocumentsAval.find(
        (item) => item.file_category_id === DCDItems?.file_category_id && item.status === true,
      );

      const filteredFiles = dataDocumentsAval.filter((file) => file.file_name.includes("anexo_1"));
      const validatedFile = filteredFiles.find((item) => item.status);

      const allDocsValid = [validatedFile, DIOdataDocuments, DCDdataDocuments, DCDFdataDocuments].every(
        (doc) => doc?.validated === true,
      );

      const someInvalid = [validatedFile, DIOdataDocuments, DCDdataDocuments, DCDFdataDocuments].some(
        (doc) => doc?.validated === false,
      );

      const allDocsNullOrTrue = [validatedFile, DIOdataDocuments, DCDdataDocuments, DCDFdataDocuments].every(
        (doc) => doc?.validated === null || doc?.validated === true,
      );

      if (dataPerson.data?.completed) {
        setAllCompletedAval(true);
        setAvalProps(validao);
      } else {
        if (validatedFile && DCDdataDocuments && DIOdataDocuments && DCDFdataDocuments) {
          if (allDocsValid) {
            setAllCompletedAval(true);
            setAvalProps(validao);
          } else if (someInvalid) {
            setAllCompletedAval(true);
            setAvalProps(faltantes);
          } else if (allDocsNullOrTrue) {
            if (
              dataPerson.data?.identity_validation_completed &&
              dataPerson.data?.bank_references_completed &&
              dataPerson.data?.information_person_completed
            ) {
              setAllCompletedAval(true);
              setAvalProps(cargado);
            }
          }
        }
      }
    }
  }, [dataPerson, dataDocumentsAval, isSuccessAval, isSuccessDocAval, documentConfig, isSuccessConfigDoc]);

  useEffect(() => {
    if (personTypeData) {
      const resultPropietario = personTypeData.find((item) => item.code === "PTPR");
      setPersonTypeId(String(resultPropietario?.id));
    }
  }, [personTypeData, typePersonSucess]);

  useEffect(() => {
    if (personTypeId && applicationData) {
      getPropietario({ applicationId: applicationData.id, personId: personTypeId });
    }
  }, [personTypeId, applicationData]);

  useEffect(() => {
    if (dataPropietario) {
      setPropietarios(dataPropietario?.data?.data);
    }
  }, [dataPropietario, isSuccessPropietario]);

  useEffect(() => {
    if (listPropietarios.length !== 0) {
      setHasPropietario(true);
    }
  }, [listPropietarios]);

  const [geneateDocumentByOwner, setGeneateDocumentByOwner] = useState<OwenerDosc[]>([]);

  useEffect(() => {
    const fetchDocumentsForOwners = async () => {
      if (listPropietarios && applicationData?.id && documentConfig) {
        if (listPropietarios.length === 0) {
          setIsSuccessListPropiestarios(true);
          return;
        }

        const DIOItems = documentConfig?.find((item) => item.file_category.code === "DIO");
        const DCDFItems = documentConfig?.find((item) => item.file_category.code === "DCDF");
        let allDocuments: DocumentDetails[] = [];
        const ownerDocuments: OwenerDosc[] = [];
        let successCount = 0;

        for (const owner of listPropietarios) {
          try {
            const result: any = await getDocumentByPersonaIdPropietario({
              personaId: owner.id,
              loanApplicationId: applicationData.id,
            }).unwrap();
            allDocuments = allDocuments.concat(result);

            successCount++;
          } catch (error) {
            console.error("Error fetching documents:", error);
          }

          const filteredDataHasData = allDocuments.find(
            (item) => item.file_name.includes("anexo_2") && item.status && item.person_id === owner.id,
          );

          const filteredDataHasDio = allDocuments.find(
            (item) =>
              item.file_category_id === String(DIOItems?.file_category_id) &&
              item.status &&
              item.person_id === owner.id,
          );

          const filteredDataHasDCDF = allDocuments.find(
            (item) =>
              item.file_category_id === String(DCDFItems?.file_category_id) &&
              item.status &&
              item.person_id === owner.id,
          );

          ownerDocuments.push({
            id_owener: String(owner.id),
            file_anexo: filteredDataHasData || null,
            file_DIO: filteredDataHasDio || null,
            file_DCDF: filteredDataHasDCDF || null,
          });
        }

        if (successCount === listPropietarios.length) {
          setIsSuccessListPropiestarios(true);
        }

        setGeneateDocumentByOwner(ownerDocuments);
      }
    };

    fetchDocumentsForOwners();
  }, [listPropietarios, applicationData?.id, getDocumentByPersonaId, documentConfig, isSuccessConfigDoc]);

  useEffect(() => {
    if (listPropietarios.length > 0) {
      const allOwnersStatusTrue = listPropietarios.every((owner) => owner.information_person_completed === true);

      const allHasDioDocs = geneateDocumentByOwner.every((owner) => owner.file_DIO !== null);
      const allHasDcdfDocs = geneateDocumentByOwner.every((owner) => owner.file_DCDF !== null);
      const allHasAnexoDocs = geneateDocumentByOwner.every((owner) => owner.file_anexo !== null);

      const allDocsPresent = allHasDioDocs && allHasDcdfDocs && allHasAnexoDocs;

      const allValidAnexoTrue = geneateDocumentByOwner.every((owner) => owner.file_anexo?.validated === true);
      const allValidDioTrue = geneateDocumentByOwner.every((owner) => owner.file_DIO?.validated === true);
      const allValidDCDFTrue = geneateDocumentByOwner.every((owner) => owner.file_DCDF?.validated === true);

      const someInvalid = geneateDocumentByOwner.some(
        (owner) =>
          owner.file_anexo?.validated === false ||
          owner.file_DIO?.validated === false ||
          owner.file_DCDF?.validated === false,
      );

      if (allDocsPresent) {
        if (allValidAnexoTrue && allValidDioTrue && allValidDCDFTrue && allOwnersStatusTrue) {
          setPropietarioProps({
            icon: <CheckIcon style={{ color: "#01A388", fontSize: "22px", margin: "0px" }} />,
            label: "Validado",
            color: "#01A388",
          });
          setAllCompletedPropietario(true);
        } else if (someInvalid) {
          setPropietarioProps({
            icon: <WarningAmberIcon style={{ color: "#F2704F", fontSize: "22px", margin: "0px" }} />,
            label: "Faltantes",
            color: "#F2704F",
          });
          setAllCompletedPropietario(true);
        } else {
          setPropietarioProps({
            icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px" }} />,
            label: "Cargado",
            color: "#A3D4E8",
          });
          setAllCompletedPropietario(true);
        }
      } else {
        setAllCompletedPropietario(false);
      }
    }
  }, [ownersSection, listPropietarios, geneateDocumentByOwner, isSuccessConfigDoc, documentConfig]);

  const handleButtonClick = (seccion: string) => {
    if (seccion === "empresa") {
      if (!dictamenComplete) {
        setOpenModal(true);
        setTitleModal("Sección empresa no disponible");
        setTextModal(
          "La sección empresa, estará disponible una vez que se encuentren cargados los documentos  del dictamen.",
        );
      }
    } else if (seccion === "aval") {
      if (!dictamenValidado) {
        setOpenModal(true);
        setTitleModal("Sección aval y propietario real no disponible");
        setTextModal(
          "La sección de aval y propietario real, estará disponible una vez que se encuentre validado el dictamen.",
        );
      }
    } else if (seccion === "propietario") {
      if (!dictamenValidado) {
        setOpenModal(true);
        setTitleModal("Sección aval y propietario real no disponible");
        setTextModal(
          "La sección de aval y propietario real, estará disponible una vez que se encuentre validado el dictamen.",
        );
        return;
      }
      if (!hasPropietario) {
        setOpenModal(true);
        setTitleModal("Sección de propietario real no disponible");
        setTextModal(
          "La sección de propietario real, no se encuentra disponible ya que la empresa no cuenta con ningún propietario real adicional al aval.",
        );
        return;
      }
    }
  };

  const [allSuccess, setAllSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (
        isSuccessApp &&
        isSuccessAval &&
        isSuccessDocumentApp &&
        typePersonSucess &&
        isSuccessDocAval &&
        isSuccessConfigDoc &&
        isSuccessListPropiestarios
      ) {
        setAllSuccess(true);
      }
    }, 1000);
  }, [
    isSuccessApp,
    isSuccessAval,
    isSuccessDocumentApp,
    typePersonSucess,
    isSuccessDocAval,
    isSuccessConfigDoc,
    isSuccessListPropiestarios,
  ]);

  const closeModal = () => setOpenModal(false);

  return (
    <>
      {allSuccess ? (
        <>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#E0F7FA",
              p: 1,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1, // Espacio entre botones
            }}
          >
            <Grid container>
              <Grid item xs={3} md={3}>
                <Button
                  onClick={() => handleStepClick("/producto/creditosimple/docs_empresa")}
                  sx={{
                    backgroundColor: isActiveDicatamen ? "#003865" : "transparent",
                    color: isActiveDicatamen ? "#FFF" : "#003865",
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "flex",
                    flexDirection: isMobileOrTablet ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#002A5D",
                      color: "#FFF",
                      "& .icon": {
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  }}
                >
                  {dictamenComplete ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {dicatmenProps.icon}
                        <br />
                        Dictamen
                        <br />
                        <span
                          style={{
                            color: dicatmenProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {dicatmenProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Dictamen
                      <img
                        src={imgDictamen}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "18px",
                          height: "18px", //
                          transition: "filter 0.3s",
                          filter: isActiveDicatamen ? "brightness(0) invert(1)" : "",
                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </Button>
              </Grid>

              <Grid item xs={3} md={3} onClick={() => handleButtonClick("empresa")}>
                <Button
                  onClick={() => handleStepClick("/producto/creditosimple/avance_expediente_digital")}
                  sx={{
                    backgroundColor: isActiveEmpresa ? "#003865" : "transparent",
                    color: isActiveEmpresa ? "#FFF" : "#003865",
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "flex",
                    flexDirection: isMobileOrTablet ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#002A5D",
                      color: "#FFF",
                      "& .icon": {
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  }}
                  disabled={!dictamenComplete}
                >
                  {allCompletedSolicitud ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {solicitudProps.icon}
                        <br />
                        Empresa
                        <br />
                        <span
                          style={{
                            color: solicitudProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {solicitudProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Empresa
                      <img
                        src={imgEmpresa}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "18px",
                          height: "18px",
                          transition: "filter 0.3s",
                          filter: !dictamenComplete
                            ? "brightness(0) saturate(100%) invert(75%)"
                            : isActiveEmpresa
                            ? "brightness(0) invert(1)"
                            : "",
                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </Button>
              </Grid>

              <Grid item xs={3} md={3} onClick={() => handleButtonClick("aval")}>
                <Button
                  onClick={() => handleStepClick("/producto/creditosimple/docs_aval")}
                  sx={{
                    backgroundColor: isActiveAval ? "#003865" : "transparent",
                    color: isActiveAval ? "#FFF" : "#003865",
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "flex",
                    flexDirection: isMobileOrTablet ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#002A5D",
                      color: "#FFF",
                      "& .icon": {
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  }}
                  disabled={!dictamenValidado}
                >
                  {allCompletedAval ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {avalProps.icon}
                        <br />
                        Aval
                        <br />
                        <span
                          style={{ color: avalProps.color, fontSize: "9px", margin: 0, padding: 0, fontWeight: 500 }}
                        >
                          {avalProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Aval
                      <img
                        src={imgAval}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "18px",
                          height: "18px", //
                          transition: "filter 0.3s",
                          filter: !dictamenValidado
                            ? "brightness(0) saturate(100%) invert(75%)"
                            : isActiveAval
                            ? "brightness(0) invert(1)"
                            : "",

                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </Button>
              </Grid>

              <Grid item xs={3} md={3} onClick={() => handleButtonClick("propietario")}>
                <Button
                  onClick={() => handleStepClick("/producto/creditosimple/propietario_real_pm")}
                  sx={{
                    backgroundColor: isActivePropietario ? "#003865" : "transparent",
                    color: isActivePropietario ? "#FFF" : "#003865",
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "flex",
                    flexDirection: isMobileOrTablet ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#002A5D",
                      color: "#FFF",
                      "& .icon": {
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  }}
                  disabled={!dictamenValidado || !hasPropietario}
                >
                  {allCompletedPropietario ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {propietarioProps.icon}
                        <br />
                        Propietario
                        <br />
                        <span
                          style={{
                            color: propietarioProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {propietarioProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Propietario
                      <img
                        src={imgProp}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "24px",
                          height: "24px", //
                          transition: "filter 0.3s",
                          filter:
                            !dictamenValidado || !hasPropietario
                              ? "brightness(0) saturate(100%) invert(75%)"
                              : isActivePropietario
                              ? "brightness(0) invert(1)"
                              : "",

                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "0px" : "0px",
                        }}
                      />
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" height={50} />
        </>
      )}

      <Modal open={openModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,

            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#A3D4E8",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color={"#528CD6"} fontWeight={600} fontSize={"17px"}>
            {titleModal}
          </Typography>
          <Typography sx={{ mt: 2 }} color={"#002652"} fontWeight={300} fontSize={"14px"}>
            {textModal}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
