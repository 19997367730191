/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  Button,
  Checkbox,
  FormControlLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { UUID } from "crypto";
import img from "@assets/img/img_tofu/img_tofu_9.svg";
import CustomRadioGroup from "@components/CustomRadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import {
  usePostPaymentDetailsMutation,
  usePostPaymentMethodMutation,
  usePutApplicationCompletedMutation,
  usePutPaymentDetailsMutation,
} from "@api/applications";
import { setPfaeCompleted, setPfaeTransactionalInfo, setPfaeTransactionalInfoId } from "@store/slices/bofuSlicePfae";
import { setModalEndStepBofuVisible, setModalErrosVisible } from "@store/slices/appSlice";
import { useGetPaymentMethodQuery } from "@api/catalogs";
import { InactivityHandler } from "@components/InactivityHandler";

export const InformacionTransaccionalPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pfae = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const [triggerPaymentMethod] = usePostPaymentMethodMutation();
  const [triggerPaymentDetails] = usePostPaymentDetailsMutation();
  const [triggerPutPaymentDetails] = usePutPaymentDetailsMutation();
  const { data: dataPayMethod, isSuccess } = useGetPaymentMethodQuery();
  const [showOtherPaymentField, setShowOtherPaymentField] = useState(false);
  const [idOther, setIdOther] = useState("");

  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const initialValues = {
    paymentMethods: [] as string[],
    otherPaymentMethod: "",
    anticipo: "",
    recursos: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      paymentMethods: yup.array().min(1).of(yup.string().required()).required(),
      otherPaymentMethod: yup.string().when("paymentMethods", (paymentMethods: string[], schema: yup.StringSchema) => {
        return paymentMethods && paymentMethods.includes("CPIOT")
          ? schema.required("Este campo es requerido para el método de pago 'Otro'")
          : schema.notRequired();
      }),
      anticipo: yup.string().required("Campo requerido"),
      recursos: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          await triggerPaymentMethod({
            solicitudId: applicationData.id,
            body: {
              payment_methods: values.paymentMethods.map((id) => ({
                payment_method_id: id,
                other_payment_method: id === idOther ? values.otherPaymentMethod : null,
              })),
            },
          }).unwrap();

          if (pfae.transactional_info.details.id) {
            await triggerPutPaymentDetails({
              solicitudId: applicationData.id,
              detailId: pfae.transactional_info.details.id,
              body: {
                advance_payments: values.anticipo === "true",
                own_account: false,
                own_resources: values.recursos === "true",
                pep_shareholder: false,
                pep_members: false,
              },
            }).unwrap();
          } else {
            const payDetail = await triggerPaymentDetails({
              solicitudId: applicationData.id,
              body: {
                advance_payments: values.anticipo === "true",
                own_account: false,
                own_resources: values.recursos === "true",
                pep_shareholder: false,
                pep_members: false,
              },
            }).unwrap();

            dispatch(setPfaeTransactionalInfoId({ id: String(payDetail.data.id) }));
          }

          dispatch(
            setPfaeTransactionalInfo({
              paymentMethods: formik.values.paymentMethods as UUID[],
              paymentDetailsId: pfae.transactional_info.details.id as UUID,
              advancePayments: formik.values.anticipo === "true",
              resourcesTypes: formik.values.recursos === "true",
            }),
          );
          dispatch(setPfaeCompleted());
          dispatch(setModalEndStepBofuVisible(true));
          const data = {
            bank_information_completed: true,
          };

          await putAapplicationCompleted({ id_sol: applicationData.id, body: data });

          navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
        } catch (error: any) {
          if (error?.status === 500 && error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
      }
    },
  });

  useEffect(() => {
    if (pfae) {
      formik.setFieldValue("anticipo", String(pfae.transactional_info.details.advance_payments));
      formik.setFieldValue("recursos", String(pfae.transactional_info.details.own_resources));
    }
  }, [pfae]);

  useEffect(() => {
    if (isSuccess && dataPayMethod) {
      const cpiotPaymentMethod = dataPayMethod.find((item) => item.code === "CPIOT");

      if (cpiotPaymentMethod) {
        const cpiotUUID = cpiotPaymentMethod.id;
        setIdOther(cpiotUUID);
        setShowOtherPaymentField(formik.values.paymentMethods.includes(cpiotUUID));
      }
    }
  }, [formik.values.paymentMethods]);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box my={2}>
            <Typography align={"center"} fontSize={"16px"} fontWeight={800} color={"#002652"}>
              Información bancaria
            </Typography>
          </Box>

          <Box my={2} mb={1}>
            <Typography align={"center"} fontSize={"16px"} fontWeight={800} color={"#002652"}>
              2 <span style={{ fontWeight: 400 }}>de 2</span>
            </Typography>
          </Box>

          <Box my={1} mb={1}>
            <Typography align={"center"} fontSize={"14px"} fontWeight={600} color={"#002652"}>
              Información transaccional
            </Typography>
          </Box>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box textAlign={"left"} my={2}>
                <Typography fontSize={"13px"}>¿De qué forma realizarás tus pagos?</Typography>
                {dataPayMethod && isSuccess
                  ? dataPayMethod.map((item, index) => {
                      return (
                        <Box my={2} key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="paymentMethods"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={item.id}
                              />
                            }
                            sx={{ fontSize: "13px" }}
                            label={item.name}
                          />
                        </Box>
                      );
                    })
                  : [...Array(4).keys()].map((_, index) => (
                      <Box my={2} key={index} py={1}>
                        <Skeleton variant="text" animation={"wave"} />
                      </Box>
                    ))}

                {/* Mostrar campo de texto si el método de pago "CPIOT" está seleccionado */}
                {showOtherPaymentField && (
                  <TextField
                    fullWidth
                    id="otherPaymentMethod"
                    name="otherPaymentMethod"
                    label="Especifica el método de pago"
                    value={formik.values.otherPaymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.otherPaymentMethod && Boolean(formik.errors.otherPaymentMethod)}
                    helperText={formik.touched.otherPaymentMethod && formik.errors.otherPaymentMethod}
                    margin="normal"
                  />
                )}
              </Box>

              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  label={"¿Realizarás pagos anticipados?"}
                  name={"anticipo"}
                  value={formik.values.anticipo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.anticipo && Boolean(formik.errors.anticipo)}
                  helperText={formik.touched.anticipo && formik.errors.anticipo}
                  options={[
                    {
                      label: "Si",
                      value: "true",
                    },
                    {
                      label: "No",
                      value: "false",
                    },
                  ]}
                />
              </Box>

              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  label={"¿Los recursos son propios o de un tercero?"}
                  name={"recursos"}
                  value={formik.values.recursos}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.recursos && Boolean(formik.errors.recursos)}
                  helperText={formik.touched.recursos && formik.errors.recursos}
                  options={[
                    {
                      label: "Propios",
                      value: "true",
                    },
                    {
                      label: "De un tercero",
                      value: "false",
                    },
                  ]}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                  mb: 3,
                }}
              >
                <Button variant="default" type="submit" disabled={!formik.isValid}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
