/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Grid, Box, Container, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { RootState } from "@store/store";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import icon from "@assets/img/check_wating_room.svg";
import iconLeft from "@assets/img/icon_left_bottom.svg";
import iconRight from "@assets/img/icon_right_top.svg";
import Confetti from "react-confetti";
import { InactivityHandler } from "@components/InactivityHandler";

const SECONDS_TO_FETCH = 10 * 1000;

export const WaitingRoom = () => {
  const navigate = useNavigate();
  const application = useSelector((state: RootState) => state.register.application);
  const [getLoadApplication] = useLazyGetApplicationByApplicationIdQuery();

  const logOut = async () => {
    await signOut();
    navigate("/login");
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#002652";
    const intervalFetch = setInterval(async () => {
      try {
        const response = await getLoadApplication(application.id);
        const url_actual = response?.data?.data ? response?.data?.data[0]?.status_catalog?.url_actual || "" : "";
        if (url_actual.includes("/oferta")) {
          navigate("/producto/creditosimple/oferta");
        } else if (url_actual.includes("/credito_rechazado")) {
          navigate("/producto/creditosimple/credito_rechazado");
        }
      } catch (error) {
        console.log("Ha ocurrido un error");
      }
    }, SECONDS_TO_FETCH);

    return () => {
      intervalFetch && clearInterval(intervalFetch);
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      <Grid container sx={{ position: "relative" }} height="90%">
        <InactivityHandler />
        <Confetti className="confetti-container" numberOfPieces={2000} tweenDuration={20000} recycle={false} />

        <Box
          component="img"
          src={iconRight}
          alt="icono superior derecho"
          sx={{
            position: "absolute",
            top: "22px",
            right: "22px",
            width: "50px",
            height: "50px",
          }}
        />

        <Box
          component="img"
          src={iconLeft}
          alt="icono inferior izquierdo"
          sx={{
            position: "absolute",
            bottom: "-20px",
            left: "22px",
            width: "50px",
            height: "50px",
          }}
        />
        <Grid item xs={12} md={12} mt={4}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container sx={{ mt: 2, mb: 2 }}>
              <Typography fontSize={"31px"} fontWeight={"600"} align="center" color={"#ffffff"} lineHeight={"39.06px"}>
                ¡Concluiste exitosamente tu solicitud!
              </Typography>
            </Container>

            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={icon} alt="" />
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography fontWeight={"300"} align="center" color={"#ffffff"} fontSize={"16px"} lineHeight={"20.16px"}>
                <span>
                  Estamos procesando tu solicitud, esto puede <br /> tomar varios minutos.
                </span>
              </Typography>
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography fontWeight={"300"} align="center" color={"#ffffff"} fontSize={"16px"} lineHeight={"20.16px"}>
                <span>
                  ¡Despreocupate! <br /> Puedes salir de esta pantalla, tan pronto este lista <br /> tu oferta
                  personalizada, te contactaremos vía <br /> WhatsApp, de igual forma puedes iniciar sesión y <br />{" "}
                  consultar tu estatus
                </span>
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Button variant="default" onClick={logOut} sx={{ px: 6, py: 1, mt: 2 }}>
                Salir
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
