import { payloadSocialMedia } from "@interfaces/socialMedia";
import { baseApi } from "./baseApi";

interface ResponseData {
  code: string;
  errors: [];
  message: string;
  data: {
    client_details_id: string;
    facebook: string;
    has_social_media: boolean;
    has_web_page: boolean;
    id: string;
    instagram: string;
    linkedin: string;
    x_twitter: string
    web_page: string;
  };
}

const socialMediaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postSocialMedia: build.mutation<Response, { client_details_id: string; data: payloadSocialMedia }>({
      query: ({ client_details_id, data }) => ({
        url: `/cuentas/${client_details_id}/social_media`,
        method: "POST",
        body: data,
      }),
    }),
    getSocialMedia: build.query<ResponseData, { client_details_id: string }>({
      query: ({ client_details_id }) => ({
        url: `/cuentas/${client_details_id}/social_media`,
        method: "GET",
      }),
    }),
    patchSocialMediaData: build.mutation<Response, { client_details_id: string; data: payloadSocialMedia, social_media_id: string }>({
      query: ({ client_details_id, data , social_media_id}) => ({
        url: `/cuentas/${client_details_id}/social_media/${social_media_id}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const { usePostSocialMediaMutation, useLazyGetSocialMediaQuery, usePatchSocialMediaDataMutation } = socialMediaApi;
