import { AnyAction, configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { diff, atomizeChangeset, applyChangeset } from "json-diff-ts";

import appReducer from "./slices/appSlice";
import bofuReducer from "./slices/bofuSlice";
import bofuSlicePfae from "./slices/bofuSlicePfae";
import registerReducer from "./slices/registerSlice";
import solicitudCreditoReducer from "./slices/solicitudCreditoSlice";

import { baseApi } from "@api/baseApi";
import { baseApiSat } from "@api/api_sat/baseApiSat";
import { baseApiSatCore } from "@api/api_sat/baseApiSatCore";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";

// Configuración de persistencia
const persistConfig = {
  key: "lenditgroup",
  storage,
  whitelist: ["register", "bofu", "app", "bofuPfae"],
  blacklist: [baseApi.reducerPath, baseApiSat.reducerPath, baseApiSatCore.reducerPath],
};

// Reducer combinado
const appsReducer = combineReducers({
  register: registerReducer,
  bofu: bofuReducer,
  bofuPfae: bofuSlicePfae,
  app: appReducer,
  solicitudCredito: solicitudCreditoReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [baseApiSat.reducerPath]: baseApiSat.reducer,
  [baseApiSatCore.reducerPath]: baseApiSatCore.reducer,
});

// Definir RootState para el tipado en el store y en rootReducer
export type RootState = ReturnType<typeof appsReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
  if (action.type === "@@INIT") {
    storage
      .getItem("persist:lenditgroup")
      .then((value) => {
        if (state !== undefined && value !== null) {
          const parsedValue = JSON.parse(value);
          Object.keys(parsedValue).forEach((key) => {
            if (key !== "_persist" && key !== undefined) {
              const tmp2 = parsedValue[key];
              if (state) {
                const diffs = diff(tmp2, state[key as keyof RootState]);
                const changes = atomizeChangeset(diffs).filter((change) => change.type === "ADD");
                state[key as keyof RootState] = applyChangeset(tmp2, changes);
              }
            }
          });
          storage.setItem("persist:lenditgroup", JSON.stringify(state));
        }
      })
      .catch((error) => {
        console.error("Error al comparar y actualizar el estado persistente:", error);
      });
  }

  if (action.type === "USER_LOGOUT" || action.type === "CLEAN_STORE") {
    storage.removeItem("persist:lenditgroup");
    state = undefined;  // Reiniciar el estado global en logout
  }

  return appsReducer(state, action);
};

// Reducer persistente
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuración del store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(baseApi.middleware)
      .concat(baseApiSat.middleware)
      .concat(baseApiSatCore.middleware),
});

// Setup de listeners para RTK Query
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
