/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";

import {
  PayloadBankAccount,
  PayloadPaymentDetails,
  PayloadPaymentMethod,
  Response,
  DetailApplicationResponse,
  SolicitudCompleted,
  SolicitudUpdateStatus,
  ScheduleResponse,
  GetPaymentMethodByIdResponse,
} from "../types";

const solicitudApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationByUserId: build.query<any, number>({
      query: (userId) => `solicitudes?user_id=${userId}`,
    }),
    postDetailApplication: build.query<Response, any>({
      query: (data: { solicitud_id: UUID; body: any }) => ({
        url: `solicitudes/${data.solicitud_id}/detalles`,
        method: "POST",
        body: data.body,
      }),
    }),
    putDetailApplication: build.mutation<Response, any>({
      query: (data: { solicitud_id: UUID; body: any }) => ({
        url: `solicitudes/${data.solicitud_id}/detalles`,
        method: "PUT",
        body: data.body,
      }),
    }),
    getDetailApplication: build.query<DetailApplicationResponse, { solicitud_id: UUID }>({
      query: (data) => ({
        url: `solicitudes/${data.solicitud_id}/detalles`,
        method: "GET",
      }),
    }),

    putPaymentMethod: build.mutation<Response, any>({
      query: (data: { solicitud_id: UUID; body: any }) => ({
        url: `solicitudes/${data.solicitud_id}/forma_pagos`,
        method: "PUT",
        body: data.body,
      }),
    }),
    getPaymentMethod: build.query<DetailApplicationResponse, { solicitud_id: UUID }>({
      query: (data) => ({
        url: `solicitudes/${data.solicitud_id}/forma_pagos`,
        method: "GET",
      }),
    }),

    postLoanUse: build.query<Response, any>({
      query: (data: { solicitud_id: string; body: any }) => ({
        url: `solicitudes/${data.solicitud_id}/destino_recursos`,
        method: "POST",
        body: data.body,
      }),
    }),
    getLoanUseBySol: build.query<Response, { solicitud_id: string}>({
      query: (data: { solicitud_id: string}) => ({
        url: `solicitudes/${data.solicitud_id}/destino_recursos`,
        method: "GET",
       
      }),
    }),
    getValidateNextStage: build.query<DetailApplicationResponse, string>({
      query: (solicitud_id) => ({
        url: `solicitudes/${solicitud_id}/progreso`,
        method: "GET",
      }),
    }),
    postValidateNextStage: build.mutation<undefined, { applicationId: string; statusCode: string }>({
      query: ({ applicationId, statusCode }) => ({
        url: `solicitudes/${applicationId}/progreso`,
        method: "POST",
        body: {
          "status_code": statusCode,
        },
      }),
    }),
    getApplicationByApplicationId: build.query<any, string>({
      query: (soldId) => `solicitudes?application_id=${soldId}`,
    }),

    getScheduleByApplicationId: build.query<ScheduleResponse, string>({
      query: (solicitud_id) => ({
        url: `solicitudes/${solicitud_id}/agendar_visita`,
        method: "GET",
      }),
    }),

    postBankAccounts: build.mutation<Response, { solicitudId: UUID | string; body: PayloadBankAccount }>({
      query: (data: { solicitudId: UUID; body: PayloadBankAccount }) => ({
        url: `solicitudes/${data.solicitudId}/cuentas_bancarias`,
        method: "POST",
        body: data.body,
      }),
    }),
    putBankAccount: build.mutation<
      Response,
      { solicitudId: UUID | string; bankAccountId: UUID | string; body: PayloadBankAccount }
    >({
      query: (data: { solicitudId: UUID | string; bankAccountId: UUID | string; body: PayloadBankAccount }) => ({
        url: `solicitudes/${data.solicitudId}/cuentas_bancarias/${data.bankAccountId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    deleteBankAccount: build.mutation<Response, { solicitudId: UUID | string; accountId: string }>({
      query: ({ solicitudId, accountId }) => ({
        url: `solicitudes/${solicitudId}/cuentas_bancarias/${accountId}`,
        method: "DELETE",
      }),
    }),
    postPaymentMethod: build.mutation<Response, { solicitudId: UUID | string; body: PayloadPaymentMethod }>({
      query: (data: { solicitudId: UUID; body: PayloadPaymentMethod }) => ({
        url: `solicitudes/${data.solicitudId}/forma_pagos`,
        method: "POST",
        body: data.body,
      }),
    }),
    getPaymentMethodById: build.query<GetPaymentMethodByIdResponse, { solicitudId: string }>({
      query: ({ solicitudId }) => ({
        url: `solicitudes/${solicitudId}/forma_pagos`,
        method: "GET",
      }),
    }),
    postPaymentDetails: build.mutation<Response, { solicitudId: UUID | string; body: PayloadPaymentDetails }>({
      query: (data: { solicitudId: UUID; body: PayloadPaymentDetails }) => ({
        url: `solicitudes/${data.solicitudId}/detalles`,
        method: "POST",
        body: data.body,
      }),
    }),
    getPaymentDetails: build.query<Response, { solicitudId: string }>({
      query: (data: { solicitudId: string }) => ({
        url: `solicitudes/${data.solicitudId}/detalles`,
        method: "GET",
      }),
    }),

    putPaymentDetails: build.mutation<
      Response,
      { solicitudId: UUID | string; detailId: string; body: PayloadPaymentDetails }
    >({
      query: (data: { solicitudId: UUID; detailId: UUID; body: PayloadPaymentDetails }) => ({
        url: `solicitudes/${data.solicitudId}/detalles/${data.detailId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    putApplicationCompleted: build.mutation<Response, { id_sol: string; body: SolicitudCompleted }>({
      query: ({ id_sol, body }) => ({
        url: `solicitudes/${id_sol}`,
        method: "PUT",
        body,
      }),
    }),
    putApplicationUpdateStatus: build.mutation<Response, { id_sol: string; body: SolicitudUpdateStatus }>({
      query: ({ id_sol, body }) => ({
        url: `solicitudes/${id_sol}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetApplicationByUserIdQuery,
  useLazyPostDetailApplicationQuery,
  useLazyPostLoanUseQuery,
  useLazyGetValidateNextStageQuery,
  usePostValidateNextStageMutation,
  useLazyGetScheduleByApplicationIdQuery,
  useGetApplicationByApplicationIdQuery,
  useLazyGetApplicationByApplicationIdQuery,
  usePostBankAccountsMutation,
  usePutBankAccountMutation,
  usePostPaymentMethodMutation,
  usePostPaymentDetailsMutation,
  usePutDetailApplicationMutation,
  useLazyGetDetailApplicationQuery,
  useGetPaymentMethodQuery,
  usePutPaymentMethodMutation,
  usePutPaymentDetailsMutation,
  useDeleteBankAccountMutation,
  usePutApplicationCompletedMutation,
  usePutApplicationUpdateStatusMutation,
  useLazyGetPaymentDetailsQuery,
  useLazyGetPaymentMethodByIdQuery,
  useLazyGetLoanUseBySolQuery
} = solicitudApi;
