import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import MainLayout from "../../../layouts/MainLayout";

import {
  ConsultaBuro,
  ConsultaCiec,
  CrearCuenta,
  DatosAccionista,
  DatosEmpresaPm,
  DomicilioAccionista,
  Oferta,
  PresenciaDigitalPm,
  Rechazo,
  RegistroDatosPersonales,
  RfcCurpForm,
  UsoDeCredito,
  ValidacionTelefono,
  WaitingRoom,
} from "@pages/producto/credito_simple/tofu/pm";

import {
  ListaRevision,
  DocsEmpresa,
  InformacionAval,
  InformacionBancaria,
  ReferenciasClientes,
  ReferenciasProveedores,
  DocsAval,
  ExpedienteDigitalCompleto,
  SalidaTemporal,
  InfoDocumentosPFAE,
  ValidarIdentidadAval,
  AvanceExpedienteDigital,
  SolicitudCreditoPart1,
  SolicitudCreditoPart3,
  PropietarioRealPM,
  DatosPropietarioRealPart1,
  DatosPropietarioRealPart2,
  InformacionAvalP2,
  ValidarIdentidadRepLegal,
  AgendaVisitaOcularPM,
  InformacionTransaccional,
  ValidandoExpediente,
} from "@pages/producto/credito_simple/bofu/pm";

import {
  ReferenciasClientesPfae,
  ReferenciasProveedoresPfae,
  ValidarIndentidadPfae,
  AvanceExpedienteDigitalPFAE,
  SolicitudCreditoPart1PFAE,
  SolicitudCreditoPart2PFAE,
  SolicitudCreditoPart3PFAE,
  AgendaVisitaOcularPFAE,
  ValidandoExpedientePFAE,
  InformacionBancariaPfae,
  InformacionTransaccionalPfae,
} from "@pages/producto/credito_simple/bofu/pfae";

import { ConsultaBuroPfae, DatosEmpresaPfae, DomicilioPfae, PresenciaDigitalPfae } from "./tofu/pfae";
import { ConfirmacionVisitaAgendada, RechazoManual, AgendaVisita,FirmaDocumentos } from "./bofu";
import { RootState } from "@store/store";
import { ModalError404 } from "@components/ModalError404";

export const CreditoSimple = () => {
  const appData = useSelector((state: RootState) => state.app);

  return (
    // <Suspense fallback={<div>Cargando..</div>}>
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {/* <Route index element={<CrearCuenta />} /> */}
        {/* {applicationData.type == "PM" ? ( */}
        {/* <> */}
        <Route index element={<Navigate to={appData.process_page} />} />

        <Route path="crear_cuenta" element={<CrearCuenta />} />
        <Route path="registro_datos_personales" element={<RegistroDatosPersonales />} />
        <Route path="validacion_telefono" element={<ValidacionTelefono />} />
        <Route path="datos_empresa_pfae" element={<DatosEmpresaPfae />} />
        <Route path="datos_empresa_pm" element={<DatosEmpresaPm />} />
        <Route path="consulta_ciec" element={<ConsultaCiec />} />
        <Route path="uso_de_credito" element={<UsoDeCredito />} />
        <Route path="presencia_digital" element={<PresenciaDigitalPm />} />
        <Route path="datos_accionista" element={<DatosAccionista />} />
        <Route path="rfc_curp" element={<RfcCurpForm />} />
        <Route path="domicilio_accionista" element={<DomicilioAccionista />} />
        <Route path="consulta_buro" element={<ConsultaBuro />} />
        <Route path="waiting_room" element={<WaitingRoom />} />
        <Route path="credito_rechazado" element={<Rechazo />} />
        <Route path="oferta" element={<Oferta />} />

        <Route path="check_list" element={<ListaRevision />} />
        <Route path="docs_empresa" element={<DocsEmpresa />} />

        <Route path="avance_expediente_digital" element={<AvanceExpedienteDigital />} />

        <Route path="solicitud_credito_1" element={<SolicitudCreditoPart1 />} />
        <Route path="solicitud_credito_2" element={<InformacionTransaccional />} />
        <Route path="solicitud_credito_3" element={<SolicitudCreditoPart3 />} />

        <Route path="referencias_clientes" element={<ReferenciasClientes />} />
        <Route path="referencias_proveedores" element={<ReferenciasProveedores />} />

        <Route path="info_bancaria_empresa" element={<InformacionBancaria type="EMPRESA" />} />
        <Route path="validacion_representante_legal" element={<ValidarIdentidadRepLegal />} />

        <Route path="validando_dictamen" element={<SalidaTemporal type="ValDic" />} />

        <Route path="docs_aval" element={<DocsAval />} />
        <Route path="informacion_datos_aval" element={<InformacionAval />} />
        <Route path="info_bancaria_aval" element={<InformacionBancaria type="AVAL" />} />
        <Route path="informacion_datos_aval_2" element={<InformacionAvalP2 />} />

        <Route path="validacion_identidad_aval" element={<ValidarIdentidadAval />} />

        <Route path="propietario_real_pm" element={<PropietarioRealPM />} />
        <Route path="datos_propietario_real_1" element={<DatosPropietarioRealPart1 />} />
        <Route path="datos_propietario_real_2" element={<DatosPropietarioRealPart2 />} />

        <Route path="completado" element={<ExpedienteDigitalCompleto />} />
        <Route path="validando_expediente" element={<SalidaTemporal type="ValExp" />} />

        <Route path="domicilio_pfae" element={<DomicilioPfae />} />
        <Route path="presencia_digital_pfae" element={<PresenciaDigitalPfae />} />
        <Route path="consulta_buro_pfae" element={<ConsultaBuroPfae />} />

        <Route path="informacion_documentos_pfae" element={<InfoDocumentosPFAE />} />
        <Route path="avance_expediente_digitalPFAE" element={<AvanceExpedienteDigitalPFAE />} />
        <Route path="solicitud_credito_1PFAE" element={<SolicitudCreditoPart1PFAE />} />
        <Route path="solicitud_credito_2PFAE" element={<SolicitudCreditoPart2PFAE />} />
        <Route path="solicitud_credito_3PFAE" element={<SolicitudCreditoPart3PFAE />} />

        <Route path="confirmacion_domicilio" element={<SalidaTemporal type="ConfDom" />} />
        <Route path="visita_ocular_concluida" element={<SalidaTemporal type="ConfVisOcCon" />} />
        <Route path="visita_y_cotejo" element={<SalidaTemporal type="ConfValVisOc" />} />
        <Route path="bofu_completado" element={<SalidaTemporal type="ConfBofuComp" />} />

        <Route path="agenda_visita_pfae" element={<AgendaVisitaOcularPFAE />} />
        <Route path="firma_documentos" element={<FirmaDocumentos />} />

        <Route path="agenda_visita_pm" element={<AgendaVisitaOcularPM />} />

        <Route path="agenda_visita" element={<AgendaVisita />} />

        <Route path="validacion_identidad_pfae" element={<ValidarIndentidadPfae />} />
        <Route path="referencias_clientes_pfae" element={<ReferenciasClientesPfae />} />
        <Route path="referencias_proveedores_pfae" element={<ReferenciasProveedoresPfae />} />
        <Route path="info_bancaria_pfae" element={<InformacionBancariaPfae />} />
        <Route path="info_transaccional_pfae" element={<InformacionTransaccionalPfae />} />

        <Route path="confirmacion_visita_agendada" element={<ConfirmacionVisitaAgendada />} />

        <Route path="rechazado_documentacion" element={<RechazoManual />} />

        <Route path="validando_expediente_pm" element={<ValidandoExpediente isValidExpediente={true} />} />
        <Route path="errores_expediente_pm" element={<ValidandoExpediente isValidExpediente={false} />} />

        <Route path="validando_expediente_pfae" element={<ValidandoExpedientePFAE isValidExpediente={true} />} />
        <Route path="errores_expediente_pfae" element={<ValidandoExpedientePFAE isValidExpediente={false} />} />

        <Route path="*" element={<ModalError404 isModalOpen={true} type="404" />} />
      </Route>
      {/* <Route path="*" element={<Navigate to={appData.process_page} />}></Route> */}
    </Routes >
    // </Suspense>
  );
};
