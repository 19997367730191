/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { FormikErrors, useFormik } from "formik";

import { RootState } from "@store/store";
import { CustomUploadFile } from "@components/CustomUploadFile";
import { Bank } from "@interfaces/catalogs";
import { useLazyGetBanksByCodeQuery } from "@api/catalogs";
import {
  useDeleteBankAccountMutation,
  usePostBankAccountsMutation,
  usePutApplicationCompletedMutation,
  usePutBankAccountMutation,
} from "@api/applications";

import {
  removeCompanyBankAccount,
  removeCompanyBankAccountFile,
  removeGuaranteBankAccountFile,
  removeGuaranteeBankAccount,
  setCompanyBankAccount,
  setCompanyBankAccountFile,
  setCompanyBankAccountUpdate,
  setCompanyBankAccountUpdateId,
  setGuaranteeBankAccount,
  setGuaranteeBankAccountFile,
  setGuaranteeBankAccountUpdate,
  setGuaranteeBankAccountUpdateId,
} from "@store/slices/bofuSlice";
import { UploadedFile } from "@interfaces/store";

import img from "@assets/img/img_tofu/img_tofu_14.svg";
import { useDeleteDocumentMutation } from "@api/documents";
import { CustomDialog } from "@components/index";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { usePatchPersonDataMutation } from "@api/personasApi";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";

interface InformacionBancariaProps {
  type: "EMPRESA" | "AVAL";
}

interface BankAccountSection extends InformacionBancariaProps {
  index: number;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  registerFormik?: (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => void;
}

const BankAccountSection = ({ setFieldValue, registerFormik, type, index }: BankAccountSection) => {
  const dispatch = useDispatch();

  const [bankInfo, setBankInfo] = useState<Bank | undefined>(undefined);
  const [sectionInternalId] = useState<string>(crypto.randomUUID());
  const [validations, setValidations] = useState<yup.ObjectShape>({
    clabe: yup.string().min(18, "Formato incorrecto, debe tener 18 dígitos").required("Campo requerido."),
    banco: yup.string().required("Campo requerido."),
  });

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const shareholderData = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const sectionData = useSelector((state: RootState) => {
    if (type === "EMPRESA") {
      return state.bofu.digital_file.company;
    } else {
      return state.bofu.digital_file.guarantee;
    }
  });
  const [triggerPutBankAccount] = usePutBankAccountMutation();
  const [triggerPostBankAccount] = usePostBankAccountsMutation();
  const [triggerGetBanks, resultGetBanks] = useLazyGetBanksByCodeQuery();

  const formik = useFormik({
    initialValues: {
      clabe: "",
      banco: "",
    },
    validationSchema: yup.object().shape(validations),
    onSubmit: async (values) => {
      try {
        const bankAccount = sectionData.bank_accounts[index];

        const isBankAccountNew = !bankAccount.id || bankAccount.id === "00000000-0000-0000-0000-000000000000";

        const bankAccountPayload = {
          account: 0,
          clabe: values.clabe,
          account_holder: "",
          bank_id: bankInfo ? bankInfo.id : "0-0-0-0-0",
          loan_application_id: applicationData.id,
          client_details_id: type === "EMPRESA" ? personalData.id : null,
          person_id: type === "AVAL" ? shareholderData.id : null,
          bank_statment_first_documents_id: String(sectionData.bank_accounts[index].bank_statements[0].id),
          bank_statment_second_documents_id: String(sectionData.bank_accounts[index].bank_statements[1].id),
          bank_statment_third_documents_id: String(sectionData.bank_accounts[index].bank_statements[2].id),
        };

        if (isBankAccountNew) {
          const postBank = await triggerPostBankAccount({
            solicitudId: applicationData.id,
            body: bankAccountPayload,
          }).unwrap();

          if (type === "EMPRESA") {
            dispatch(
              setCompanyBankAccountUpdateId({
                index,
                id: String(postBank.data.id),
              }),
            );
          } else {
            dispatch(
              setGuaranteeBankAccountUpdateId({
                index,
                id: String(postBank.data.id),
              }),
            );
          }
        } else {
          await triggerPutBankAccount({
            solicitudId: applicationData.id,
            bankAccountId: String(bankAccount.id),
            body: bankAccountPayload,
          }).unwrap();
        }

        if (type === "EMPRESA") {
          dispatch(
            setCompanyBankAccountUpdate({
              index,
              clabe: values.clabe,
              bank_name: String(bankInfo?.name),
            }),
          );
        } else {
          dispatch(
            setGuaranteeBankAccountUpdate({
              index,
              clabe: values.clabe,
              bank_name: String(bankInfo?.name),
            }),
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateOnMount: true,
  });

  const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  useEffect(() => {
    formik.setFieldValue("clabe", sectionData.bank_accounts[index].clabe);
    triggerGetBanks(sectionData.bank_accounts[index].clabe.substring(0, 3));

    registerFormik?.(
      formik.submitForm,
      sectionInternalId,
      yup.boolean().isTrue(`La sección con id ${sectionInternalId} no ha pasado sus validaciones`),
    );
  }, []);

  useEffect(() => {
    setFieldValue?.(sectionInternalId, formik.isValid);
  }, [formik.isValid]);

  useEffect(() => {
    if (resultGetBanks.isSuccess && resultGetBanks.currentData) {
      setBankInfo(resultGetBanks.data);
      formik.setFieldValue("banco", resultGetBanks.data.name);
    }
  }, [resultGetBanks]);

  const handleOnChangeCLABE = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    if (!event?.target) return;

    const numericText = event.target.value.replace(/[^0-9]/g, "");
    formik.setFieldValue("clabe", numericText);
    if (numericText.length >= 3) {
      try {
        const bank = await triggerGetBanks(numericText.substring(0, 3));

        if (bank.data === undefined || bank.data === null || Object.keys(bank.data).length === 0) {
          throw new Error("CLABE no válida");
        }

        formik.setFieldValue("banco", bank.data.name);
        formik.setFieldError("banco", "");
      } catch (error) {
        if (numericText.length >= 18) {
          formik.setFieldError("banco", String(error) || "Error desconocido");
          formik.setErrors({ clabe: "Clabe bancaria incorrecta, validar nuevamente." });
        }
      }
    } else {
      formik.setFieldValue("banco", "");
    }
  };

  const handleUpdateStore = (action: any, indexFile: number, uploadedFile: UploadedFile | null, _fileId: string) => {
    if (action === "ADD" && uploadedFile !== null) {
      dispatch(
        type === "EMPRESA"
          ? setCompanyBankAccountFile({
              parentIndex: index,
              indexFile: indexFile,
              uploadedFile: uploadedFile,
            })
          : setGuaranteeBankAccountFile({
              parentIndex: index,
              indexFile: indexFile,
              uploadedFile: uploadedFile,
            }),
      );
    } else if (action === "DEL") {
      dispatch(
        type == "EMPRESA"
          ? removeCompanyBankAccountFile({
              parentIndex: index,
              indexFile: indexFile,
            })
          : removeGuaranteBankAccountFile({
              parentIndex: index,
              indexFile: indexFile,
            }),
      );
    }
  };

  return (
    <Container>
      <TextField
        id="clabe"
        name="clabe"
        label="Cuenta CLABE"
        margin="normal"
        type="text"
        required
        fullWidth
        autoComplete="off"
        onBlur={formik.handleBlur}
        onChange={handleOnChangeCLABE}
        value={formik.values.clabe}
        error={formik.touched.clabe && Boolean(formik.errors.clabe)}
        helperText={formik.touched.clabe ? formik.errors.clabe : ""}
        inputProps={{ maxLength: 18 }}
      />
      <TextField
        id="banco"
        name="banco"
        label="Banco"
        margin="normal"
        required
        fullWidth
        autoComplete="off"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.banco}
        error={formik.touched.banco && Boolean(formik.errors.banco)}
        helperText={formik.touched.banco ? formik.errors.banco : ""}
        disabled
      />

      <CustomUploadFile
        py={2}
        index={0}
        updateStore={handleUpdateStore}
        uploadedFile={
          sectionData.bank_accounts[index] ? sectionData.bank_accounts[index].bank_statements[0] : undefined
        }
        optionalName="1er estado de cuenta bancario"
        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
        setFieldValue={formik.setFieldValue}
        registerFormik={registerInternalFormik}
        errors={formik.errors}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          fileCode: "DECB",
          uploadType: type === "EMPRESA" ? "client" : "person",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          person_id: type === "AVAL" ? (shareholderData.id as UUID) : undefined,
          clientDetailsId: personalData.id,
          personType: type === "EMPRESA" ? undefined : "accionista_mayoritario",
        }}
      />

      <CustomUploadFile
        py={2}
        index={1}
        updateStore={handleUpdateStore}
        uploadedFile={
          sectionData.bank_accounts[index] ? sectionData.bank_accounts[index].bank_statements[1] : undefined
        }
        optionalName="2do estado de cuenta bancario"
        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
        setFieldValue={formik.setFieldValue}
        registerFormik={registerInternalFormik}
        errors={formik.errors}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          fileCode: "DECB",
          uploadType: type === "EMPRESA" ? "client" : "person",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          person_id: type === "AVAL" ? (shareholderData.id as UUID) : undefined,
          clientDetailsId: personalData.id,
          personType: type === "EMPRESA" ? undefined : "accionista_mayoritario",
        }}
      />

      <CustomUploadFile
        py={2}
        index={2}
        updateStore={handleUpdateStore}
        uploadedFile={
          sectionData.bank_accounts[index] ? sectionData.bank_accounts[index].bank_statements[2] : undefined
        }
        optionalName="3er estado de cuenta bancario"
        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
        setFieldValue={formik.setFieldValue}
        registerFormik={registerInternalFormik}
        errors={formik.errors}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          fileCode: "DECB",
          uploadType: type === "EMPRESA" ? "client" : "person",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          person_id: type === "AVAL" ? (shareholderData.id as UUID) : undefined,
          clientDetailsId: personalData.id,
          personType: type === "EMPRESA" ? undefined : "accionista_mayoritario",
        }}
      />
    </Container>
  );
};

export const InformacionBancaria = (props: InformacionBancariaProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const sectionData = useSelector((state: RootState) => {
    if (props.type === "EMPRESA") {
      return state.bofu.digital_file.company;
    } else {
      return state.bofu.digital_file.guarantee;
    }
  });
  const applicationData = useSelector((state: RootState) => state.register.application);
  const aval = useSelector((state: RootState) => state.register.shareholder);
  const [patchPersonData] = usePatchPersonDataMutation();

  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const [validForm, setValidForm] = useState(false);

  const [formiks, setFormiks] = useState<{ [k: string]: () => Promise<any> }>({});

  const [deleteBanckAccount] = useDeleteBankAccountMutation();
  const [deleteBannkDocuments] = useDeleteDocumentMutation();

  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [acountDelete, setAcountDelete] = useState<number>(0);

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  const handleOnOpenModal = (index: number) => {
    setCloseModal(false);
    setAcountDelete(index);
  };

  const handleDeleteAccount = async (indexToDelete: number) => {
    if (
      sectionData.bank_accounts[indexToDelete].id &&
      sectionData.bank_accounts[indexToDelete].id !== "00000000-0000-0000-0000-000000000000"
    ) {
      await deleteBanckAccount({
        solicitudId: String(applicationData.id),
        accountId: String(sectionData.bank_accounts[indexToDelete].id),
      }).unwrap();
    }

    const documents = sectionData.bank_accounts[indexToDelete].bank_statements;

    const validDocuments: any = documents.filter(
      (doc: any) => doc.id && doc.id !== "00000000-0000-0000-0000-000000000000",
    );

    for (const doc of validDocuments) {
      try {
        await deleteBannkDocuments(doc.id);
      } catch (error) {
        console.error(`Failed to delete document ${doc.id}:`, error);
      }
    }

    if (props.type === "EMPRESA") {
      dispatch(removeCompanyBankAccount(indexToDelete));
    } else {
      dispatch(removeGuaranteeBankAccount(indexToDelete));
    }

    setBankAccounts((prevAccounts) => prevAccounts.filter((_, index) => index !== indexToDelete));
    formik.resetForm();
    formik.validateForm();
    setTimeout(() => {
      setValidForm(true);
    }, 200);
    setCloseModal(true);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      const tmp = [];
      for (const key in formiks) {
        tmp.push(formiks[key]());
      }

      await Promise.all(tmp);

      if (props.type === "EMPRESA") {
        const data = {
          bank_information_completed: true,
        };

        await putAapplicationCompleted({ id_sol: applicationData.id, body: data }).unwrap();
        window.location.href = "/producto/creditosimple/avance_expediente_digital";
      } else {
        const dataAval = {
          bank_references_completed: true,
          id: aval.id,
        };
        try {
          await patchPersonData(dataAval).unwrap();
          window.location.href = "/producto/creditosimple/docs_aval";
        } catch (error: any) {
          if (error?.status === 500 || error?.status === 403) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
      }
    },
  });

  const registerFormik = (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
    formiks[name] = formikLocal;
    setFormiks(formiks);

    formik.validateForm();
  };

  const [bankAccounts, setBankAccounts] = useState<Array<ReactNode>>(
    sectionData.bank_accounts?.length > 0
      ? sectionData.bank_accounts.map((_bankAccount, index) => (
          <BankAccountSection
            key={crypto.randomUUID()}
            index={index}
            type={props.type}
            registerFormik={registerFormik}
            setFieldValue={formik.setFieldValue}
          />
        ))
      : [
          <BankAccountSection
            key={crypto.randomUUID()}
            index={0}
            type={props.type}
            registerFormik={registerFormik}
            setFieldValue={formik.setFieldValue}
          />,
        ],
  );

  useEffect(() => {
    const isValidForm = formik.dirty && formik.isValid && Object.keys(formik.errors).length === 0;

    setValidForm(isValidForm);
  }, [formik.dirty, formik.isValid, formik.errors]);

  const handleOnAddAccountBank = () => {
    dispatch(props.type === "EMPRESA" ? setCompanyBankAccount() : setGuaranteeBankAccount());
    const tmpUUID = crypto.randomUUID();

    setBankAccounts([
      ...bankAccounts,
      <BankAccountSection
        key={tmpUUID}
        index={bankAccounts.length}
        type={props.type}
        registerFormik={registerFormik}
        setFieldValue={formik.setFieldValue}
      />,
    ]);
  };

  const handleOnClickRestart = () => {
    if (props.type === "EMPRESA") {
      navigate("/producto/creditosimple/avance_expediente_digital");
    } else {
      navigate("/producto/creditosimple/docs_aval");
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} variant="body2" fontWeight={700}>
              Información bancaria
            </Typography>
            {props.type == "EMPRESA" ? (
              <>
                <Typography variant="body2" fontWeight={800}>
                  1{" "}
                  <Typography component="span" variant="body2" fontWeight={400}>
                    de 1
                  </Typography>
                </Typography>
                <Typography my={3} variant="body2" color={"#002652"} fontWeight={600}>
                  Información bancaria
                </Typography>
                <Typography my={3} color={"#528CD6"} variant="body2" fontWeight={600}>
                  Ingresa los datos de la cuenta en dónde concentras la mayor facturación de tu negocio, a esta cuenta
                  depositaremos tu crédito
                </Typography>
              </>
            ) : (
              <Typography my={3} color={"#528CD6"} variant="body2" fontWeight={600}>
                Ingresa los datos de tu cuenta principal, donce contras la mayoría de tu actividad bancaría.
              </Typography>
            )}

            <form onSubmit={formik.handleSubmit}>
              <Box>
                {bankAccounts.length > 0 &&
                  bankAccounts.map((bankAccount, index) => (
                    <Box key={index}>
                      {bankAccount}
                      <Container>
                        {index !== 0 && (
                          <Box textAlign={"left"} my={2} mt={2}>
                            <Link onClick={() => handleOnOpenModal(index)} sx={{ cursor: "pointer" }}>
                              <Typography variant="body1" fontWeight={400}>
                                - Eliminar cuentas bancaria
                              </Typography>
                            </Link>
                          </Box>
                        )}
                      </Container>
                    </Box>
                  ))}
              </Box>

              <Container>
                <Box textAlign={"left"} my={2}>
                  <Link onClick={handleOnAddAccountBank} sx={{ cursor: "pointer" }}>
                    <Typography variant="body1" fontWeight={400}>
                      + Agregar cuenta bancaria
                    </Typography>
                  </Link>
                </Box>
              </Container>

              <Box my={3} px={3}>
                <Typography variant="body3" fontWeight={600} color="#002652" textAlign={"center"}>
                  {props.type == "EMPRESA"
                    ? "Si la cuenta refleja menos del 50% de los ingresos agrega otra cuenta bancaria hasta llegar a este porcentaje"
                    : "Si tienes más de una cuenta principal, puedes agregarlas."}
                </Typography>
              </Box>

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="blue_outlined"
                    sx={{ padding: "0", height: "100%" }}
                    onClick={handleOnClickRestart}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth type="submit" variant="default" disabled={!validForm}>
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomDialog
        colorClose="#A3D4E8"
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} color={"#002652"} fontWeight={600} fontSize={"14px"} mt={4}>
            ¿Estas seguro de eliminar la cuenta bancaria?
          </Typography>
        }
        itemContent={
          <Box mt={2} mb={2} mr={1} ml={1}>
            <Box display={"flex"} columnGap={2} justifyContent={"center"}>
              <Button variant="blue_outlined" sx={{ py: 0, px: 1, width: "110px" }} onClick={handleOnCloseModal}>
                Regresar
              </Button>
              <Button variant="default" sx={{ width: "110px" }} onClick={() => handleDeleteAccount(acountDelete)}>
                Eliminar
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};
