/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  SxProps,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useLazyGetDocumentsContractsQuery } from "@api/accountData";
import { useGetUrlToDownloadDocMutation } from "@api/documents";
import dayjs from "dayjs";
import { forceDownload, getMimeTypeFromFilename } from "@helpers/funciones";
import { DocumentContract } from "@interfaces/ConsultaContratos";
import ModalFilePreview, { FileParams } from "@components/ModalFilePreview";

const styleButtonSee: SxProps = {
  backgroundColor: "#002652",
  color: "#fff",
  width: "100%",
  height: "100%",
  borderRadius: "7px 0px 0px 7px",
};

const styleButtonDownload: SxProps = {
  backgroundColor: "#6BA4E6",
  color: "#fff",
  width: "100%",
  height: "100%",
  borderRadius: "0px 7px 7px 0px",
};

const styleRowNoData: SxProps = {
  fontSize: "16px",
  color: "#002652",
  textAlign: "center",
};

const styleTitle: SxProps = {
  fontSize: "31px",
  fontWeight: "bold",
  color: "#002652",
  textAlign: "center",
  marginBottom: 4,
};

export const DocumentosContratos = () => {
  const loanData = useSelector((state: RootState) => state.register);
  const [getDocumentsContracts] = useLazyGetDocumentsContractsQuery();
  const [documents, setDocuments] = useState<DocumentContract[]>([]);
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [fileParams, setFileParams] = useState<FileParams | null>(null);

  const seeDocument = async (document: DocumentContract) => {
    try {
      const response_url = await triggerPostUrlDocument(document.url).unwrap();
      setFileParams({
        file_name: document.file_name,
        file_url: response_url,
      });
    } catch (error) {
      console.error("Error al obtener el documento", error);
    }
  };

  const downloadDocument = async (dc: DocumentContract) => {
    try {
      const response_url = await triggerPostUrlDocument(dc.url).unwrap();
      const file = await fetch(response_url);
      const blob = await file.blob();
      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(dc.file_name) }));
      forceDownload(url, dc.file_name);
    } catch (error) {
      console.error("Error al descargar el documento", error);
    }
  };

  useEffect(() => {
    const getDocuments = async () => {
      try {
        if (!loanData || !loanData.application || !loanData.application.id) {
          console.log("No se encontró información de la solicitud de préstamo");
          return;
        }

        setIsLoading(true);

        const response_documents = await getDocumentsContracts({
          loan_application_id: loanData.application.id,
        }).unwrap();

        const documents_found: any = [];

        response_documents.forEach((document: any) => {
          const newItem: DocumentContract = {
            id: document.id,
            url: document.url,
            year: "",
            file_name: document.file_name,
            file_category_id: document?.file_category?.id,
            file_category_name: document?.file_category?.name,
          };

          if (document.sign_date && dayjs(document.sign_date).isValid()) {
            newItem.year = dayjs(document.sign_date).format("YYYY");
          }

          documents_found.push(newItem);
        });

        setDocuments(documents_found);
      } catch (error) {
        console.error("Error al obtener los documentos de los contratos", error);
      } finally {
        setIsLoading(false);
      }
    };

    getDocuments();
  }, [loanData]);

  return (
    <Box sx={{ width: "100%", paddingY: 4 }}>
      <Box sx={{ marginBottom: 2, textAlign: "left" }}>
        <Button
          sx={{
            fontSize: "16px",
            color: "#F2704F",
          }}
        >
          &lt; Regresar a inicio
        </Button>
      </Box>

      <Typography variant="body2" sx={styleTitle}>
        Contratos
      </Typography>

      <Box
        sx={{
          maxWidth: "700px",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <TableContainer
          sx={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: 4,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: "16px", color: "#002652" }}>Documento</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "16px", color: "#002652" }}>Año</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={4} sx={styleRowNoData}>
                    Buscando documentos...
                  </TableCell>
                </TableRow>
              )}
              {!isLoading && documents.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={styleRowNoData}>
                    No se encontraron documentos
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                documents.map((documento, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{documento.file_category_name}</TableCell>
                    <TableCell>{documento.year}</TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Button variant="contained" sx={styleButtonSee} onClick={() => seeDocument(documento)}>
                        Ver
                      </Button>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <Button variant="contained" sx={styleButtonDownload} onClick={() => downloadDocument(documento)}>
                        Descargar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ModalFilePreview
          fileParams={fileParams}
          onClose={() => setFileParams(null)}
        />
      </Box>
    </Box>
  );
};
