/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { useDispatch } from "react-redux";

import CustomRadioGroup from "@components/CustomRadioGroup";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

import img from "@assets/img/img_tofu/img_tofu_10.svg";

import { usePatchPersonDataMutation, useLazyGetPersonByIdQuery } from "@api/personasApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import {
  useLazyGetCargoPublicoQuery,
  useLazyGetDependeciasQuery,
  useLazyGetPepsTypeQuery,
  useLazyGetRelationshipQuery,
} from "@api/catalogs";
import { CargoPublicoData, DependenciasData, RelationshipData } from "@interfaces/index";
import {
  useDeletePepsMutation,
  useLazyGetPepsByPersonaIdQuery,
  usePatchPepsMutation,
  usePotsPepsDataMutation,
} from "@api/pepsApi";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { Spinner } from "@components/index";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const validationSchema = yup.object().shape({
  publicas: yup.string().required("Campo requerido"),
  conyuge: yup.string().required("Campo requerido"),
  dependencia: yup.string().when("publicas", {
    is: "true",
    then: (schema) => schema.required("Campo requerido"),
  }),
  cargo: yup.string().when("publicas", {
    is: "true",
    then: (schema) => schema.required("Campo requerido"),
  }),
  actualmente: yup.string().when("publicas", {
    is: "true",
    then: (schema) => schema.required("Campo requerido"),
  }),
  start_date: yup.string().when("publicas", {
    is: "true",
    then: (schema) => schema.required("Campo requerido").matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido"),
  }),
  end_date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
    .when("actualmente", {
      is: "false",
      then: (schema) =>
        schema
          .required("Campo requerido")
          .test(
            "end_date_greater_than_start_date",
            "La fecha de término no puede ser menor que la fecha de inicio",
            function (value) {
              const { start_date } = this.parent;
              // Validar solo si ambas fechas existen
              if (start_date && value) {
                return dayjs(value).isSameOrAfter(dayjs(start_date));
              }
              return true; // Si no existen ambas fechas, la validación es correcta
            },
          )
          .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
            if (value) {
              // Verificar que la fecha de término no sea mayor al día actual
              return dayjs(value).isSameOrBefore(dayjs(), "day");
            }
            return true; // Si no existe fecha, la validación es correcta
          }),
    }),

  peps_conyuge: yup.array().of(
    yup.object().shape({
      parentesco_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      nombre_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_paterno_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_materno_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      dependencia_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      cargo_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      actualmente_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      start_date_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      end_date_conyuge: yup.string().when("actualmente_conyuge", {
        is: "false",
        then: (schema) =>
          schema
            .required("Campo requerido")
            .test(
              "end_date_greater_than_start_date",
              "La fecha de término no puede ser menor que la fecha de inicio",
              function (value) {
                const { start_date_conyuge } = this.parent;
                if (start_date_conyuge && value) {
                  return dayjs(value).isSameOrAfter(dayjs(start_date_conyuge), "day");
                }
                return true;
              },
            )
            .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
              if (value) {
                return dayjs(value).isSameOrBefore(dayjs(), "day");
              }
              return true;
            }),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  ),
});

export const DatosPropietarioRealPart2: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [patchPersonData] = usePatchPersonDataMutation();
  const [postPeps] = usePotsPepsDataMutation();
  const [patchPeps] = usePatchPepsMutation();

  const [isLoading, setIsLoading] = useState(false);

  const [getPersona, { data: dataPerson, isSuccess }] = useLazyGetPersonByIdQuery();

  const [getDependeciasLista, { data: dataDependencia, isSuccess: loadingDependencia }] = useLazyGetDependeciasQuery();
  const [getCargoPulico, { data: dataCargo, isSuccess: loadingCargo }] = useLazyGetCargoPublicoQuery();
  const [getPepsType, { data: dataPeps, isSuccess: loadingPeps }] = useLazyGetPepsTypeQuery();
  const [getFamiliar, { data: dataFamiliar, isSuccess: loadingFamiliar }] = useLazyGetRelationshipQuery();
  const [getPepsByPersona, { data: dataPepsPerson }] = useLazyGetPepsByPersonaIdQuery();

  const [listDependecias, setListDependecias] = useState<DependenciasData[]>([]);
  const [listCargo, setListCargo] = useState<CargoPublicoData[]>([]);
  const [listFamiliar, setListFamiliar] = useState<RelationshipData[]>([]);

  const [pepsId, setPepsId] = useState("");
  const [pepsIdPropias, setPepsIdPropias] = useState("");

  const [pepsIdPropioRegistro, setPepsIdPropioRegistro] = useState("");

  const [disableAdd, setDisableAdd] = useState(false);

  const ownerId = useSelector((state: RootState) => state.bofu.digital_file.actual_owner.current_owner_id);
  const application = useSelector((state: RootState) => state.register.application);
  const [deletePeps] = useDeletePepsMutation();

  const initialValues = {
    publicas: "",
    conyuge: "",
    dependencia: "",
    cargo: "",
    actualmente: "",
    start_date: "",
    end_date: "",
    peps_conyuge: [
      {
        parentesco_conyuge: "",
        nombre_conyuge: "",
        ap_paterno_conyuge: "",
        ap_materno_conyuge: "",
        dependencia_conyuge: "",
        cargo_conyuge: "",
        actualmente_conyuge: "",
        start_date_conyuge: "",
        end_date_conyuge: "",
        id: "",
      },
    ],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          const dataPropiestario = {
            id: String(ownerId),
            pep_shareholder: values.publicas === "true",
            pep_members: values.conyuge === "true",
            information_person_completed: true,
          };

          const dataPropio = {
            name: `${dataPerson?.data.name} ${dataPerson?.data.last_name} ${dataPerson?.data.last_name_2}`,
            position: values.cargo,
            dependency: values.dependencia,
            active: values.actualmente === "true",
            start_date: values.start_date === "" ? null : values.start_date,
            loan_application_id: application.id,
            pep_type_id: pepsIdPropias,
            end_date: values.end_date === "" ? null : values.end_date,
            person_id: String(ownerId),
            relationship_id: null,
          };

          if (values.publicas === "true") {
            if (pepsIdPropioRegistro !== "" && pepsIdPropioRegistro !== undefined) {
              try {
                await patchPeps({ id: pepsIdPropioRegistro, body: dataPropio });
              } catch (error: any) {
                if (error?.status === 500 || error?.status === 403) {
                  dispatch(setModalErrosVisible({ open: true, type: "500" }));
                  return;
                } else {
                  dispatch(setModalErrosVisible({ open: true, type: "" }));
                  return;
                }
              }
            } else {
              try {
                await postPeps(dataPropio);
              } catch (error: any) {
                if (error?.status === 500 || error?.status === 403) {
                  dispatch(setModalErrosVisible({ open: true, type: "500" }));
                  return;
                } else {
                  dispatch(setModalErrosVisible({ open: true, type: "" }));
                  return;
                }
              }
            }
          } else if (values.publicas === "false") {
            if (pepsIdPropioRegistro !== undefined && pepsIdPropioRegistro !== "") {
              await deletePeps({ id: pepsIdPropioRegistro }).unwrap();
            }
          }

          if (values.conyuge === "true") {
            for (const pep of values.peps_conyuge) {
              const dataPepsFamiliar = {
                name: `${pep.nombre_conyuge} ${pep.ap_paterno_conyuge} ${pep.ap_materno_conyuge}`,
                position: pep.cargo_conyuge,
                dependency: pep.dependencia_conyuge,
                active: pep.actualmente_conyuge === "true",
                start_date: pep.start_date_conyuge,
                loan_application_id: application.id,
                pep_type_id: pepsId,
                end_date: pep.end_date_conyuge === "" ? null : pep.end_date_conyuge,
                person_id: String(ownerId),
                relationship_id: pep.parentesco_conyuge,
              };

              if (pep.id !== "" && pep.id !== undefined) {
                try {
                  await patchPeps({ id: pep.id, body: dataPepsFamiliar });
                } catch (error: any) {
                  if (error?.status === 500 || error?.status === 403) {
                    dispatch(setModalErrosVisible({ open: true, type: "500" }));
                    return;
                  } else {
                    dispatch(setModalErrosVisible({ open: true, type: "" }));
                    return;
                  }
                }
              } else {
                try {
                  await postPeps(dataPepsFamiliar);
                } catch (error: any) {
                  if (error?.status === 500 || error?.status === 403) {
                    dispatch(setModalErrosVisible({ open: true, type: "500" }));
                    return;
                  } else {
                    dispatch(setModalErrosVisible({ open: true, type: "" }));
                    return;
                  }
                }
              }
            }
          } else if (values.conyuge === "false") {
            for (const pep of values.peps_conyuge) {
              if (pep.id !== "" && pep.id !== undefined) {
                try {
                  await deletePeps({ id: pep.id }).unwrap();
                } catch (error: any) {
                  if (error?.status === 500 || error?.status === 403) {
                    dispatch(setModalErrosVisible({ open: true, type: "500" }));
                    return;
                  } else {
                    dispatch(setModalErrosVisible({ open: true, type: "" }));
                    return;
                  }
                }
              }
            }
          }

          try {
            await patchPersonData(dataPropiestario).unwrap();
          } catch (error: any) {
            if (error?.status === 500 || error?.status === 403) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
              return;
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
              return;
            }
          }
        } catch (err) {
          console.error("Error al enviar las PEPs:", err);
        }
        window.location.href = "/producto/creditosimple/propietario_real_pm";
      }
    },
  });

  const getPersonaById = async (value: string) => {
    try {
      await getPersona(value).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const getDependecia = async () => {
    try {
      await getDependeciasLista().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const getCargo = async () => {
    try {
      await getCargoPulico().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const getPeps = async () => {
    try {
      await getPepsType().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const getFamiliares = async () => {
    try {
      await getFamiliar().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getDependecia();
      getCargo();
      getPeps();
      getFamiliares();
    }, 1000);
  }, []);

  useEffect(() => {
    if (dataPeps) {
      const pepsTypeId = dataPeps.find((entity) => entity.name === "Familiar");
      const pepsTypeIdPropias = dataPeps.find((entity) => entity.name === "Propio");
      setPepsId(String(pepsTypeId?.id));
      setPepsIdPropias(String(pepsTypeIdPropias?.id));
    }
  }, [dataPeps, loadingPeps]);

  useEffect(() => {
    if (ownerId) {
      setTimeout(() => {
        getPersonaById(String(ownerId));
      }, 1000);
    }
  }, [ownerId]);

  useEffect(() => {
    if (ownerId) {
      setTimeout(() => {
        getPepsByPersona({ idPersona: String(ownerId) });
      }, 1000);
    }
  }, [ownerId]);

  useEffect(() => {
    if (dataDependencia && loadingDependencia) {
      setListDependecias(dataDependencia);
    }
  }, [dataDependencia, loadingDependencia]);

  useEffect(() => {
    if (dataCargo && loadingCargo) {
      setListCargo(dataCargo);
    }
  }, [dataCargo, loadingCargo]);

  useEffect(() => {
    if (dataFamiliar && loadingFamiliar) {
      setListFamiliar(dataFamiliar);
    }
  }, [dataFamiliar, loadingFamiliar]);

  useEffect(() => {
    if (dataPerson) {
      if (dataPerson.data.pep_shareholder !== null) {
        const value = dataPerson.data.pep_shareholder;
        formik.setFieldValue("publicas", value.toString());
      }
      if (dataPerson.data.pep_members !== null) {
        const value = dataPerson.data.pep_members;
        formik.setFieldValue("conyuge", value.toString());
      }
    }
  }, [dataPerson]);

  useEffect(() => {
    if (dataPeps) {
      const pepsTypeId = dataPeps.find((entity) => entity.name === "Familiar");
      const pepsTypeIdPropias = dataPeps.find((entity) => entity.name === "Propio");

      setPepsId(String(pepsTypeId?.id));
      setPepsIdPropias(String(pepsTypeIdPropias?.id));
    }
  }, [dataPeps, loadingPeps]);

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.peps_conyuge?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  const handleAddConyuge = () => {
    if (formik.values.peps_conyuge.length < 5) {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
        },
      ]);
    } else {
      setDisableAdd(true);
    }
  };

  const handleRemoveConyuge = async (index: number, id: string) => {
    if (id !== "" && id !== undefined) {
      try {
        await deletePeps({ id: id });
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }
    }
    const updatedPepsConyuge = formik.values.peps_conyuge.filter((_, i) => i !== index);
    formik.setFieldValue("peps_conyuge", updatedPepsConyuge);
    if (updatedPepsConyuge.length === 0) {
      formik.setFieldValue("conyuge", `false`);
    }
    if (updatedPepsConyuge.length < 5) {
      setDisableAdd(false);
    }
  };

  useEffect(() => {
    if (formik.values.peps_conyuge.length === 0 && formik.values.conyuge === "true") {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
          id: "",
        },
      ]);
    }
  }, [formik.values.conyuge, formik.values.peps_conyuge]);

  useEffect(() => {
    if (dataPepsPerson && loadingDependencia && loadingCargo && loadingFamiliar) {
      const dataFamiliar = dataPepsPerson.filter((item) => item.pep_type_id === pepsId);

      setTimeout(() => {
        const pepsConyugeData = dataFamiliar.map((item) => {
          const [nombreConyuge, apPaternoConyuge, apMaternoConyuge] = item.name.split(" ");
          return {
            parentesco_conyuge: item.relationship_id,
            nombre_conyuge: nombreConyuge,
            ap_paterno_conyuge: apPaternoConyuge || "",
            ap_materno_conyuge: apMaternoConyuge || "",
            dependencia_conyuge: item.dependency,
            cargo_conyuge: item.position,
            actualmente_conyuge: item.active ? "true" : "false",
            start_date_conyuge: dayjs(item.start_date).format("YYYY-MM-DD"),
            end_date_conyuge: item.end_date === null ? "" : dayjs(item.end_date).format("YYYY-MM-DD"),
            id: item.id,
          };
        });
        formik.setFieldValue("peps_conyuge", pepsConyugeData);
      }, 900);
    }
  }, [dataPepsPerson, pepsId, loadingDependencia, loadingCargo, loadingFamiliar]);

  useEffect(() => {
    if (dataPepsPerson) {
      const dataPropio = dataPepsPerson.filter((item) => item.pep_type_id === pepsIdPropias);
      const dataPepsPersonPropio = dataPropio[0];
      if (loadingDependencia && loadingCargo && loadingFamiliar) {
        setPepsIdPropioRegistro(dataPepsPersonPropio?.id);
        formik.setFieldValue(
          "end_date",
          dataPepsPersonPropio?.end_date === null ? "" : dayjs(dataPepsPersonPropio?.end_date).format("YYYY-MM-DD"),
        );
        formik.setFieldValue("cargo", dataPepsPersonPropio?.position);
        formik.setFieldValue("dependencia", dataPepsPersonPropio?.dependency);
        formik.setFieldValue("actualmente", `${dataPepsPersonPropio?.active}`);
        formik.setFieldValue("start_date", dayjs(dataPepsPersonPropio?.start_date).format("YYYY-MM-DD"));
      }
    }
  }, [dataPepsPerson, pepsIdPropias, loadingDependencia, loadingCargo, loadingFamiliar]);

  useEffect(() => {
    if (loadingDependencia && loadingCargo && loadingPeps && loadingFamiliar && isSuccess) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [loadingDependencia, loadingCargo, loadingPeps, loadingFamiliar, isSuccess]);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Spinner open={isLoading} />
        <InactivityHandler />
        <Box mx={4}>
          <Typography mt={4} mb={3} fontSize={"17px"} fontWeight={600} color={"#002652"} textAlign={"center"}>
            Información del Propietario Real
          </Typography>

          <Typography mt={4} mb={3} fontSize={"14px"} fontWeight={300} color={"#002652"} textAlign={"center"}>
            <strong>2</strong> de 2
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={
                  "¿Desempeñas o has desempeñado funciones públicas destacadas en México o el extranjero, " +
                  "altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos?​"
                }
                name={"publicas"}
                value={formik.values.publicas}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.publicas && Boolean(formik.errors.publicas)}
                helperText={formik.touched.publicas && formik.errors.publicas}
                options={[
                  {
                    label: "Si",
                    value: "true",
                  },
                  {
                    label: "No",
                    value: "false",
                  },
                ]}
              />
            </Box>

            {formik.values.publicas === "true" && (
              <>
                <FormControl
                  fullWidth
                  required
                  sx={{
                    ...customStylesAsterisk,
                    ...customSelect,
                    mt: 2,
                  }}
                  error={formik.touched.dependencia && Boolean(formik.errors.dependencia)}
                >
                  <InputLabel id="dependencia">Dependencia del cargo</InputLabel>
                  <Select
                    labelId="dependencia"
                    id="dependencia"
                    name="dependencia"
                    fullWidth
                    required
                    label="Dependencia del cargo"
                    value={formik.values.dependencia}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.dependencia && Boolean(formik.errors.dependencia)}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {listDependecias.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.dependencia && formik.errors.dependencia && (
                    <FormHelperText>{formik.errors.dependencia}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  sx={{
                    ...customStylesAsterisk,
                    ...customSelect,
                    mt: 2,
                  }}
                  error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                >
                  <InputLabel id="cargo">Cargo público</InputLabel>
                  <Select
                    labelId="cargo"
                    id="cargo"
                    name="cargo"
                    fullWidth
                    required
                    label="Cargo público"
                    value={formik.values.cargo}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {listCargo.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.cargo && formik.errors.cargo && (
                    <FormHelperText>{formik.errors.cargo}</FormHelperText>
                  )}
                </FormControl>
              </>
            )}

            {formik.values.publicas === "true" && (
              <>
                <Box textAlign={"left"} my={2}>
                  <CustomRadioGroup
                    label={"¿Actualmente esta en ese cargo?​"}
                    name={"actualmente"}
                    value={formik.values.actualmente}
                    onChange={(e: any) => {
                      formik.handleChange(e);

                      if (e.target.value === "true") {
                        formik.setFieldValue(`end_date`, "");
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.actualmente && Boolean(formik.errors.actualmente)}
                    helperText={formik.touched.actualmente && formik.errors.actualmente}
                    options={[
                      {
                        label: "Si",
                        value: "true",
                      },
                      {
                        label: "No",
                        value: "false",
                      },
                    ]}
                  />
                </Box>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      label="Fecha de Inicio"
                      value={formik.values.start_date ? dayjs(formik.values.start_date) : null}
                      onChange={(date) => {
                        formik.setFieldValue("start_date", dayjs(date).format("YYYY-MM-DD"));
                        const actualmente = formik.values.actualmente;
                        if (actualmente === "false") {
                          formik.setFieldValue(`end_date`, dayjs(date).format("YYYY-MM-DD"));
                        }
                      }}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          name: "start_date",
                          onBlur: () => {
                            const inputValue = formik.values?.start_date;
                            const actualmente = formik.values?.actualmente;
                            const currentDate = dayjs();

                            if (inputValue) {
                              const inputDate = dayjs(inputValue);

                              if (!inputDate.isValid() || inputDate.isAfter(currentDate, "day")) {
                                formik.setFieldValue(`start_date`, currentDate.format("YYYY-MM-DD"));

                                if (actualmente === "false") {
                                  formik.setFieldValue(`end_date`, currentDate.format("YYYY-MM-DD"));
                                }
                                return;
                              }

                              if (actualmente === "false") {
                                formik.setFieldValue(`end_date`, dayjs(inputDate).format("YYYY-MM-DD"));
                              }
                            }
                          },
                          error: formik.touched.start_date && Boolean(formik.errors.start_date),
                          helperText: formik.touched.start_date ? formik.errors.start_date : "",
                          required: true,
                          inputProps: {
                            "aria-invalid": formik.touched.start_date && Boolean(formik.errors.start_date),
                            "aria-hidden": false,
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                {formik.values.actualmente === "false" && (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <DatePicker
                        label="Fecha de termino"
                        value={formik.values.end_date ? dayjs(formik.values.end_date) : null}
                        onChange={(date) => {
                          formik.setFieldValue("end_date", dayjs(date).format("YYYY-MM-DD"));
                        }}
                        minDate={formik.values.start_date ? dayjs(formik.values.start_date) : undefined}
                        maxDate={dayjs()}
                        slotProps={{
                          textField: {
                            name: "end_date",
                            onBlur: () => {
                              const endDateValue = formik.values?.end_date;
                              const startDateValue = formik.values?.start_date;
                              const currentDate = dayjs();

                              if (endDateValue) {
                                const endDate = dayjs(endDateValue);
                                const startDate = dayjs(startDateValue);

                                if (!endDate.isValid() || endDate.isAfter(currentDate, "day")) {
                                  formik.setFieldValue(`end_date`, currentDate.format("YYYY-MM-DD"));
                                } else if (startDateValue && endDate.isBefore(startDate, "day")) {
                                  formik.setFieldValue(`end_date`, startDate.format("YYYY-MM-DD"));
                                }
                              }
                            },
                            error: formik.touched.end_date && Boolean(formik.errors.end_date),
                            helperText: formik.touched.end_date ? formik.errors.end_date : "",
                            required: true,
                            inputProps: {
                              "aria-invalid": formik.touched.end_date && Boolean(formik.errors.end_date),
                              "aria-hidden": false,
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              </>
            )}

            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={
                  "¿Tu cónyuge o alguno de tus padres, abuelos, hijos, nietos, hermanos, " +
                  "suegros, hijos políticos, abuelos políticos o cuñados, desempeña o ha " +
                  "desempeñado funciones públicas destacadas en México o el extranjero, altos puestos ejecutivos " +
                  "en empresas estatales o funciones importantes en partidos políticos?​"
                }
                name={"conyuge"}
                value={formik.values.conyuge}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.conyuge && Boolean(formik.errors.conyuge)}
                helperText={formik.touched.conyuge && formik.errors.conyuge}
                options={[
                  {
                    label: "Si",
                    value: "true",
                  },
                  {
                    label: "No",
                    value: "false",
                  },
                ]}
              />
            </Box>

            {formik.values.conyuge === "true" && (
              <>
                {formik.values.peps_conyuge.map((pep, index) => (
                  <Box key={index}>
                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        ...customSelect,
                        mt: 2,
                      }}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                          getFieldError("parentesco_conyuge", index),
                      )}
                    >
                      <InputLabel id={`parentesco_conyuge-${index}`}>Parentesco</InputLabel>
                      <Select
                        labelId={`parentesco_conyuge-${index}`}
                        id={`parentesco_conyuge-${index}`}
                        name={`peps_conyuge[${index}].parentesco_conyuge`}
                        fullWidth
                        required
                        label="Parentesco"
                        value={pep.parentesco_conyuge}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                            getFieldError("parentesco_conyuge", index),
                        )}
                      >
                        <MenuItem value={""}>Selecciona</MenuItem>
                        {listFamiliar.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                        getFieldError("parentesco_conyuge", index) && (
                          <FormHelperText>{getFieldError("parentesco_conyuge", index)}</FormHelperText>
                        )}
                    </FormControl>

                    <TextField
                      id={`nombre_conyuge-${index}`}
                      name={`peps_conyuge[${index}].nombre_conyuge`}
                      label="Nombre(s)"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.nombre_conyuge && getFieldError("nombre_conyuge", index),
                      )}
                      helperText={
                        formik.touched.peps_conyuge?.[index]?.nombre_conyuge && getFieldError("nombre_conyuge", index)
                      }
                      value={pep.nombre_conyuge}
                    />

                    <TextField
                      id={`ap_paterno_conyuge-${index}`}
                      name={`peps_conyuge[${index}].ap_paterno_conyuge`}
                      label="Apellido paterno"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                          getFieldError("ap_paterno_conyuge", index),
                      )}
                      helperText={
                        formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                        getFieldError("ap_paterno_conyuge", index)
                      }
                      value={pep.ap_paterno_conyuge}
                    />

                    <TextField
                      id={`ap_materno_conyuge-${index}`}
                      name={`peps_conyuge[${index}].ap_materno_conyuge`}
                      label="Apellido materno"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                          getFieldError("ap_materno_conyuge", index),
                      )}
                      helperText={
                        formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                        getFieldError("ap_materno_conyuge", index)
                      }
                      value={pep.ap_materno_conyuge}
                    />

                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        ...customSelect,
                        mt: 2,
                      }}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                          getFieldError("dependencia_conyuge", index),
                      )}
                    >
                      <InputLabel id={`dependencia_conyuge-${index}`}>Dependencia del cargo</InputLabel>
                      <Select
                        labelId={`dependencia_conyuge-${index}`}
                        id={`dependencia_conyuge-${index}`}
                        name={`peps_conyuge[${index}].dependencia_conyuge`}
                        fullWidth
                        required
                        label="Dependencia del cargo"
                        value={pep.dependencia_conyuge}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                            getFieldError("dependencia_conyuge", index),
                        )}
                      >
                        <MenuItem value={""}>Selecciona</MenuItem>
                        {listDependecias.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                        getFieldError("dependencia_conyuge", index) && (
                          <FormHelperText>{getFieldError("dependencia_conyuge", index)}</FormHelperText>
                        )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        ...customSelect,
                        mt: 2,
                      }}
                      error={Boolean(
                        formik.touched.peps_conyuge?.[index]?.cargo_conyuge && getFieldError("cargo_conyuge", index),
                      )}
                    >
                      <InputLabel id={`cargo_conyuge-${index}`}>Cargo público</InputLabel>
                      <Select
                        labelId={`cargo_conyuge-${index}`}
                        id={`cargo_conyuge-${index}`}
                        name={`peps_conyuge[${index}].cargo_conyuge`}
                        fullWidth
                        required
                        label="Cargo público"
                        value={pep.cargo_conyuge}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.cargo_conyuge && getFieldError("cargo_conyuge", index),
                        )}
                      >
                        <MenuItem value={""}>Selecciona</MenuItem>
                        {listCargo.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.peps_conyuge?.[index]?.cargo_conyuge && getFieldError("cargo_conyuge", index) && (
                        <FormHelperText>{getFieldError("cargo_conyuge", index)}</FormHelperText>
                      )}
                    </FormControl>

                    <Box textAlign={"left"} my={2}>
                      <CustomRadioGroup
                        label={"¿Actualmente esta en ese cargo?​"}
                        name={`peps_conyuge[${index}].actualmente_conyuge`}
                        value={pep.actualmente_conyuge}
                        onChange={(e: any) => {
                          formik.handleChange(e);

                          if (e.target.value === "true") {
                            formik.setFieldValue(`peps_conyuge[${index}].end_date_conyuge`, "");
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                            getFieldError("actualmente_conyuge", index),
                        )}
                        helperText={
                          formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                          getFieldError("actualmente_conyuge", index)
                        }
                        options={[
                          {
                            label: "Si",
                            value: "true",
                          },
                          {
                            label: "No",
                            value: "false",
                          },
                        ]}
                      />
                    </Box>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DatePicker
                          label="Fecha de Inicio"
                          value={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : null}
                          onChange={(date) => {
                            formik.setFieldValue(
                              `peps_conyuge[${index}].start_date_conyuge`,
                              dayjs(date).format("YYYY-MM-DD"),
                            );
                          }}
                          maxDate={dayjs()}
                          slotProps={{
                            textField: {
                              name: `peps_conyuge[${index}].start_date_conyuge`,
                              onBlur: () => {
                                const inputValue = formik.values.peps_conyuge?.[index]?.start_date_conyuge;
                                const actualmente = formik.values.peps_conyuge?.[index]?.actualmente_conyuge;
                                const currentDate = dayjs();

                                if (inputValue) {
                                  const inputDate = dayjs(inputValue);

                                  if (!inputDate.isValid() || inputDate.isAfter(currentDate, "day")) {
                                    formik.setFieldValue(
                                      `peps_conyuge[${index}].start_date_conyuge`,
                                      currentDate.format("YYYY-MM-DD"),
                                    );

                                    if (actualmente === "false") {
                                      formik.setFieldValue(
                                        `peps_conyuge[${index}].end_date_conyuge`,
                                        currentDate.format("YYYY-MM-DD"),
                                      );
                                    }
                                    return;
                                  }

                                  if (actualmente === "false") {
                                    formik.setFieldValue(
                                      `peps_conyuge[${index}].end_date_conyuge`,
                                      dayjs(inputDate).format("YYYY-MM-DD"),
                                    );
                                  }
                                }
                              },
                              error: Boolean(
                                formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                  getFieldError("start_date_conyuge", index),
                              ),
                              helperText: formik.touched.peps_conyuge?.[index]?.start_date_conyuge
                                ? getFieldError("start_date_conyuge", index)
                                : "",
                              required: true,
                              inputProps: {
                                "aria-invalid": Boolean(
                                  formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                    getFieldError("start_date_conyuge", index),
                                ),
                                "aria-hidden": false,
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>

                    {formik.values.peps_conyuge[index].actualmente_conyuge === "false" && (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                          <DatePicker
                            label="Fecha de termino"
                            value={pep.end_date_conyuge ? dayjs(pep.end_date_conyuge) : null}
                            onChange={(date) => {
                              formik.setFieldValue(
                                `peps_conyuge[${index}].end_date_conyuge`,
                                dayjs(date).format("YYYY-MM-DD"),
                              );
                            }}
                            minDate={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : undefined}
                            maxDate={dayjs()}
                            slotProps={{
                              textField: {
                                name: `peps_conyuge[${index}].end_date_conyuge`,
                                onBlur: () => {
                                  const endDateValue = formik.values.peps_conyuge?.[index]?.end_date_conyuge;
                                  const startDateValue = formik.values.peps_conyuge?.[index]?.start_date_conyuge;
                                  const currentDate = dayjs();

                                  if (endDateValue) {
                                    const endDate = dayjs(endDateValue);
                                    const startDate = dayjs(startDateValue);

                                    if (!endDate.isValid() || endDate.isAfter(currentDate, "day")) {
                                      formik.setFieldValue(
                                        `peps_conyuge[${index}].end_date_conyuge`,
                                        currentDate.format("YYYY-MM-DD"),
                                      );
                                    } else if (startDateValue && endDate.isBefore(startDate, "day")) {
                                      formik.setFieldValue(
                                        `peps_conyuge[${index}].end_date_conyuge`,
                                        startDate.format("YYYY-MM-DD"),
                                      );
                                    }
                                  }
                                },
                                error: Boolean(
                                  formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                    getFieldError("end_date_conyuge", index),
                                ),
                                helperText: formik.touched.peps_conyuge?.[index]?.end_date_conyuge
                                  ? getFieldError("end_date_conyuge", index)
                                  : "",
                                required: true,
                                inputProps: {
                                  "aria-invalid": Boolean(
                                    formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                      getFieldError("end_date_conyuge", index),
                                  ),
                                  "aria-hidden": false,
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}
                    <Typography
                      onClick={() => handleRemoveConyuge(index, pep.id)}
                      color={"#F2704F"}
                      fontSize={"15px"}
                      fontWeight={400}
                      mt={3}
                      sx={{ cursor: disableAdd ? "not-allowed" : "pointer", textDecoration: "" }}
                    >
                      - Remover Persona
                    </Typography>
                    <hr />
                  </Box>
                ))}
                <Typography
                  onClick={handleAddConyuge}
                  color={"#528CD6"}
                  fontSize={"15px"}
                  fontWeight={400}
                  mt={3}
                  sx={{ cursor: disableAdd ? "not-allowed" : "pointer" }}
                >
                  + Agregar Otro
                </Typography>
              </>
            )}

            <Grid container columnSpacing={1} my={4}>
              <Grid item xs={6}>
                <Button
                  variant="blue_outlined"
                  type="button"
                  fullWidth
                  onClick={() => {
                    navigate("/producto/creditosimple/propietario_real_pm");
                  }}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="default"
                  disabled={!formik.dirty || !formik.isValid || !(Object.keys(formik.errors).length === 0)}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
