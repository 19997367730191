import { useState } from "react";
import {
  Grid,
  Box,
  Container,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RFC_PFAE } from "@utils/expreciones";
import { useAppDispatch, type RootState } from "@store/store";
import { ProgressBar } from "@components/ProgressBar";
import { setCompanyData } from "@store/slices/registerSlice";
import { useLazyAddCompanyDataQuery } from "@api/account";
import { usePostValidateNextStageMutation } from "@api/applications";
import { transformTitleCase } from "@helpers/funciones";
import img from "@assets/img/img_domicilio_right.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

export const DatosEmpresaPfae = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [triggerAddCompanyData] = useLazyAddCompanyDataQuery();

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const personal_data = useSelector((state: RootState) => state.register.personal_data);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      rfc: companyData.rfc,
      company_name: companyData.name,
      name: companyData.pfae_name || "",
      lastName1: companyData.pfae_last_name || "",
      lastName2: companyData.pfae_last_name_2 || "",
      isPFAE: companyData.is_pfae || false,
    },
    validationSchema: yup.object().shape({
      rfc: yup.string().trim().required("Campo requerido").matches(RFC_PFAE, "Formato incorrecto, inténtalo de nuevo"),
      company_name: yup.string().trim().required("Campo requerido"),
      name: yup.string().trim().required("El nombre es obligatorio"),
      lastName1: yup.string().trim().required("El apellido paterno es obligatorio"),
      lastName2: yup.string().trim().nullable(),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setIsLoading(true);

          const rfc = values.rfc.trim();
          const company_name = values.company_name.trim();
          const legal_name = `${values.name} ${values.lastName1} ${values.lastName2 || ""}`.trim();
          const name = values.name.trim();
          const lastName1 = values.lastName1.trim();
          const lastName2 = values.lastName2?.trim();
          const isPFAE = values.isPFAE;

          await triggerAddCompanyData({
            id: personal_data.id,
            body: {
              rfc: rfc,
              name: company_name,
              legal_name: legal_name,
              is_pfae: isPFAE,
              pfae_name: name,
              pfae_last_name: lastName1,
              pfae_last_name_2: lastName2,
            },
          }).unwrap();

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: "CSDEM",
          }).unwrap();

          dispatch(
            setCompanyData({
              rfc: rfc,
              name: company_name,
              legal_name: legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_pfae: isPFAE,
              pfae_name: name,
              pfae_last_name: lastName1,
              pfae_last_name_2: lastName2,
            }),
          );

          navigate("/producto/creditosimple/domicilio_pfae");
        } catch (error: any) {
          if (error?.data?.errors) {
            formik.setFieldError("rfc", `Existe una cuenta con RFC - ${values.rfc}`);
            return;
          }

          if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    validateOnMount: true,
  });

  const handleUpperChange = (values: string) => {
    formik.setFieldValue("rfc", values.toUpperCase());
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      formik.setValues(
        {
          ...formik.values,
          name: personal_data.name || "",
          lastName1: personal_data.first_lastname || "",
          lastName2: personal_data.second_lastname || "",
          isPFAE: true,
        },
        true,
      );
    } else {
      formik.setValues(
        {
          ...formik.values,
          name: "",
          lastName1: "",
          lastName2: "",
          isPFAE: false,
        },
        true,
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={3} mt={4}>
          <Typography mb={3} variant="h4" fontWeight={500}>
            Registro de empresa
          </Typography>
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 0 }}>
            <ProgressBar progress={3} />
          </Container>

          <Typography mt={4} mb={2} variant="body2" color={"#002652"}>
            Ingresa los datos de la PFAE
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="rfc"
                name="rfc"
                label="RFC de la PFAE"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => handleUpperChange(e.target.value)}
                value={formik.values.rfc}
                error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                helperText={formik.touched.rfc ? formik.errors.rfc : ""}
                inputProps={{ maxLength: 13 }}
              />

              <TextField
                id="company_name"
                name="company_name"
                label="Nombre comercial"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("company_name", transformTitleCase(e.target.value))}
                value={formik.values.company_name}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                helperText={formik.touched.company_name ? formik.errors.company_name : ""}
                inputProps={{ maxLength: 40 }}
              />
              <Grid item sx={{ textAlign: "left", mb: 2, mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox id="one" name="isPFAE" onChange={handleCheckboxChange} checked={formik.values.isPFAE} />
                  }
                  label="Soy la Persona Física con act. empresarial"
                />
              </Grid>
              <TextField
                id="name"
                name="name"
                label="Nombre"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={(e) => formik.setFieldValue("name", transformTitleCase(e.target.value))}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name ? formik.errors.name : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE}
              />

              <TextField
                id="lastName1"
                name="lastName1"
                label="Apellido Paterno"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={(e) => formik.setFieldValue("lastName1", transformTitleCase(e.target.value))}
                value={formik.values.lastName1}
                error={formik.touched.lastName1 && Boolean(formik.errors.lastName1)}
                helperText={formik.touched.lastName1 ? formik.errors.lastName1 : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE}
              />

              <TextField
                id="lastName2"
                name="lastName2"
                label="Apellido Materno"
                margin="normal"
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={(e) => formik.setFieldValue("lastName2", transformTitleCase(e.target.value))}
                value={formik.values.lastName2}
                error={formik.touched.lastName2 && Boolean(formik.errors.lastName2)}
                helperText={formik.touched.lastName2 ? formik.errors.lastName2 : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE}
              />

              <Button type="submit" variant="default" sx={{ my: 4 }} disabled={!formik.isValid || isLoading}>
                Continuar
              </Button>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
