import { PayloadRequestReferencias } from "@interfaces/referencias";
import { baseApi } from "./baseApi";
import { ReferenceResponse, ReferenceType, ReferenceTypeResponse } from "@interfaces/response";
import { UUID } from "crypto";

const referenciasApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postReferences: build.mutation<ReferenceResponse, PayloadRequestReferencias>({
      query: (data: PayloadRequestReferencias) => ({
        url: `/referencias`,
        method: "POST",
        body: data,
      }),
    }),
    patchReferences: build.mutation<ReferenceResponse, { referenceId: UUID | undefined; body: PayloadRequestReferencias }>({
      query: ({ referenceId, body }) => ({
        url: `/referencias/${referenceId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getReferenceTypes: build.query<Array<ReferenceType>, void>({
      query: () => "catalogos/reference_type",
      transformResponse: (response: { data: ReferenceTypeResponse }) => {
        return response.data.data;
      },
    }),
  }),
});

export const { usePostReferencesMutation, useGetReferenceTypesQuery, usePatchReferencesMutation } = referenciasApi;
