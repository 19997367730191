import { baseApi } from "./baseApi";
import { UUID } from "crypto";
import { AddressResponse } from "../types";

interface AddressData {
    street: string;
    no_ext: string;
    no_int: string;
    colonia_id: number | undefined;
    state_id: number;
    city_id: number;
    country_id: number;
    cp: string;
    address_type_id: UUID | string;
    client_details_id: UUID | undefined;
}

const addressApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        postAddress: build.mutation<AddressResponse, AddressData>({
            query: (data) => ({
                url: `direcciones`,
                method: "POST",
                body: data,
            })
        }),
        patchAddres: build.mutation<AddressResponse, {addressId: UUID, body:AddressData}>({
            query:({addressId, body}) => ({
                url: `direcciones/${addressId}`,
                method: 'PATCH',
                body: body
            })
        })
    }),
});

export const { usePostAddressMutation, usePatchAddresMutation } = addressApi;