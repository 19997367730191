/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import img from "@assets/img/img_tofu/img_tofu_1.svg";
import { CustomCheckList } from "@components/CustomCheckList";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePutApplicationUpdateStatusMutation } from "@api/applications";
import { useGetStatusQuery } from "@api/catalogs";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

export const InfoDocumentosPFAE = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [status, setStatus] = useState("");
  const [upDateStatus] = usePutApplicationUpdateStatusMutation();
  const { data: statusList } = useGetStatusQuery();


  useEffect(() => {
    if (statusList) {
      const aproved = statusList.find((item: { code: string }) => item.code === "CSAED");
      setStatus(String(aproved?.id));
    }
  }, [statusList]);

  const handleOnClickNext = async () => {
    try {
      navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
      const data = {
        status_id: status,
      };
      await upDateStatus({ id_sol: application.id, body: data });
    } catch (error: any) {
      if (error?.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} textAlign={"center"} variant="h4" fontWeight={600}>
              ¡Hola {personalData.name}!
            </Typography>
            <Typography mb={3} textAlign={"center"} variant="body2" color={"#528CD6"} fontWeight={600}>
              Ahora completa el expediente digital.
            </Typography>
            <Typography mb={3} textAlign={"center"} variant="body2">
              Conforme avances, deberás cargar los siguientes documentos, si no los tienes a la mano puedes guardar tu
              avance y completarlo después
            </Typography>
            <CustomCheckList
              id="empresa"
              sx={{ mt: 3 }}
              title="Del dueño"
              items={["Constancia de situación fiscal", "Identificación oficial vigente (INE, Pasaporte,FM2)"]}
            />

            <Box mt={2}>
              <Button variant="default" onClick={handleOnClickNext}>
                Continuar
              </Button>
            </Box>
          </Box>
        </Grid>
        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#A3D4E8"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
